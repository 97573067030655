.product-footer3 {
  width: 100%;
  padding: 32px 0;
}

.text-wrapper-27{
  color: black;
  font-size: 14px;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  font-weight: 700;
}

.div-wrapper-27{
  position: relative;
  width: 30px;
  height: 30px;
  top: 0px;
  right: 0px;
}

.barbar3 {
  width: 100%;
    background-color: rgba(73, 73, 73, 0.286);
    border-radius: 8px;
    /* margin-top: 10px; */
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    background-color: rgba(68, 68, 68, .4);
    backdrop-filter: blur(0.75rem);
    -webkit-backdrop-filter: blur(.75rem);
    border-radius: 6.25rem;
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 700;
    letter-spacing: .08em;
    text-shadow: 0 0 0.125rem rgba(0,0,0,.25);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100vw - 4rem);
    background-color: rgba(17, 17, 17, .5);
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    border-radius: 0;
    max-width: unset;
    color: #fff;
    text-shadow: 0 0 0.125rem rgba(0,0,0,.75);
    font-size: .375rem;
    line-height: .625rem;
    /* padding: 0 1rem; */
    opacity: 1;
    transition: opacity .25s;
    transition-delay: .15s;
    border-bottom-left-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
}

.dropdown-button3 {
  align-items: center;
  background-color: #343639;
  background-color: var(--systemBlue);
  border: 1px solid #494949bb;
  border-radius: 8px;
  /* color: #fff; */
  color: var(--systemPrimary);
  height: 24px;
  cursor: pointer;
  display: flex;
  padding: 6px 10px 6px 10px;
  position: relative;
  border-radius: 50px;
  background: 0 0;
  background-color: #343639;
  background-color: var(--cardBackgroundColor);
  background-color: var(--appSecondaryBackgroundColor);
  /* border: none; */
}

.munich {
  display: block; /* Adjust from grid to block for vertical layout */
    /* overflow-x: hidden; 
    overflow-y: auto; */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    margin-bottom: 40px !important;
    padding-left: 0px;
}

.munich-header {
  padding-left: 0px;
}



.munich-padless{
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.munich-list {
    margin-bottom: 40px !important;
}

@media screen and (max-width: 740px) {
  .munich-ordinal {
    padding: 15px;
  }
}

@media screen and (min-width: 1001px) {
  .munich-list-item {
    margin-bottom: 30px;
  }
}

/* Div that should be shown when the screen width is over 100px */
.bigger1000 {
  display: none !important;
}

/* Div that should be shown when the screen width is 100px or less */
.smaller1000 {
  display: block !important;
}

.bollum2 {
  grid-template-columns: repeat(2, 1fr);
}

/* Use a media query to change the display properties based on screen width */
@media screen and (min-width: 1001px) {
  .bigger1000 {
    display: block !important;
  }

  .bollum2 {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .smaller1000 {
    display: none !important;
  }
}


@media only screen and (max-width: 2740px) {
  .wide-card-full {
    grid-column: span 2;
    /* padding: 0px; */
  }
}

@media only screen and (max-width: 740px) {
  .wide-card-full {
    grid-column: span 2;
    /* padding: 0px; */
  }
}


@media only screen and (max-width: 2740px) {
  .product-footer__container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .product-header__video-wrapper {
    z-index: 2;
  }

  .product-footer3 {
    width: 100%;
    padding: 0px 0px 30px 0px;
    background-color: #1b1b1b;
    border-radius: 6px;
    padding-bottom: 0px;
    background-color: var(--appPrimaryBackgroundColor);
  }
  .ordinal-chart__metadata2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 1;
    width: 100%;
    text-align: left;
    
  }
  .new-order2 {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 500;
  }
  .product-header__content__container3 {
    padding: 20px;
    padding-left: 20px;
    display: flex;
    position: relative;
    background-color: #000;
    background-color: var(--appSecondaryBackgroundColor);
    margin-bottom: 20px;
    /* border: 1px solid #494949; */
  }
  .ordinal-chart-item__title3 {
    letter-spacing: 0;
    -webkit-line-clamp: 2;
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 1.23;
  }
  .wide-card3 {
    grid-column: span 2;
  }
  .side-card3 {
    border-radius: 0px;
    padding: 0px;
    background-color: #00000000;
  }
  
  .movie-poster3 {
    /* width: 67px; */
    height: 105px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    position: relative;
  }

  .movie-poster3 img {
    width: auto; /* Adjust the width automatically */
    height: 100%; /* Limit the image height to not exceed the container */
    object-fit: cover; /* Cover the area without losing the aspect ratio */
  }
}