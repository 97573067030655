html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

:host,
:root {
  --rem: 16;
}

[hydrated] {
  visibility: inherit;
}

:root {
  --bodyGutter: 25px;
  color-scheme: light dark;
  --systemRed: #ff3b30;
  --systemRed-vibrant: #f53126;
  --systemRed-default_IC: #d70015;
  --systemRed-vibrant_IC: #c20618;
  --systemOrange: #ff9500;
  --systemOrange-vibrant: #f58b00;
  --systemOrange-default_IC: #c93400;
  --systemOrange-vibrant_IC: #ad3a00;
  --systemYellow: #ffcc00;
  --systemYellow-vibrant: #f5c200;
  --systemYellow-default_IC: #a05a00;
  --systemYellow-vibrant_IC: #925100;
  --systemGreen: #28cd41;
  --systemGreen-vibrant: #1ec337;
  --systemGreen-default_IC: #007d1b;
  --systemGreen-vibrant_IC: #007018;
  --systemMint: #00c7be;
  --systemMint-vibrant: #00bdb4;
  --systemMint-default_IC: #0c817b;
  --systemMint-vibrant_IC: #0b7570;
  --systemTeal: #59adc4;
  --systemTeal-vibrant: #2ea7bd;
  --systemTeal-default_IC: #008299;
  --systemTeal-vibrant_IC: #00778c;
  --systemCyan: #55bef0;
  --systemCyan-vibrant: #41afdc;
  --systemCyan-default_IC: #0071a4;
  --systemCyan-vibrant_IC: #006796;
  --systemBlue: #007aff;
  --systemBlue-vibrant: #0070f5;
  --systemBlue-default_IC: #0040dd;
  --systemBlue-vibrant_IC: #0040dd;
  --systemBlue-rollover: #0045b7;
  --systemBlue-pressed: #005cd7;
  --systemBlue-deepPressed: #0045b7;
  --systemBlue-disabled: rgba(0, 122, 255, 0.35);
  --systemIndigo: #5856d6;
  --systemIndigo-vibrant: #5452cc;
  --systemIndigo-default_IC: #3634a3;
  --systemIndigo-vibrant_IC: #3634a3;
  --systemPurple: #af52de;
  --systemPurple-vibrant: #9f4bc9;
  --systemPurple-default_IC: #ad44ab;
  --systemPurple-vibrant_IC: #ad44ab;
  --systemPink: #ff2d55;
  --systemPink-vibrant: #f5234b;
  --systemPink-default_IC: #d30f45;
  --systemPink-vibrant_IC: #c11032;
  --systemBrown: #a2845e;
  --systemBrown-vibrant: #987a54;
  --systemBrown-default_IC: #7f6545;
  --systemBrown-vibrant_IC: #775d3b;
  --systemGray: #8e8e93;
  --systemGray-vibrant: #848489;
  --systemGray-default_IC: #69696e;
  --systemGray-vibrant_IC: #616165;
  --systemPrimary: rgba(0, 0, 0, 0.85);
  --systemPrimary-vibrant: #4c4c4c;
  --systemPrimary-onLight: rgba(0, 0, 0, 0.85);
  --systemPrimary-onDark: rgba(255, 255, 255, 0.85);
  --systemPrimary-default_IC: black;
  --systemPrimary-vibrant_IC: black;
  --systemPrimary-onLight_IC: black;
  --systemPrimary-onDark_IC: white;
  --systemSecondary: rgba(0, 0, 0, 0.5);
  --systemSecondary-vibrant: gray;
  --systemSecondary-onLight: rgba(0, 0, 0, 0.5);
  --systemSecondary-onDark: rgba(255, 255, 255, 0.55);
  --systemSecondary-default_IC: rgba(0, 0, 0, 0.76);
  --systemSecondary-vibrant_IC: rgba(0, 0, 0, 0.76);
  --systemSecondary-onLight_IC: rgba(0, 0, 0, 0.76);
  --systemSecondary-onDark_IC: rgba(255, 255, 255, 0.7);
  --systemTertiary: rgba(0, 0, 0, 0.25);
  --systemTertiary-vibrant: #bfbfbf;
  --systemTertiary-onLight: rgba(0, 0, 0, 0.25);
  --systemTertiary-onDark: rgba(255, 255, 255, 0.25);
  --systemTertiary-default_IC: rgba(0, 0, 0, 0.56);
  --systemTertiary-vibrant_IC: rgba(0, 0, 0, 0.56);
  --systemTertiary-onLight_IC: rgba(0, 0, 0, 0.56);
  --systemTertiary-onDark_IC: rgba(255, 255, 255, 0.5);
  --systemQuaternary: rgba(0, 0, 0, 0.1);
  --systemQuaternary-vibrant: #e6e6e6;
  --systemQuaternary-onLight: rgba(0, 0, 0, 0.1);
  --systemQuaternary-onDark: rgba(255, 255, 255, 0.1);
  --systemQuaternary-default_IC: rgba(0, 0, 0, 0.35);
  --systemQuaternary-vibrant_IC: rgba(0, 0, 0, 0.35);
  --systemQuaternary-onLight_IC: rgba(0, 0, 0, 0.35);
  --systemQuaternary-onDark_IC: rgba(255, 255, 255, 0.3);
  --systemQuinary: rgba(0, 0, 0, 0.05);
  --systemQuinary-vibrant: #f2f2f2;
  --systemQuinary-onLight: rgba(0, 0, 0, 0.05);
  --systemQuinary-onDark: rgba(255, 255, 255, 0.05);
  --systemQuinary-default_IC: rgba(0, 0, 0, 0.1);
  --systemQuinary-vibrant_IC: rgba(0, 0, 0, 0.1);
  --systemQuinary-onLight_IC: rgba(0, 0, 0, 0.1);
  --systemQuinary-onDark_IC: rgba(255, 255, 255, 0.1);
  --keyColor: #007aff;
  --keyColor-default_IC: #0040dd;
  --systemStandardUltrathinMaterialSover: rgba(246, 246, 246, 0.36);
  --systemStandardUltrathinMaterialSover-inactive: #f0f0f0;
  --systemStandardUltrathinMaterialSover-default_IC: #d2d2d2;
  --systemStandardUltrathinMaterialSover-inactive_IC: #ececec;
  --systemStandardThinMaterialSover: rgba(246, 246, 246, 0.48);
  --systemStandardThinMaterialSover-inactive: #f0f0f0;
  --systemStandardThinMaterialSover-default_IC: gainsboro;
  --systemStandardThinMaterialSover-inactive_IC: #ececec;
  --systemStandardMediumMaterialSover: rgba(246, 246, 246, 0.6);
  --systemStandardMediumMaterialSover-inactive: #f0f0f0;
  --systemStandardMediumMaterialSover-default_IC: #e6e6e6;
  --systemStandardMediumMaterialSover-inactive_IC: #ececec;
  --systemStandardThickMaterialSover: rgba(246, 246, 246, 0.72);
  --systemStandardThickMaterialSover-inactive: #f0f0f0;
  --systemStandardThickMaterialSover-default_IC: #f0f0f0;
  --systemStandardThickMaterialSover-inactive_IC: #ececec;
  --systemStandardUltrathickMaterialSover: rgba(246, 246, 246, 0.84);
  --systemStandardUltrathickMaterialSover-inactive: #f0f0f0;
  --systemStandardUltrathickMaterialSover-default_IC: #fafafa;
  --systemStandardUltrathickMaterialSover-inactive_IC: #ececec;
  --systemHeaderMaterialSover: rgba(255, 255, 255, 0.8);
  --systemHeaderMaterialSover-default_IC: white;
  --systemToolbarTitlebarMaterialSover: rgba(253, 253, 253, 0.8);
  --systemToolbarTitlebarMaterialSover-inactive: #f0f0f0;
  --systemToolbarTitlebarMaterialSover-default_IC: white;
  --systemToolbarTitlebarMaterialSover-inactive_IC: #ececec;
  --labelDivider: rgba(0, 0, 0, 0.15);
  --vibrantDivider: rgba(60, 60, 67, 0.29);
  --pageBG: white;
  --shelfBG: rgba(0, 0, 0, 0.05);
  --genericJoeColor: #ebebeb;
  --fallbackMaterialBG: rgba(255, 255, 255, 0.97);
  --liveBadgePlatterBG: #ff5066;
  --keyColorBackground: #007aff;
  --keyColorBackground-default_IC: #0040dd;
  --appPrimaryBackgroundColor: white;
  --appSecondaryBackgroundColor: #f0f0f3;
  --alertDropShadowColor: rgba(0, 0, 0, 0.25);
  --alertOuterStrokeColor: rgba(0, 0, 0, 0.2);
  --appModalBackgroundColor: white;
  --artworkBorderColor: rgba(151, 151, 151, 0.2);
  --ctaButtonBackgroundColor: white;
  --cardBackgroundColor: white;
  --defaultButtonBackgroundColor: rgba(84, 84, 84, 0.5);
  --dialogShadowColor: rgba(0, 0, 0, 0.25);
  --disabledLightColor: rbga(255, 255, 255, 0.4);
  --dividerTextColor: rgba(60, 60, 67, 0.29);
  --fauxNativeSheetBackgroundColor: white;
  --labelSecondary: rgba(235, 235, 245, 0.6);
  --liveBadgeBackgroundColor: #ff5066;
  --mobileNavigationBG: white;
  --modalBoxShadowColor: rgba(0, 0, 0, 0.24);
  --modalCloseButtonIconColor: rgba(60, 60, 67, 0.6);
  --modalContentOverlayColorEnd: white;
  --modalContentOverlayColorStart: rgba(255, 255, 255, 0);
  --modalHeaderBackgroundColor: rgba(255, 255, 255, 0.97);
  --modalHeaderShadowColor: rgba(0, 0, 0, 0.2);
  --modalScrimColor: rgba(0, 0, 0, 0.45);
  --navigationBackgroundColor: #eeeeee;
  --navigationBorderColor: #aaaaaa;
  --navigationBorderBottomColor: #aaaaaa;
  --navigationBorderLeftColor: #bbbbbb;
  --navigationBorderRightColor: #bbbbbb;
  --navigationBorderTopColor: #eeeeee;
  --navigationLinkSelectionStateColor: rgba(60, 60, 67, 0.1);
  --navigationMobileMenuBackgroundColor: white;
  --navigationScrollBackgroundColor: rgba(0, 0, 0, 0.4);
  --navigationScrollBackgroundFallbackColor: rgba(0, 0, 0, 0.6);
  --navigationTextColor: #555555;
  --overlayBackgroundColor: rgba(51, 51, 51, 0.3);
  --overlayButtonPlatterColor: rgba(255, 255, 255, 0.2);
  --personHeaderBackgroundColor: rgba(40, 40, 40, 0.9);
  --playerBackground: rgba(255, 255, 255, 0.88);
  --playbackOverlayBackgroundColor: rgba(51, 51, 51, 0.7);
  --progressBarColor: rgba(255, 255, 255, 0.4);
  --progressBarFillColor: white;
  --searchInputBorderColor: rgba(0, 0, 0, 0.15);
  --settingsMenuPointer: rgba(255, 255, 255, 0.5);
  --sidebarActiveItemBackgroundColor: rgba(60, 60, 67, 0.1);
  --sidebarBackgroundColor: rgba(60, 60, 67, 0.03);
  --sidebarSearchInputBorderColor: rgba(0, 0, 0, 0.15);
  --sportLockupClockTimeBadgeBackgroundColor: rgba(0, 0, 0, 0.2);
  --sportLockupScoreBadgeDivider: rgba(0, 0, 0, 0.15);
  --unifiedVolumeThumbShadow: rgba(0, 0, 0, 0.1);
  --unifiedVolumeThumbShadowInset: rgba(0, 0, 0, 0.15);
  --unifiedVolumeTrackFilled: rgba(246, 246, 246, 0.36);
  --unifiedVolumeThumb: #d0d0d0;
  --unifiedPlayerIcons: rgba(0, 0, 0, 0.95);
  --navigationIconColor: #7f7f7f;
  --modalBGColor: rgba(255, 255, 255, 1);
  --labelPrimary: rgba(0, 0, 0, 0.95);
  --shelf-arrow-offset: 38px;
  --skip-button-visibility: hidden;
}

@supports not (font: -apple-system-body) {
  :root {
    --systemPrimary: rgba(0, 0, 0, 0.88);
    --systemPrimary-vibrant: #1d1d1f;
    --systemPrimary-onLight: rgba(0, 0, 0, 0.88);
    --systemPrimary-onDark: rgba(255, 255, 255, 0.92);
    --systemSecondary: rgba(0, 0, 0, 0.56);
    --systemSecondary-vibrant: rgba(0, 0, 0, 0.56);
    --systemSecondary-onLight: rgba(0, 0, 0, 0.56);
    --systemSecondary-onDark: rgba(255, 255, 255, 0.64);
    --systemTertiary: rgba(0, 0, 0, 0.48);
    --systemTertiary-vibrant: rgba(0, 0, 0, 0.48);
    --systemTertiary-onLight: rgba(0, 0, 0, 0.48);
    --systemTertiary-onDark: rgba(255, 255, 255, 0.4);
    --keyColorBackground: #0040dd;






    --systemRed: #ff453a;
    --systemRed-vibrant: #ff4f44;
    --systemRed-default_IC: #ff6961;
    --systemRed-vibrant_IC: #ff4136;
    --systemOrange: #ff9f0a;
    --systemOrange-vibrant: #ffa914;
    --systemYellow: #ffd60a;
    --systemGreen: #32d74b;
    --systemBlue: #0a84ff;
    --systemGray: #98989d;
    --systemPrimary: rgba(255, 255, 255, 0.85);
    --systemSecondary: rgba(255, 255, 255, 0.55);
    --systemTertiary: rgba(255, 255, 255, 0.25);
    --systemQuaternary: rgba(255, 255, 255, 0.1);
    --keyColor: #0a84ff;
    --pageBG: #1f1f1f;
    --appPrimaryBackgroundColor: #2d2d2d;
    --appSecondaryBackgroundColor: rgba(0, 0, 0, 0.3);
    --navigationBackgroundColor: #5b5b5b;
    --navigationTextColor: #c8c8c8;
    --modalBGColor: rgba(45, 45, 45, 1);
    --labelPrimary: rgba(255, 255, 255, 0.95);

    --systemRed: #ff453a;
    --systemRed-vibrant: #ff4f44;
    --systemRed-default_IC: #ff6961;
    --systemRed-vibrant_IC: #ff4136;
    --systemOrange: #ff9f0a;
    --systemOrange-vibrant: #ffa914;
    --systemOrange-default_IC: #ffb340;
    --systemOrange-vibrant_IC: #ffb340;
    --systemYellow: #ffd60a;
    --systemYellow-vibrant: #ffe014;
    --systemYellow-default_IC: #ffd426;
    --systemYellow-vibrant_IC: #ffd426;
    --systemGreen: #32d74b;
    --systemGreen-vibrant: #3ce155;
    --systemGreen-default_IC: #31de4b;
    --systemGreen-vibrant_IC: #31de4b;
    --systemMint: #63e6e2;
    --systemMint-vibrant: #6ce0db;
    --systemMint-default_IC: #63e6e2;
    --systemMint-vibrant_IC: #63e6e2;
    --systemTeal: #6ac4dc;
    --systemTeal-vibrant: #44d4ed;
    --systemTeal-default_IC: #5de6ff;
    --systemTeal-vibrant_IC: #5de6ff;
    --systemCyan: #5ac8f5;
    --systemCyan-vibrant: #5acdfa;
    --systemCyan-default_IC: #70d7ff;
    --systemCyan-vibrant_IC: #70d7ff;
    --systemBlue: #0a84ff;
    --systemBlue-vibrant: #148eff;
    --systemBlue-default_IC: #409cff;
    --systemBlue-vibrant_IC: #409cff;
    --systemBlue-rollover: #47c1ff;
    --systemBlue-pressed: #38b2ff;
    --systemBlue-deepPressed: #47c1ff;
    --systemBlue-disabled: rgba(10, 132, 255, 0.5);
    --systemIndigo: #5e5ce6;
    --systemIndigo-vibrant: #6361f2;
    --systemIndigo-default_IC: #7d7aff;
    --systemIndigo-vibrant_IC: #7d7aff;
    --systemPurple: #bf5af2;
    --systemPurple-vibrant: #cc65ff;
    --systemPurple-default_IC: #da8fff;
    --systemPurple-vibrant_IC: #da8fff;
    --systemPink: #ff375f;
    --systemPink-vibrant: #ff4169;
    --systemPink-default_IC: #ff6482;
    --systemPink-vibrant_IC: #ff3a5f;
    --systemBrown: #ac8e68;
    --systemBrown-vibrant: #b69872;
    --systemBrown-default_IC: #b59469;
    --systemBrown-vibrant_IC: #b59469;
    --systemGray: #98989d;
    --systemGray-vibrant: #a2a2a7;
    --systemGray-default_IC: #98989d;
    --systemGray-vibrant_IC: #98989d;
    --systemPrimary: rgba(255, 255, 255, 0.85);
    --systemPrimary-vibrant: #e5e5e5;
    --systemPrimary-onLight: rgba(0, 0, 0, 0.85);
    --systemPrimary-onDark: rgba(255, 255, 255, 0.85);
    --systemPrimary-default_IC: white;
    --systemPrimary-vibrant_IC: white;
    --systemPrimary-onLight_IC: black;
    --systemPrimary-onDark_IC: white;
    --systemSecondary: rgba(255, 255, 255, 0.55);
    --systemSecondary-vibrant: #7c7c7c;
    --systemSecondary-onLight: rgba(0, 0, 0, 0.5);
    --systemSecondary-onDark: rgba(255, 255, 255, 0.55);
    --systemSecondary-default_IC: rgba(255, 255, 255, 0.7);
    --systemSecondary-vibrant_IC: rgba(255, 255, 255, 0.7);
    --systemSecondary-onLight_IC: rgba(0, 0, 0, 0.76);
    --systemSecondary-onDark_IC: rgba(255, 255, 255, 0.7);
    --systemTertiary: rgba(255, 255, 255, 0.25);
    --systemTertiary-vibrant: #414141;
    --systemTertiary-onLight: rgba(0, 0, 0, 0.25);
    --systemTertiary-onDark: rgba(255, 255, 255, 0.25);
    --systemTertiary-default_IC: rgba(255, 255, 255, 0.5);
    --systemTertiary-vibrant_IC: rgba(255, 255, 255, 0.5);
    --systemTertiary-onLight_IC: rgba(0, 0, 0, 0.56);
    --systemTertiary-onDark_IC: rgba(255, 255, 255, 0.5);
    --systemQuaternary: rgba(255, 255, 255, 0.1);
    --systemQuaternary-vibrant: #232323;
    --systemQuaternary-onLight: rgba(0, 0, 0, 0.1);
    --systemQuaternary-onDark: rgba(255, 255, 255, 0.1);
    --systemQuaternary-default_IC: rgba(255, 255, 255, 0.3);
    --systemQuaternary-vibrant_IC: rgba(255, 255, 255, 0.3);
    --systemQuaternary-onLight_IC: rgba(0, 0, 0, 0.35);
    --systemQuaternary-onDark_IC: rgba(255, 255, 255, 0.3);
    --systemQuinary: rgba(255, 255, 255, 0.05);
    --systemQuinary-vibrant: #080808;
    --systemQuinary-onLight: rgba(0, 0, 0, 0.05);
    --systemQuinary-onDark: rgba(255, 255, 255, 0.05);
    --systemQuinary-default_IC: rgba(255, 255, 255, 0.1);
    --systemQuinary-vibrant_IC: rgba(255, 255, 255, 0.1);
    --systemQuinary-onLight_IC: rgba(0, 0, 0, 0.1);
    --systemQuinary-onDark_IC: rgba(255, 255, 255, 0.1);
    --keyColor: #0a84ff;
    --keyColor-default_IC: #409cff;
    --systemStandardUltrathinMaterialSover: rgba(40, 40, 40, 0.4);
    --systemStandardUltrathinMaterialSover-inactive: #282828;
    --systemStandardUltrathinMaterialSover-default_IC: black;
    --systemStandardUltrathinMaterialSover-inactive_IC: #141414;
    --systemStandardThinMaterialSover: rgba(40, 40, 40, 0.5);
    --systemStandardThinMaterialSover-inactive: #282828;
    --systemStandardThinMaterialSover-default_IC: #0a0a0a;
    --systemStandardThinMaterialSover-inactive_IC: #141414;
    --systemStandardMediumMaterialSover: rgba(40, 40, 40, 0.6);
    --systemStandardMediumMaterialSover-inactive: #282828;
    --systemStandardMediumMaterialSover-default_IC: #141414;
    --systemStandardMediumMaterialSover-inactive_IC: #141414;
    --systemStandardThickMaterialSover: rgba(40, 40, 40, 0.7);
    --systemStandardThickMaterialSover-inactive: #282828;
    --systemStandardThickMaterialSover-default_IC: #1e1e1e;
    --systemStandardThickMaterialSover-inactive_IC: #141414;
    --systemStandardUltrathickMaterialSover: rgba(40, 40, 40, 0.8);
    --systemStandardUltrathickMaterialSover-inactive: #282828;
    --systemStandardUltrathickMaterialSover-default_IC: #282828;
    --systemStandardUltrathickMaterialSover-inactive_IC: #141414;
    --systemHeaderMaterialSover: rgba(30, 30, 30, 0.8);
    --systemHeaderMaterialSover-default_IC: #1e1e1e;
    --systemToolbarTitlebarMaterialSover: rgba(60, 60, 60, 0.8);
    --systemToolbarTitlebarMaterialSover-inactive: #282828;
    --systemToolbarTitlebarMaterialSover-default_IC: #262626;
    --systemToolbarTitlebarMaterialSover-inactive_IC: #141414;
    --labelDivider: rgba(255, 255, 255, 0.1);
    --vibrantDivider: rgba(235, 235, 245, 0.19);
    --pageBG: #1f1f1f;
    --shelfBG: rgba(255, 255, 255, 0.05);
    --genericJoeColor: #323232;
    --fallbackMaterialBG: rgba(31, 31, 31, 0.97);
    --liveBadgePlatterBG: #ff5066;
    --keyColorBackground: #0a84ff;
    --keyColorBackground-default_IC: #409cff;
    --appPrimaryBackgroundColor: #131313;
    --appSecondaryBackgroundColor: rgba(0, 0, 0, 0.3);
    --alertDropShadowColor: rgba(0, 0, 0, 0.55);
    --alertOuterStrokeColor: rgba(0, 0, 0, 0.5);
    --appModalBackgroundColor: #2d2d2d;
    --artworkBorderColor: rgba(151, 151, 151, 0.2);
    --ctaButtonBackgroundColor: white;
    --cardBackgroundColor: var(--systemQuaternary-onDark);
    --defaultButtonBackgroundColor: rgba(84, 84, 84, 0.5);
    --dialogShadowColor: rgba(0, 0, 0, 0.55);
    --disabledLightColor: rbga(255, 255, 255, 0.4);
    --dividerTextColor: rgba(235, 235, 245, 0.19);
    --fauxNativeSheetBackgroundColor: #2d2d2d;
    --labelSecondary: rgba(60, 60, 67, 0.6);
    --liveBadgeBackgroundColor: #ff5066;
    --mobileNavigationBG: #2e2e2e;
    --modalBoxShadowColor: rgba(0, 0, 0, 0.24);
    --modalCloseButtonIconColor: rgba(235, 235, 245, 0.6);
    --modalContentOverlayColorEnd: #2d2d2d;
    --modalContentOverlayColorStart: rgba(45, 45, 45, 0);
    --modalHeaderBackgroundColor: rgba(45, 45, 45, 0.97);
    --modalHeaderShadowColor: rgba(0, 0, 0, 0.2);
    --modalScrimColor: rgba(0, 0, 0, 0.55);
    --navigationBackgroundColor: #5b5b5b;
    --navigationBorderColor: #353535;
    --navigationBorderBottomColor: #484848;
    --navigationBorderLeftColor: #525252;
    --navigationBorderRightColor: #525252;
    --navigationBorderTopColor: #848484;
    --navigationLinkSelectionStateColor: rgba(235, 235, 245, 0.1);
    --navigationMobileMenuBackgroundColor: white;
    --navigationScrollBackgroundColor: rgba(0, 0, 0, 0.4);
    --navigationScrollBackgroundFallbackColor: rgba(0, 0, 0, 0.6);
    --navigationTextColor: #c8c8c8;
    --overlayBackgroundColor: rgba(51, 51, 51, 0.3);
    --overlayButtonPlatterColor: rgba(255, 255, 255, 0.2);
    --personHeaderBackgroundColor: rgba(40, 40, 40, 0.9);
    --playerBackground: rgba(45, 45, 45, 0.88);
    --playbackOverlayBackgroundColor: rgba(51, 51, 51, 0.7);
    --progressBarColor: rgba(255, 255, 255, 0.4);
    --progressBarFillColor: white;
    --searchInputBorderColor: rgba(255, 255, 255, 0.15);
    --settingsMenuPointer: rgba(45, 45, 45, 0.5);
    --sidebarActiveItemBackgroundColor: rgba(235, 235, 245, 0.1);
    --sidebarBackgroundColor: rgba(235, 235, 245, 0.03);
    --sidebarSearchInputBorderColor: rgba(255, 255, 255, 0.15);
    --sportLockupClockTimeBadgeBackgroundColor: rgba(0, 0, 0, 0.2);
    --sportLockupScoreBadgeDivider: rgba(0, 0, 0, 0.15);
    --unifiedVolumeThumbShadow: rgba(0, 0, 0, 0.1);
    --unifiedVolumeThumbShadowInset: rgba(0, 0, 0, 0.15);
    --unifiedVolumeTrackFilled: rgba(246, 246, 246, 0.36);
    --unifiedVolumeThumb: #d0d0d0;
    --unifiedPlayerIcons: rgba(255, 255, 255, 0.9);

  }
}


  :root {
    --systemRed: #ff453a;
    --systemRed-vibrant: #ff4f44;
    --systemRed-default_IC: #ff6961;
    --systemRed-vibrant_IC: #ff4136;
    --systemOrange: #ff9f0a;
    --systemOrange-vibrant: #ffa914;
    --systemOrange-default_IC: #ffb340;
    --systemOrange-vibrant_IC: #ffb340;
    --systemYellow: #ffd60a;
    --systemYellow-vibrant: #ffe014;
    --systemYellow-default_IC: #ffd426;
    --systemYellow-vibrant_IC: #ffd426;
    --systemGreen: #32d74b;
    --systemGreen-vibrant: #3ce155;
    --systemGreen-default_IC: #31de4b;
    --systemGreen-vibrant_IC: #31de4b;
    --systemMint: #63e6e2;
    --systemMint-vibrant: #6ce0db;
    --systemMint-default_IC: #63e6e2;
    --systemMint-vibrant_IC: #63e6e2;
    --systemTeal: #6ac4dc;
    --systemTeal-vibrant: #44d4ed;
    --systemTeal-default_IC: #5de6ff;
    --systemTeal-vibrant_IC: #5de6ff;
    --systemCyan: #5ac8f5;
    --systemCyan-vibrant: #5acdfa;
    --systemCyan-default_IC: #70d7ff;
    --systemCyan-vibrant_IC: #70d7ff;
    --systemBlue: #0a84ff;
    --systemBlue-vibrant: #148eff;
    --systemBlue-default_IC: #409cff;
    --systemBlue-vibrant_IC: #409cff;
    --systemBlue-rollover: #47c1ff;
    --systemBlue-pressed: #38b2ff;
    --systemBlue-deepPressed: #47c1ff;
    --systemBlue-disabled: rgba(10, 132, 255, 0.5);
    --systemIndigo: #5e5ce6;
    --systemIndigo-vibrant: #6361f2;
    --systemIndigo-default_IC: #7d7aff;
    --systemIndigo-vibrant_IC: #7d7aff;
    --systemPurple: #bf5af2;
    --systemPurple-vibrant: #cc65ff;
    --systemPurple-default_IC: #da8fff;
    --systemPurple-vibrant_IC: #da8fff;
    --systemPink: #ff375f;
    --systemPink-vibrant: #ff4169;
    --systemPink-default_IC: #ff6482;
    --systemPink-vibrant_IC: #ff3a5f;
    --systemBrown: #ac8e68;
    --systemBrown-vibrant: #b69872;
    --systemBrown-default_IC: #b59469;
    --systemBrown-vibrant_IC: #b59469;
    --systemGray: #98989d;
    --systemGray-vibrant: #a2a2a7;
    --systemGray-default_IC: #98989d;
    --systemGray-vibrant_IC: #98989d;
    --systemPrimary: rgba(255, 255, 255, 0.85);
    --systemPrimary-vibrant: #e5e5e5;
    --systemPrimary-onLight: rgba(0, 0, 0, 0.85);
    --systemPrimary-onDark: rgba(255, 255, 255, 0.85);
    --systemPrimary-default_IC: white;
    --systemPrimary-vibrant_IC: white;
    --systemPrimary-onLight_IC: black;
    --systemPrimary-onDark_IC: white;
    --systemSecondary: rgba(255, 255, 255, 0.55);
    --systemSecondary-vibrant: #7c7c7c;
    --systemSecondary-onLight: rgba(0, 0, 0, 0.5);
    --systemSecondary-onDark: rgba(255, 255, 255, 0.55);
    --systemSecondary-default_IC: rgba(255, 255, 255, 0.7);
    --systemSecondary-vibrant_IC: rgba(255, 255, 255, 0.7);
    --systemSecondary-onLight_IC: rgba(0, 0, 0, 0.76);
    --systemSecondary-onDark_IC: rgba(255, 255, 255, 0.7);
    --systemTertiary: rgba(255, 255, 255, 0.25);
    --systemTertiary-vibrant: #414141;
    --systemTertiary-onLight: rgba(0, 0, 0, 0.25);
    --systemTertiary-onDark: rgba(255, 255, 255, 0.25);
    --systemTertiary-default_IC: rgba(255, 255, 255, 0.5);
    --systemTertiary-vibrant_IC: rgba(255, 255, 255, 0.5);
    --systemTertiary-onLight_IC: rgba(0, 0, 0, 0.56);
    --systemTertiary-onDark_IC: rgba(255, 255, 255, 0.5);
    --systemQuaternary: rgba(255, 255, 255, 0.1);
    --systemQuaternary-vibrant: #232323;
    --systemQuaternary-onLight: rgba(0, 0, 0, 0.1);
    --systemQuaternary-onDark: rgba(255, 255, 255, 0.1);
    --systemQuaternary-default_IC: rgba(255, 255, 255, 0.3);
    --systemQuaternary-vibrant_IC: rgba(255, 255, 255, 0.3);
    --systemQuaternary-onLight_IC: rgba(0, 0, 0, 0.35);
    --systemQuaternary-onDark_IC: rgba(255, 255, 255, 0.3);
    --systemQuinary: rgba(255, 255, 255, 0.05);
    --systemQuinary-vibrant: #080808;
    --systemQuinary-onLight: rgba(0, 0, 0, 0.05);
    --systemQuinary-onDark: rgba(255, 255, 255, 0.05);
    --systemQuinary-default_IC: rgba(255, 255, 255, 0.1);
    --systemQuinary-vibrant_IC: rgba(255, 255, 255, 0.1);
    --systemQuinary-onLight_IC: rgba(0, 0, 0, 0.1);
    --systemQuinary-onDark_IC: rgba(255, 255, 255, 0.1);
    --keyColor: #0a84ff;
    --keyColor-default_IC: #409cff;
    --systemStandardUltrathinMaterialSover: rgba(40, 40, 40, 0.4);
    --systemStandardUltrathinMaterialSover-inactive: #282828;
    --systemStandardUltrathinMaterialSover-default_IC: black;
    --systemStandardUltrathinMaterialSover-inactive_IC: #141414;
    --systemStandardThinMaterialSover: rgba(40, 40, 40, 0.5);
    --systemStandardThinMaterialSover-inactive: #282828;
    --systemStandardThinMaterialSover-default_IC: #0a0a0a;
    --systemStandardThinMaterialSover-inactive_IC: #141414;
    --systemStandardMediumMaterialSover: rgba(40, 40, 40, 0.6);
    --systemStandardMediumMaterialSover-inactive: #282828;
    --systemStandardMediumMaterialSover-default_IC: #141414;
    --systemStandardMediumMaterialSover-inactive_IC: #141414;
    --systemStandardThickMaterialSover: rgba(40, 40, 40, 0.7);
    --systemStandardThickMaterialSover-inactive: #282828;
    --systemStandardThickMaterialSover-default_IC: #1e1e1e;
    --systemStandardThickMaterialSover-inactive_IC: #141414;
    --systemStandardUltrathickMaterialSover: rgba(40, 40, 40, 0.8);
    --systemStandardUltrathickMaterialSover-inactive: #282828;
    --systemStandardUltrathickMaterialSover-default_IC: #282828;
    --systemStandardUltrathickMaterialSover-inactive_IC: #141414;
    --systemHeaderMaterialSover: rgba(30, 30, 30, 0.8);
    --systemHeaderMaterialSover-default_IC: #1e1e1e;
    --systemToolbarTitlebarMaterialSover: rgba(60, 60, 60, 0.8);
    --systemToolbarTitlebarMaterialSover-inactive: #282828;
    --systemToolbarTitlebarMaterialSover-default_IC: #262626;
    --systemToolbarTitlebarMaterialSover-inactive_IC: #141414;
    --labelDivider: rgba(255, 255, 255, 0.1);
    --vibrantDivider: rgba(235, 235, 245, 0.19);
    --pageBG: #1f1f1f;
    --shelfBG: rgba(255, 255, 255, 0.05);
    --genericJoeColor: #323232;
    --fallbackMaterialBG: rgba(31, 31, 31, 0.97);
    --liveBadgePlatterBG: #ff5066;
    --keyColorBackground: #0a84ff;
    --keyColorBackground-default_IC: #409cff;
    --appPrimaryBackgroundColor: #2d2d2d;
    --appSecondaryBackgroundColor: rgba(0, 0, 0, 0.3);
    --alertDropShadowColor: rgba(0, 0, 0, 0.55);
    --alertOuterStrokeColor: rgba(0, 0, 0, 0.5);
    --appModalBackgroundColor: #2d2d2d;
    --artworkBorderColor: rgba(151, 151, 151, 0.2);
    --ctaButtonBackgroundColor: white;
    --cardBackgroundColor: var(--systemQuaternary-onDark);
    --defaultButtonBackgroundColor: rgba(84, 84, 84, 0.5);
    --dialogShadowColor: rgba(0, 0, 0, 0.55);
    --disabledLightColor: rbga(255, 255, 255, 0.4);
    --dividerTextColor: rgba(235, 235, 245, 0.19);
    --fauxNativeSheetBackgroundColor: #2d2d2d;
    --labelSecondary: rgba(60, 60, 67, 0.6);
    --liveBadgeBackgroundColor: #ff5066;
    --mobileNavigationBG: #2e2e2e;
    --modalBoxShadowColor: rgba(0, 0, 0, 0.24);
    --modalCloseButtonIconColor: rgba(235, 235, 245, 0.6);
    --modalContentOverlayColorEnd: #2d2d2d;
    --modalContentOverlayColorStart: rgba(45, 45, 45, 0);
    --modalHeaderBackgroundColor: rgba(45, 45, 45, 0.97);
    --modalHeaderShadowColor: rgba(0, 0, 0, 0.2);
    --modalScrimColor: rgba(0, 0, 0, 0.55);
    --navigationBackgroundColor: #5b5b5b;
    --navigationBorderColor: #353535;
    --navigationBorderBottomColor: #484848;
    --navigationBorderLeftColor: #525252;
    --navigationBorderRightColor: #525252;
    --navigationBorderTopColor: #848484;
    --navigationLinkSelectionStateColor: rgba(235, 235, 245, 0.1);
    --navigationMobileMenuBackgroundColor: white;
    --navigationScrollBackgroundColor: rgba(0, 0, 0, 0.4);
    --navigationScrollBackgroundFallbackColor: rgba(0, 0, 0, 0.6);
    --navigationTextColor: #c8c8c8;
    --overlayBackgroundColor: rgba(51, 51, 51, 0.3);
    --overlayButtonPlatterColor: rgba(255, 255, 255, 0.2);
    --personHeaderBackgroundColor: rgba(40, 40, 40, 0.9);
    --playerBackground: rgba(45, 45, 45, 0.88);
    --playbackOverlayBackgroundColor: rgba(51, 51, 51, 0.7);
    --progressBarColor: rgba(255, 255, 255, 0.4);
    --progressBarFillColor: white;
    --searchInputBorderColor: rgba(255, 255, 255, 0.15);
    --settingsMenuPointer: rgba(45, 45, 45, 0.5);
    --sidebarActiveItemBackgroundColor: rgba(235, 235, 245, 0.1);
    --sidebarBackgroundColor: rgba(235, 235, 245, 0.03);
    --sidebarSearchInputBorderColor: rgba(255, 255, 255, 0.15);
    --sportLockupClockTimeBadgeBackgroundColor: rgba(0, 0, 0, 0.2);
    --sportLockupScoreBadgeDivider: rgba(0, 0, 0, 0.15);
    --unifiedVolumeThumbShadow: rgba(0, 0, 0, 0.1);
    --unifiedVolumeThumbShadowInset: rgba(0, 0, 0, 0.15);
    --unifiedVolumeTrackFilled: rgba(246, 246, 246, 0.36);
    --unifiedVolumeThumb: #d0d0d0;
    --unifiedPlayerIcons: rgba(255, 255, 255, 0.9);
  }


  @supports not (font: -apple-system-body) {
    :root {
      --systemPrimary: rgba(255, 255, 255, 0.92);
      --systemPrimary-vibrant: #f5f5f7;
      --systemPrimary-onLight: rgba(0, 0, 0, 0.88);
      --systemPrimary-onDark: rgba(255, 255, 255, 0.92);
      --systemSecondary: rgba(255, 255, 255, 0.64);
      --systemSecondary-vibrant: #a1a1a6;
      --systemSecondary-onLight: rgba(0, 0, 0, 0.56);
      --systemSecondary-onDark: rgba(255, 255, 255, 0.64);
      --systemTertiary: rgba(255, 255, 255, 0.4);
      --systemTertiary-vibrant: #6e6e73;
      --systemTertiary-onLight: rgba(0, 0, 0, 0.48);
      --systemTertiary-onDark: rgba(255, 255, 255, 0.4);
      --keyColorBackground: #0040dd;
    }
  }


.iphone-pro-max {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.iphone-pro-max .overlap-wrapper {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a,
blockquote,
body,
code,
dd,
div,
dl,
dt,
embed,
fieldset,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
img,
legend,
li,
ol,
p,
pre,
section,
table,
td,
th,
ul {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  margin: 0;
  padding: 0;
}

.product-header__wrapper--with-color-transition,
.product-header__wrapper--with-color-transition,
.coming-soon__channel,
.product-header__wrapper--with-color-transition,
.secondary-text {
  transition: var(--transition);
}

.product-header__wrapper.dark-content,
.coming-soon__channel,
.product-header__wrapper.dark-content,
.secondary-text {
  color: var(--systemSecondary-onDark);
}

.flex-grow-1 {
  max-width: 1280px;
  min-width: 300px;
}

button {
  background: none;
  border: 0;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  overflow: visible;
  vertical-align: inherit;
}

.product-footer-languages-modal__item,
.review-card__details__list,
ul {
  list-style-type: none;
}

.h2 {
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-size: 17px;
}

.episode-shelf-header .ember-basic-dropdown .ember-power-select-trigger,
.typ-headline-emph {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.23;
}

.my-footer-format {
  background: #0035a0;
  box-shadow: 0px 0px 0px 0px #dfe3ec00;
  min-height: 50px;
  text-align: left;
  padding: 0px;
}

@media screen and (min-width: 801px) {
  .group {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    left: auto;
    right: 0px;
  }
}

/* Adjustments for smaller screens */
@media screen and (max-width: 430px) {
  .iphone-pro-max .overlap-wrapper,
  .iphone-pro-max .root,
  .iphone-pro-max .flex-screen,
  .iphone-pro-max .group,
  .iphone-pro-max .overlap-group-wrapper,
  .iphone-pro-max .overlap-group {
    width: 100%;
  }

  .iphone-pro-max .group-wrapper {
    width: 100%; /* Adjust width with some padding */
  }

  .iphone-pro-max .text-wrapper-3,
  .iphone-pro-max .text-wrapper-4 {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  /* Further adjustments as needed */
}

.iphone-pro-max .overlap {
  width: 100%;
  position: relative;
}

.iphone-pro-max .root {
  /* background-color: #33373a; */
  height: 930px;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  /* width: 100%; */
}

.iphone-pro-max .flex-screen {
  /* background-color: #33373a; */
  height: 930px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  /* width: 100%; */
}

.iphone-pro-max .group {
  /* background-color: #33373a; */
  height: 890px;
  left: 0;
  position: absolute;
  top: 80px;
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  right: 0;
}

.iphone-pro-max .overlap-group-wrapper {
  height: 890px;
  position: relative;
  top: -20px;
  width: 100%;
}

.iphone-pro-max .overlap-group {
  /* background-color: #14171C; */
  border-radius: 9px;
  height: 890px;
  position: relative;
  width: 100%;
}

.iphone-pro-max .text-wrapper {
  color: #5ec16a;
  font-family: "-apple-system", BlinkMacSystemFont, "-webkit-system-font",
    "Malgun Gothic", "Segoe UI", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  height: 28px;
  left: 167px;
  left: calc(50% - 46px);
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 874px;
  width: 92px;
}

.see-all {
  position: relative;
}

.iphone-pro-max .group-wrapper {
  /* height: 60px; */
  left: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  top: 84px;
  width: 100%;
  /* border-top: .3px solid transparent; */
  border-image-source: linear-gradient(
    to right,
    transparent 80px,
    #49494985 80px
  );
  border-image-slice: 1;
}

.group-2 {
  position: relative;
  /* overflow: hidden;  */
  height: 70px;
  border-radius: 0px;
  background-size: cover;
  background-position: center;
}

.group-2::before {
  /* color: black; */
  border-radius: 15px;
  height: 70px;
  position: relative;
  width: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: -1; /* Place the pseudo-element behind the content */
}

.iphone-pro-max .div-wrapper {
  /* height: 46px;
  width: 46px; */
  height: 20px;
  width: 20px;
  position: absolute;
  top: 24px;
  right: 10px;
}

.iphone-pro-max .overlap-group-2 {
  background-color: var(--variable-collection-main1);
  border-radius: 4px;
  height: 100%;
  position: relative;
  width: 100%;
}

.iphone-pro-max .text-wrapper-2 {
  /* color: #000000; */
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 600;
  height: 27px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 7px;
  white-space: nowrap;
  width: 100%;
}

.iphone-pro-max .group-3 {
  height: 28px;
  left: 118px;
  position: absolute;
  top: 5px;
  font-size: 12px;
  right: 70px;
}

.iphone-pro-max .text-wrapper-3 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* color: #ffffff; */
  display: -webkit-box;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 24px;
  left: 11px;
  letter-spacing: 0;
  line-height: normal;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 9px;
  width: 100%;
}

.text-wrapper-3 {
  font-size: 18px !important;
}

.iphone-pro-max .text-wrapper-4 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* color: #b8b8b8; */
  display: -webkit-box;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 20px;
  left: 11px;
  letter-spacing: 0;
  line-height: normal;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 34px;
}

.text-wrapper-41 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* color: #b8b8b8; */
  display: -webkit-box;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 20px;
  left: 11px;
  letter-spacing: 0;
  line-height: normal;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 58px;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.9
  ); /* Adjust the alpha value (0.5) to increase or decrease darkness */
  z-index: 0;
}

.background-poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.filter-toggle-button {
  cursor: pointer;
  padding: 10px 20px;
  /* background-color: #ff00c1; */
  /* color: white; */
  text-align: center;
  border-radius: 5px;
  margin: 10px 0 10px 25px;
  right: 22px;
  position: absolute;
  top: 185px;
}

.filter-button-unfiltered {
  border: 2px #494949 solid; /* Example color for unfiltered state */
}

.filter-button-filtered {
  border: 2px #51ff48 solid !important; /* Example color for filtered state */
}

.star {
  /* color: #fff; */
  font-size: 10px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 30px;
  border-radius: 50px;
}

.star::before {
  content: "";
  display: block;
  background-color: inherit; /* Inherit the background color */
}

.star[aria-pressed="false"] {
  background-color: #494949; /* Gray color for unseen */
}

.star[aria-pressed="true"] {
  background-color: #51ff48; /* Green color for seen */
  border-radius: 100px;
}

/* Focus state - Optional */
.star:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.iphone-pro-max .group-4 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 166px;
  width: 385px;
}

.iphone-pro-max .text-wrapper-5 {
  /* color: #000000; */
  font-family: "Inter", Helvetica;
  font-size: 23px;
  font-weight: 700;
  height: 27px;
  left: 9px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
  white-space: nowrap;
  width: 32px;
}

.iphone-pro-max .group-5 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 250px;
  width: 385px;
}

.iphone-pro-max .group-6 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 334px;
  width: 385px;
}

.iphone-pro-max .group-7 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 419px;
  width: 385px;
}

.iphone-pro-max .group-8 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 503px;
  width: 385px;
}

.iphone-pro-max .group-9 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 587px;
  width: 385px;
}

.iphone-pro-max .group-10 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 672px;
  width: 385px;
}

.iphone-pro-max .group-11 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 756px;
  width: 385px;
}

.iphone-pro-max .group-12 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 0;
  width: 385px;
}

.iphone-pro-max .text-wrapper-6 {
  /* color: #ffffff; */
  font-family: "Inter", Helvetica;
  font-size: 26px;
  font-weight: 700;
  height: 69px;
  display: flex;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.iphone-pro-max .group-13 {
  border-bottom: 0.3px solid #494949;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    90deg,
    transparent,
    transparent 10px,
    #49494985 0,
    #49494985 calc(100% - 10px),
    transparent calc(100% - 10px),
    transparent
  );
  height: 60px;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  width: 100%;
  background-color: #000;
}

.iphone-pro-max .overlap-2 {
  border-radius: 5px;
  height: 39px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 126px;
}

.iphone-pro-max .group-14 {
  height: 24px;
  left: 41px;
  position: absolute;
  top: 8px;
  width: 81px;
}

.iphone-pro-max .text-wrapper-7 {
  /* color: #ffffff; */
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 700;
  height: 24px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 76px;
  color: var(--systemPrimary-onDark);

  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.47;
}

.iphone-pro-max .group-15 {
  background-color: #ffffff;
  border-radius: 11.7px;
  height: 20px;
  left: 8px;
  position: absolute;
  top: 10px;
  width: 20px;
}

.iphone-pro-max .overlap-3 {
  border-radius: 0px;
  height: 39px;
  right: 10px;
  position: absolute;
  top: 10px;
  width: 126px;
}

.login-button {
  border-radius: 0px;
  height: 39px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 126px;
  border: 2px solid #49494900;
}

.movie-rank-cont {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 18px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  left: 0px;
}

.movie-rank-pos {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.iphone-pro-max .group-16 {
  height: 24px;
  left: 10px;
  position: absolute;
  top: 5px;
  width: 72px;
}

.iphone-pro-max .text-wrapper-8 {
  /* color: #ffffff; */
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 700;
  height: 24px;
  left: -2;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
  width: 60px;
}

.iphone-pro-max .group-17 {
  border-radius: 11.7px;
  height: 30px;
  left: 85px;
  position: absolute;
  top: 2px;
  width: 30px;
}

.logout-button {
  border: 2px #49494900 solid;
  /* background-color: #1b1e25; */
}

.rounded-circle {
  border-radius: 50%;
  border: 0.3px #494949 solid !important;
  position: relative;
  right: -68px;

  position: absolute;
  right: 15px;
}

.iphone-pro-max .overlap-4 {
  border-radius: 5px;
  height: 39px;
  left: 220px;
  position: absolute;
  top: 10px;
  width: 10%;
}

.iphone-pro-max .rectangle {
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgb(91.81, 91.81, 91.81),
      rgba(143.27, 143.27, 143.27, 0)
    )
    1;
  border-radius: 5px;
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 64px;
}

.iphone-pro-max .group-18 {
  height: 37px;
  left: 14px;
  position: absolute;
  top: 1px;
  width: 37px;
}

.iphone-pro-max .group-19 {
  height: 20px;
  left: 9px;
  position: relative;
  top: 8px;
  width: 20px;
}

.iphone-pro-max .group-20 {
  height: 20px;
  width: 19px;
}

.iphone-pro-max .overlap-group-3 {
  height: 21px;
  position: relative;
  width: 20px;
}

.iphone-pro-max .line {
  height: 7px;
  left: 13px;
  position: absolute;
  top: 13px;
  width: 7px;
}

.iphone-pro-max .ellipse {
  border: 2.5px solid;
  border-color: #ffffff;
  border-radius: 8.68px/8.9px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.iphone-pro-max .overlap-5 {
  border-radius: 5px;
  height: 39px;
  position: absolute;
  top: 10px;
  width: 10%;
}

.iphone-pro-max .group-21 {
  height: 37px;
  left: 14px;
  position: relative;
  top: 1px;
  width: 37px;
}

.iphone-pro-max .group-22 {
  height: 25px;
  left: 7px;
  position: relative;
  top: 5px;
  width: 22px;
}

.iphone-pro-max .overlap-group-4 {
  height: 20px;
  left: 2px;
  position: relative;
  top: 5px;
  width: 18px;
}

.iphone-pro-max .polygon {
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.iphone-pro-max .rectangle-2 {
  background-color: #d9d9d9;
  border-radius: 1px;
  height: 9px;
  left: 7px;
  position: absolute;
  top: 10px;
  width: 3px;
}

.search-submit {
  position: absolute;
  top: 79px;
  right: 11px;
  background-color: #1954d3;
  /* color: white; */
  border: 0px white solid;
  border-radius: 25px;
  width: 74px;
  height: 32px;
}

.filter-button-text {
  /* color: rgb(255, 255, 255) !important; */
  font-size: 16px !important;
  font-weight: 500 !important;
}

.filter-button {
  border: 2px #49494900 solid;
  height: 32px !important;
  border-radius: 11px;
  background-color: #1c1c1e !important;
}

.filter-text {
  top: -4px !important;
}

.filter-wrapper {
  margin-bottom: 8px !important;
  border: 0px !important;
}

/* Adjustments for the loading screen */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100vw;
  background-color: black;
  background-color: var(--appPrimaryBackgroundColor);
  font-weight: 500;
  /* color: white; */
  font-size: 20px;
}

.loading-text {
  text-align: center;
}

.movie-poster {
  width: 67px;
  height: 100px;
  left: 50px;
  top: 0px;

  width: 60px;
  height: 60px;
  top: 5px;
  border-radius: 0px;
  position: absolute;
}

.movie-poster-img {
  width: 67px;
  height: 99px;
  object-fit: cover;
  border-radius: 6px;
  border: 0.3px #49494985 solid;
}

.movie-poster-wide {
  width: 1000%;
  height: 100px;
  object-fit: cover;
}

.foot-space {
  height: 20px !important;
  border: none !important;
}

.iphone-pro-max .div {
  width: 100%; /* Ensure it occupies full width of its parent */
  min-width: 300px;
  max-width: 1280px;
  position: relative;
  padding: 10px; /* Add padding to avoid touching the screen edges */
  padding: 0 25px;
  padding-top: 10px;
}

.nav-bar-display {
  display: block;
}

.search-input {
  padding: 10px;
  padding-left: 36px;
  border-radius: 10px;
  border: 1px solid #49494949; /* Adjust border to be visible */
  font-size: 16px; /* Adjust font size for better visibility */
  width: 100%; /* Adjust width to account for padding */
  background-color: #1c1c1e;
  /* color: white; */
  box-sizing: border-box;
  outline: none;
  margin-bottom: 5px;
  position: relative;
  font-weight: 500;
  height: 40px;
  display: block !important;
}

.search-input:focus {
  border: 1px solid #51ff48; /* Default border color */
  outline: none; /* Ensures no default browser outline */
}

.search-form {
  margin-bottom: 10px;
  position: relative;
  height: 40px;
  left: 0px;
  right: 10px;
}

.search-bar {
  display: flex;
  align-items: center;
  position: relative;
}

.search-icon {
  position: absolute;
  left: 10px;
  height: 23px;
  width: 20px;
  z-index: 1;
}

.filter-container {
  border: 1px solid #49494949;
  border: 1px solid #49494900;
  border-radius: 10px;
  display: flex;
  gap: 5px;
  height: 40px;
  /* margin-bottom: 15px; */
  overflow-x: auto;
  background-color: #1c1c1e;
  /* padding: 4px; */

  margin-top: 15px;
  background: 0 0;
}

.filter-container-explore {
  padding: 0 40px;
  margin-bottom: 10px;
}

.filter-container-expanded {
  /* Expanded height when a dropdown is open */
}

.filter-container::-webkit-scrollbar {
  display: none; /* Hides the scrollbar for Webkit browsers */
}

.body::-webkit-scrollbar {
  display: none; /* Hides the scrollbar for Webkit browsers */
}

.dropdown {
  position: relative;
  display: inline-block;
  flex-shrink: 0; /* Prevent dropdowns from shrinking */
}

.dropdown-menu {
  background-color: #1c1c1e;
  border-radius: 0px 0px 8px 8px;
  display: block;
  max-height: 300px;
  min-width: 12rem !important;
  overflow-y: auto;
  padding: 0.5rem;
  padding-top: 0.25rem;
  position: absolute !important;
  width: calc(100% - 50px);
  z-index: 1000;
  border: 1px solid #49494900;
  left: auto;
  right: auto;

  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2),
    0 8px 40px var(--dialogShadowColor);
  background-color: var(--systemStandardThickMaterialSover-default_IC);

  background-color: var(--systemStandardThickMaterialSover);
  -webkit-backdrop-filter: blur(60px) saturate(210%);
  backdrop-filter: blur(60px) saturate(210%);

  padding: 6px 10px;
  /* width: 282px; */
  max-height: 300px;
  position: absolute;
  top: 167px;
  border-radius: 9px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: start;
  z-index: 9953;
}

.explore-dropdown-menu {
  top: 100px;
  width: calc(100% - 40px);
  /* margin-left: auto;
  margin-right: auto; */
  padding: 6px 10px;
  /* width: 282px; */
  max-height: 500px;
  position: absolute;
  border-radius: 9px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: start;
  margin: 20px;
  z-index: 9953;
}

@media only screen and (max-width: 739px) {
  .filter-container-explore {
    scroll-snap-type: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 25px;
  }
}

.dropdown-menu::-webkit-scrollbar {
  display: none; /* Hides the scrollbar for Webkit browsers */
}

.dropdown-icon {
  height: 10px;
  position: absolute;
  right: 11px;
  top: 14px;
  transform: translateY(-50%);
  transform: rotate(90deg);
  width: 16px;
  fill: var(--navigationIconColor);
  z-index: 1;
}

.dropdown-button {
  align-items: center;
  background-color: #343639;
  background-color: var(--systemBlue);

  border: 1px solid #494949;
  border-radius: 8px;
  /* color: #fff; */
  color: var(--systemPrimary);
  height: 24px;
  cursor: pointer;
  display: flex;
  padding: 6px 33px 6px 10px;
  position: relative;

  border-radius: 50px;
  background: 0 0;
  border: none;
}

.dropdown-button:focus {
  outline: none;
}

.dropdown-button.active {
  border: 1px solid #51ff481f;
  background-color: #184116ad;

  background-color: var(--systemBlue);
  color: white;
  border: none;

  .dropdown-icon {
    fill: white;
  }
}

.checkmark-icon {
  height: 14px;
  margin-left: -5px;
  margin-right: 6px;
  position: absolute;
  width: 14px;
  z-index: 1;
  margin-top: 2px;
}

.button-text {
  font-weight: bold;
}

.dropdown-menu.visible {
  visibility: visible; /* Make visible when dropdown is active */
}

.dropdown-item {
  padding: 8px 16px;
  background-color: transparent;
  /* color: white; */
  border: none;
  text-align: left;
  width: 100%;
  width: calc(100% - 50px);
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0.5rem;
  color: var(--systemPrimary);

  font-size: 14px;
  padding: 6px 12px;
  margin: 8px 4px 8px 0px;
}

.dropdown-item-nav {
  padding: 8px 16px;
  background-color: transparent;
  /* color: white; */
  border: none;
  text-align: left;
  /* width: 100%; */
  /* width: calc(100% - 50px); */
  /* width: calc(100%); */
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0.5rem;
  display: block;
  font-size: 14px;
  padding: 6px 12px;
  margin: 8px 4px 8px 0px;
  /* color: var(--keyColor); */
  background-color: var(--systemToolbarTitlebarMaterialSover);
}

button:focus {
  outline: none;
}

.dropdown-item:hover,
.dropdown-item:focus {
  /* background-color: rgb(42 47 58); */
  /* background-color: #343639;
  border-radius: 0.5rem; */
  color: var(--systemPrimary);
  background: none;
  text-decoration: none;
  outline: none;
  /* color: white; */
}

.dropdown-item-nav:hover,
.dropdown-item-nav:focus {
  /* background-color: rgb(42 47 58); */
  /* background-color: #343639;
  border-radius: 0.5rem; */
  outline: none;
  color: var(--systemPrimary);
  text-decoration: none;
  /* color: white; */
}

.hr-line {
  border-top: 1px solid #2a2f3a;
  color: inherit;
  height: 0;
  margin: 0;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  font-size: 15px;
  font-weight: 500;
  line-height: inherit;
  box-sizing: border-box;
}

.see-more-text {
  color: #51ff48;
  font-family: Inter, Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 167px;
  left: calc(50% - 46px);
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  margin: 10px;
}

.dropdown-text {
  margin-left: 6px;
  font-weight: 600;
}

.flexy-sexy {
  background-color: #17171c;
  background-color: #6a6a6a08;
  border: 0.3px solid #ffffff2b;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;
  border-radius: 20px;
  padding: 0px;
  right: 0;
  top: 15px;
  width: 100%;
  position: relative;
  margin-bottom: 40px;

  background: 0 0;
  background-color: #25252576;
  border: none;
  border-radius: 10px;

  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2),
    0 8px 40px var(--dialogShadowColor);
  background-color: var(--systemStandardThickMaterialSover-default_IC);

  background-color: var(--systemStandardThickMaterialSover);
  -webkit-backdrop-filter: blur(60px) saturate(210%);
  backdrop-filter: blur(60px) saturate(210%);
}

@media screen and (min-width: 500px) {
  .flexy-sexy {
    padding: 10px;
  }
}

@media screen and (min-width: 600px) {
  .flexy-sexy {
    padding: 20px;
  }
}

.clear-all {
  background-color: #b7002b24;
  border: 1px #b7000047 solid;
  padding-right: 12px;
}

.movie-wrapper-div {
  margin-top: 15px;
  padding-bottom: 20px;
}

.nav-selector {
  border-radius: 0px;
  height: 39px;
  right: 10px;
  position: absolute;
  top: 10px;
  max-width: 200px;

  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.47;
}

.nav-dropdown-menu {
  top: 35px !important;
  right: 0px !important;
  border-radius: 8px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.47;
  padding: 10px;

  width: 100% !important;
  color: var(--systemPrimary);
}

.footer-background {
  background-color: #090a0c !important;
  border-top: 0.3px solid #494949;
  box-shadow: 0px 0px 0px 0px #00000000;
}

.movie-detail-popup-inner {
  position: relative;
  background-color: #000000;
  background-color: #757575c7;
  z-index: 1001;
  width: calc(100% - 0px);
  /* height: calc(100% - 20px); */
  /* max-height: calc(100% - 20px); */
  /* max-width: 738px; */
  /* top: 10px; */
  /* top: 10px;
  left: 0px;
  right: 0px; */
  border-radius: 0px;
  /* padding: 40px; */
  /* border: 2px solid #49494928; */
  /* border: .3px solid #ff0000; */
  /* margin: 20px; */
  /* outline: #000000 solid 10px; */
  /* max-width: 800px; */

  margin: 0px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}

.fullscreen {
  position: fixed;
  background-color: #000000;
  z-index: 1001;
  width: calc(100% - 0px);
  /* height: calc(100% - 20px); */
  max-height: calc(100% - 0px);
  top: 0px;
  left: 0px;
  border-radius: 0px;
  padding: 0px;
  border: 0px solid #0c0fc6;
  margin: 0px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  max-width: none;
  background-color: #000000e6;
  /* background-color: var(--appPrimaryBackgroundColor); */
  --transition: color 250ms ease-in-out;
}

.close-button {
  position: absolute;
  background-color: rgba(53, 53, 53, 1);
  top: 15px;
  right: 20px;
  height: 40px;
  width: 40px;
  /* margin: 20px; */
  /* border-radius: 10px; */
  /* width: 30px;
  height: 30px; */
  padding: 4px;
  outline: none;
  /* color: white; */

  align-items: center;
  border: 1px solid #49494900;
  border-radius: 50px;
  /* color: #fff; */
  cursor: pointer;
  z-index: 1002;
  /* padding: 6px 10px 6px 10px; */
}

.movie-poster-img-full {
  width: 100%;
  height: 100%;
  max-height: 800px;
  object-fit: contain;
  border-radius: 6px;
  border: 0.3px #49494985 solid;
  margin-bottom: 20px;
  margin-top: 10px;
}

.movie-detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.movie-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-color: #ddd; /* Example background color, replace as needed */
  border-radius: 50%; /* Optional: for rounded corners */
}

.full-movie-text {
  margin-top: auto;
  margin-bottom: auto;
}

.divider {
  margin: 0 auto;
  width: calc(100% - 80px);
  /* border-top: 0.3px solid #494949; */
  border-bottom: 1px solid var(--labelDivider);
}

.divider2 {
  margin: 0 auto;
  width: calc(100% - 0px);
  /* background-color: #0c0fc6; */
  background-color: #00000098;
  border: 0.3px solid #494949;
  padding: 25px;
}

.about-section {
  background-color: #000000;
}

.movie-img-popup {
  padding: 0px;
}

.movie-poster-img-popup {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: cover;
  border: 0.3px #49494985 solid;
  backdrop-filter: saturate(180%) blur(60px);
  mask-image: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 0, 0, 0.068) 3.3%,
    rgba(0, 0, 0, 0.145) 5.9%,
    rgba(0, 0, 0, 0.227) 8.1%,
    rgba(0, 0, 0, 0.313) 10.1%,
    rgba(0, 0, 0, 0.401) 12.1%,
    rgba(0, 0, 0, 0.49) 14.6%,
    rgba(0, 0, 0, 0.578) 17.7%,
    rgba(0, 0, 0, 0.661) 21.8%,
    rgba(0, 0, 0, 0.74) 27.1%,
    rgba(0, 0, 0, 0.812) 33.9%,
    rgba(0, 0, 0, 0.875) 42.4%,
    rgba(0, 0, 0, 0.927) 53%,
    rgba(0, 0, 0, 0.966) 66%,
    rgba(0, 0, 0, 0.991) 81.5%,
    rgba(0, 0, 0, 0.991) 100%
  );
}

.movie-img-popup-test {
  padding: 0px;

  width: 100%;
  height: calc(111vw / 0.65);
  max-height: 80vh;
  background-color: #000 !important;
  position: relative;
  margin: auto;
  max-width: 1280px;
  height: calc(1680px * 9 / 16);
  --transition: color 250ms ease-in-out;
  z-index: 1;
}

.product-header__main__container {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  /* height: 50%; */
  /* background-color: white; */
  padding-top: 100px;
  z-index: 5;
}

.product-header__content__container {
  width: 100%;
  display: grid;
  padding: 0 40px 30px;
  grid-gap: 0 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  text-align: center;
}

.product-header__content__details__metadata--info--xs-view {
  /* display: none; */
  position: relative;
  display: block;
  margin-top: 5px;
  /* margin-bottom: 10px; */
  text-align: center;
  font-size: 11px;
  line-height: 1.27273;
  font-weight: 600;
  letter-spacing: 0;
  letter-spacing: -0.1px;
  color: var(--systemSecondary-onDark);
}

@media only screen and (max-width: 739px) {
  .product-header__content__details__metadata--info--xs-view {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 8px;
    text-align: center;
    font-size: 11px;
    line-height: 1.27273;
    font-weight: 600;
    letter-spacing: 0;
    letter-spacing: -0.1px;
  }
}

.product-header__content__availability-text {
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.dark-content .video-data-services-button__availability {
  color: var(--systemPrimary-onDark);
}

.video-data-services-button__availability {
  width: 100%;
  color: var(--systemSecondary-onDark);
  text-align: center;
}

.typography-callout {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0;
}

@media only screen and (max-width: 739px) {
  .product-header__content__container {
    padding: 100px 25px 25px;
    display: block;
    position: absolute;
    bottom: 0px;
  }
}
/* 
.product-header__content__details__metadata--info--xs-view {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 14px;
  line-height: 1.27273;
  font-weight: 600;
  letter-spacing: 0;
  letter-spacing: -.1px;
  color: white;
} */

.product-header__image-bg {
  object-fit: cover;
  object-position: 0 25%;
  /* margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s ease-in-out;
  z-index: 1; */

  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  z-index: 1;

  user-select: none;
  -webkit-user-drag: none;
  margin: 0;
  padding: 0;

  border-radius: 8px;

  overflow-clip-margin: content-box;
  overflow: clip;

  border-radius: 5px;


  border-radius: 0px;
    bottom: -9999px;
    left: -9999px;
    margin: auto;
    position: absolute;
    right: -9999px;
    top: -9999px;
    transition-duration: .15s;
    transition-property: opacity;
    height: 100%;
    object-fit: cover;
    padding: 0px;
    width: unset;
}
/* 
.product-header__wrapper {
  background-color: #000!important;
  position: relative;
  margin: auto;
  max-width: 1280px;
  height: calc(1680px * 9 / 16);
  --transition: color 250ms ease-in-out;
  z-index: 1;
} */

.product-header__wrapper {
  -webkit-text-size-adjust: 100%;
  --rem: 16;
  visibility: inherit;
  --bodyGutter: 25px;
  color-scheme: light dark;
  font-style: normal;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23077;
  letter-spacing: 0;
  font-family: "-apple-system", BlinkMacSystemFont, "-webkit-system-font",
    "Malgun Gothic", "Segoe UI", "Helvetica Neue", Helvetica, sans-serif;
  font-feature-settings: "kern";
  --footer-height-desktop: 89px;
  --footer-height-mobile: 148px;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-drag: none;
  padding: 0;
  color: var(--systemPrimary-onDark);
  /* background-color: #000!important; */
  position: relative;
  margin: auto;
  max-width: 1280px;
  --transition: color 250ms ease-in-out;
  z-index: 1;
  transition: var(--transition);
  width: 100%;
  /* height: calc(100vh / 2); */
  aspect-ratio: 0.66 / 1;
  max-height: 80vh;

  border-radius: 10px;

  /* max-height: calc(100vh / 2); */
  /* height: calc(111vw /.85); production */
  /* max-height: 80vh; apple production put back*/
}

.product-header__blur {
  font-style: normal;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.23077;
  letter-spacing: 0;
  font-family: "-apple-system", BlinkMacSystemFont, "-webkit-system-font",
    "Malgun Gothic", "Segoe UI", "Helvetica Neue", Helvetica, sans-serif;
  font-feature-settings: "kern";
  --footer-height-desktop: 89px;
  --footer-height-mobile: 148px;
  color: var(--systemPrimary-onDark);
  --transition: color 250ms ease-in-out;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-drag: none;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(
    to bottom,
    transparent,
    rgba(0, 0, 0, 0.068) 3.3%,
    rgba(0, 0, 0, 0.145) 5.9%,
    rgba(0, 0, 0, 0.227) 8.1%,
    rgba(0, 0, 0, 0.313) 10.1%,
    rgba(0, 0, 0, 0.401) 12.1%,
    rgba(0, 0, 0, 0.49) 14.6%,
    rgba(0, 0, 0, 0.578) 17.7%,
    rgba(0, 0, 0, 0.661) 21.8%,
    rgba(0, 0, 0, 0.74) 27.1%,
    rgba(0, 0, 0, 0.812) 33.9%,
    rgba(0, 0, 0, 0.875) 42.4%,
    rgba(0, 0, 0, 0.927) 53%,
    rgba(0, 0, 0, 0.966) 66%,
    rgba(0, 0, 0, 0.991) 81.5%,
    rgba(0, 0, 0, 0.991) 100%
  );
  background-color: #1b1b1b;
  /* background-color: rgba(40,40,40,1); */
  /* background-color: rgba(40,40,40,.5); */
  /* backdrop-filter: saturate(180%) blur(60px); */
  z-index: -1;
}

.typ-headline-title {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.23;
}

.fullscreen-title {
  padding-top: 15px;
  padding-bottom: 10px;
  /* top: 0px;
  z-index: 1002;
  position: absolute; */
  /* background-color: rgba(0, 0, 0, 1) */
}

.product-header__content__details__metadata--info--xs-view span::after {
  content: " · ";
}

.product-header__content__details__metadata--info--xs-view
  span:last-child::after {
  content: "";
}

.movie-dot::after {
  content: " · ";
}

.movie-dot:last-child::after {
  content: "";
}

.product-footer {
  width: 100%;
  padding: 32px 0;
  background-color: #1b1b1b;
  border-radius: 6px;
  padding-bottom: 0px;
  background-color: var(--appPrimaryBackgroundColor);
}

.product-footer__container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
}

.product-footer__title {
  /* padding-top: 13px; */
  padding-bottom: 13px;
}

.product-footer__info {
  /* border-bottom: 0.5px solid #494949; */
  /* padding-bottom: 32px; */
}

.product-footer__info,
.product-footer__metadata {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 739px) {
  .product-footer__info,
  .product-footer__metadata {
    grid-template-columns: 1fr;
  }
}

.product-footer__metadata__section__title {
  padding-top: 13px;
  padding-bottom: 13px;
}

.review-card {
  background-color: #363636;
  background-color: var(--cardBackgroundColor);
  background-color: var(--appSecondaryBackgroundColor);
  padding: 14px 16px;
  border-radius: 12px;
  overflow: hidden;
}

.review-card__title {
  padding-bottom: 5px;
  margin: 0;
}

.review-card__title span {
  display: block;
  /* text-align: center; */
}

.review-card__title--sub {
  margin-top: 5px;
}

.typ-footnote {
  font-weight: 400;
  max-width: 1280px;
}

.typ-footnote {
  font-size: 0.9rem;
  line-height: 1.19;
  max-width: 1280px;
}

.review-card__title--sub--list {
  list-style: none;
}

.line-clamp-wrapper {
  display: inline-block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: calc(10px * -1);
  margin-bottom: calc(10px * -1);
  margin-right: calc(10px * -1);
  margin-left: calc(10px * -1);
  padding: 10px;
}

.line-clamp--with-more {
  --line-height: 22;
  font-size: 15px;
  --link-length: 4;
  --one-ch: 8;
  --fade-direction: 270deg;
  word-break: break-word;
  position: relative;
  -webkit-mask: linear-gradient(
      0deg,
      transparent 0,
      transparent calc(var(--line-height) * 1px),
      #000 calc(var(--line-height) * 1px)
    ),
    linear-gradient(
      var(--fade-direction),
      transparent 0,
      transparent calc((var(--link-length) * var(--one-ch)) * 1px),
      #000
        calc(
          ((var(--link-length) * var(--one-ch)) + (var(--line-height) * 2)) *
            1px
        )
    );
  mask: linear-gradient(
      0deg,
      transparent 0,
      transparent calc(var(--line-height) * 1px),
      #000 calc(var(--line-height) * 1px)
    ),
    linear-gradient(
      var(--fade-direction),
      transparent 0,
      transparent calc((var(--link-length) * var(--one-ch)) * 1px),
      #000
        calc(
          ((var(--link-length) * var(--one-ch)) + (var(--line-height) * 2)) *
            1px
        )
    );
  -webkit-mask-size: initial, initial;
  mask-size: initial, initial;
  -webkit-mask-position: right bottom;
  mask-position: right bottom;
  z-index: 1;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4; /*change to 4 for production*/
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.punchout__button,
.typ-subhead {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.47;
}

.line-clamp__more,
.lt-line-clamp__more {
  font-size: 11px;
  color: #0a84ff;
  background: 0 0;
  border: 0;
  position: absolute;
  text-decoration: none;
  text-transform: uppercase;
  bottom: 11px;
  cursor: pointer;
  z-index: 1;
  right: 10px;
}

.typography-footnote-emphasized {
  font-size: 10px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: 0;
}

.review-card__title .badge--common-sense,
.review-card__title .badge--rotten-tomatoes {
  background-position-x: left;
  display: inline-block;
}

.review-card__title .badge--common-sense {
  background-size: 23px 20px;
}

.review-card__title .badge {
  height: 22px;
  padding: 0 25px;
  opacity: 1;
}

.badge--common-sense {
  background-image: url(https://tv.apple.com/assets/badges/common-sense-onDark-small@2x-f3312938f6b7188fbf37701e3557e399.png);
  background-size: 15px 13px;
  height: 13px;
}

.badge--common-sense,
.badge--rotten-tomatoes {
  opacity: 1;
  background-position-x: left;
  padding-left: 18px;
  display: inline;
}

.badge {
  height: 12px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  opacity: 0.6;
  margin-right: 5px;
}

.product-footer__info,
.product-footer__metadata {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 739px) {
  .product-footer__info,
  .product-footer__metadata {
    grid-template-columns: 1fr;
  }
}

.typ-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.34;
}

.product-footer__metadata__section__desc {
  margin-bottom: 20px;
  display: inline-block;
}

.input-data-button {
  position: relative;
  display: block;
}

.clr-secondary-text,
.watch-card-lockup__content__subtitle {
  color: #ffffffa3;
  /* font-size: 12px; */
}

.product-footer__metadata__section,
button.clr-link {
  background: 0 0;
  border: none;
  font-size: 13px;
}

.clr-link {
  color: var(--keyColor);
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.global-footer {
  /* max-width: 1280px; */
  margin: 0 auto;
  padding: 20px 25px 25px 25px;
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 12px;
  background-color: #101010;
  max-width: 1280px;
  width: 100%;

  background: #101010;
  background-color: #10101000;
  box-shadow: 0px 0px 0px 0px #dfe3ec00;
  /* border-top: 0.5px solid #494949; */
  min-height: auto;
  text-align: left;
}

@media only screen and (max-width: 739px) and (hover: none) {
  .global-footer {
    padding-bottom: 75px;
    font-size: 12px;
    max-width: 1280px;
  }
}

@media only screen and (max-width: 739px) {
  .global-footer {
    display: block;
    font-size: 12px;
    max-width: 1280px;
  }
}

.typ-footnote {
  font-weight: 400;
  max-width: 1280px;
}

.typ-footnote,
.typ-footnote-emph {
  font-size: 1.1rem;
  line-height: 1.19;
  max-width: 1280px;
}

@media only screen and (max-width: 999px) {
  .global-footer__right {
    margin-bottom: 10px;
    font-size: 12px;
  }
}

.global-footer__right {
  order: 2;
  margin-left: auto;
  font-size: 12px;
}

.button-container.svelte-93h96z.svelte-93h96z {
  --linkColor: #c93636;
  display: flex;
  margin-bottom: 25px;
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

.global-footer__links {
  margin-top: 4px;
}

.global-footer__links__link {
  -webkit-border-end: 1px solid #494949;
  -moz-border-end: 1px solid #494949;
  border-inline-end: 1px solid #494949;
  display: inline-block;
  margin-top: 5px;
  white-space: nowrap;
  margin-right: 7px;
  padding-right: 10px;
  font-size: 12px;
}

.typ-footnote {
  font-size: 11px;
  line-height: 1.19;
  max-width: 1280px;
}

.badge--common-sense {
  font-size: 17px;
}

.fullscreen-movietitle {
  font-size: 30px;
  color: var(--systemPrimary-onDark);
}

.shelf-grid {
  width: 100%;
  padding: 0 40px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 739px) {
  .shelf-grid {
    padding-left: 0;
    padding-right: 0;
  }
}

.shelf-grid {
  /* margin-bottom: 24px; */
  background-color: #000;
}

.shelf-grid__header {
  padding: 13px 25px;
}

@media only screen and (min-width: 740px) {
  .shelf-grid__header {
    padding-left: 0;
    padding-right: 0;
  }
}

.accordion-button:after,
.person-header::before,
.shelf-grid-nav__arrow::after {
  content: "";
}

.shelf-grid-nav__arrow::after {
  margin: 0 auto;
  position: relative;
  top: 0;
  display: block;
  width: 13px;
  height: 29px;
  opacity: 0.85;
  background-repeat: no-repeat;
  background-image: url(https://tv.apple.com/assets/shelf/paddle-dark-3bc9eef848158860d017803b15a9911c.svg);
  z-index: 1;
}

  .shelf-grid-nav__arrow::after {
    background-image: url(https://tv.apple.com/assets/shelf/paddle-dark-3bc9eef848158860d017803b15a9911c.svg);
  }


.shelf-grid-nav__arrow--next::after {
  transform: scaleX(-1);
}

.shelf-grid .shelf-grid__body {
  margin-top: calc(15px * -1);
  margin-bottom: calc(15px * -1);
  margin-right: calc(15px * -1);
  margin-left: calc(15px * -1);
  padding: 15px;
}

@media only screen and (max-width: 739px) {
  .shelf-grid .shelf-grid__body {
    margin-right: unset;
    padding-right: unset;
  }
}

.shelf-grid__body {
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
}

.shelf-grid .shelf-grid__list {
  margin-top: -20px;
  margin-bottom: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.shelf-grid__list {
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior-x: none;
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;

  grid-auto-columns: 94px;
  grid-template-rows: repeat(var(--grid-rows), -webkit-max-content);
  grid-template-rows: repeat(var(--grid-rows), max-content);
  grid-column-gap: 10px;
}

@media only screen and (max-width: 739px) {
  .shelf-grid__list {
    -webkit-scroll-snap-type: none;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 25px;
  }
}

.shelf-grid-nav,
.shelf-grid-nav ul,
.shelf-grid__list {
  margin: 0;
  list-style: none;
}

.epic-stage,
.shelf-grid .shelf-grid__list-item {
  position: relative;
  /* max-width: calc(100vw - 10px); */
}

@media only screen and (max-width: 739px) {
  .shelf-grid__list-item {
    scroll-snap-align: none;
  }
}

.epic-stage,
.shelf-grid .shelf-grid__list-item {
  position: relative;
  background-color: #00000000 !important;
}

.shelf-grid__list-item {
  scroll-snap-align: start;
}

@media only screen and (max-width: 739px) {
  .shelf-grid__list-item {
    scroll-snap-align: none;
  }
}

.canvas-lockup,
.watch-live-lockup {
  position: relative;
}

.media-artwork-v2__container.profile-lockup__image {
  padding-top: 100%;
  height: 100%;

  border-radius: 50%;
  box-shadow: 0 7px 7px -5px rgba(0, 0, 0, 0.3);
  margin-bottom: 8px;
  overflow: hidden;
}

.media-artwork-v2__container,
.media-artwork-v2__image {
  z-index: 1;
}

.media-artwork-v2__container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.media-artwork-v2--image-did-attempt-download .media-artwork-v2__image,
.media-artwork-v2--image-did-error .media-artwork-v2__image {
  opacity: 1;
}

.media-artwork-v2__image {
  border-radius: inherit;
  bottom: -9999px;
  height: 100%;
  left: -9999px;
  margin: auto;
  opacity: 0;
  position: absolute;
  right: -9999px;
  top: -9999px;
  transition-duration: 0.15s;
  transition-property: opacity;
}

.profile-lockup__details {
  text-align: center;
  min-height: 32px;
  width: 100%;
  margin-top: 8px;
}

.profile-lockup__details-movie {
  text-align: left;
  min-height: 32px;
  width: 100%;
  margin-top: 8px;
}

.profile-lockup__details--one-or-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (max-width: 739px) {
  .shelf-grid .shelf-grid__list::after {
    display: block;
    content: "\00a0";
    width: 30px;
  }
}

@media only screen and (max-width: 739px) {
  .shelf-grid__list::after {
    width: 15px;
  }
}

@media only screen and (max-width: 739px) {
  .shelf-grid-nav {
    display: none;
  }
}

.shelf-grid .shelf-grid-nav__arrow {
  margin-top: var(--shelf-arrow-offset);
}

.shelf-grid-nav__arrow--next {
  right: 0;
}

.shelf-grid-nav__arrow--next,
.shelf-grid-nav__arrow--previous {
  top: 0;
}

.shelf-grid-nav__arrow {
  width: 40px;
  height: 100%;
  background: 0 0;
  display: block;
  overflow: hidden;
  position: absolute;
  text-align: center;
  transition: 250ms background-color linear, 250ms color linear,
    250ms opacity linear;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.shelf-grid-nav__arrow.disabled,
.shelf-grid-nav__arrow:disabled {
  cursor: default;
  opacity: 0;
}

.shelf-grid-nav__arrow--previous {
  left: 0;
}

.product-main {
  max-width: 1280px;
  margin: auto;
  /* background-color: #a82b2b; */
  /* background-color: var(--appPrimaryBackgroundColor); */
}

.shelf-header--with-see-all {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.canvas-lockup,
.watch-live-lockup {
  position: relative;
}

.media-artwork-v2__container {
  z-index: 1;
  padding-top: 56.25%;
  height: 100%;
}

.canvas-lockup__artwork,
.library-container,
.library-lockup,
.canvas-lockup__artwork,
.watch-live-lockup__artwork {
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  margin-bottom: 8px;
  overflow: hidden;
}

.media-artwork-v2__container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.media-artwork-v2__image {
  border-radius: inherit;
  bottom: -9999px;
  height: 100%;
  left: -9999px;
  margin: auto;
  opacity: 0;
  position: absolute;
  right: -9999px;
  top: -9999px;
  transition-duration: 0.15s;
  transition-property: opacity;
}

.canvas-lockup--profile-vertical {
  width: 100%;
  height: 140px;
  border-radius: 6px;
}

.profile-lockup__image-vertical {
  /* padding-top: 100%;
  height: 100%; */

  border-radius: 6px;
  /* box-shadow: 0 7px 7px -5px rgba(0,0,0,.3);
  margin-bottom: 8px;
  overflow: hidden; */
}

.fullscreen-movietitle-div {
  margin-bottom: 10px;
  /* margin-top: 80px; */
  margin-top: 0px;
}

.product-header__content__buttons {
  position: relative;
  grid-column: 1;
  z-index: 1;
}

.product-header__content__buttons {
  margin-bottom: 24px;
  margin-top: 10px;
}

.review-card__title,
.typ-headline-emph {
  /* text-align: center; */
  z-index: 0;
}

.video-data-services-buttons__list {
  width: 100%;
}

.product-header__content__buttons > :last-of-type {
  margin-bottom: 0;
}

.video-data-services-buttons__list-item {
  text-align: center;
  position: relative;
}

.video-data-services-buttons__list-item:not(:last-of-type) {
  margin-bottom: 12px;
}

@media only screen and (max-width: 739px) {
  .video-data-services-buttons__list-item:not(:last-of-type) {
    margin-bottom: 5px;
  }
}

.typography-title-3-emphasized {
  font-size: 15px;
  line-height: 1.33338;
  font-weight: 600;
  letter-spacing: 0;
}

.video-data-services-button,
.video-data-services-buttons__disclaimer--dark {
  color: var(--systemPrimary-onLight);
}

.video-data-services-button {
  width: 100%;
  max-width: 300px;
  border-radius: 6px;
  border: none;
  margin: auto;
  /* padding: 0 16px; */
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ctaButtonBackgroundColor);
  transition-property: color, background;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

@media only screen and (min-width: 740px) {
  .product-header__content__container {
    /* grid-template-columns: repeat(3,1fr); */
    padding-top: 24px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 739px) {
  .video-data-services-button {
    height: 36px;
    width: 220px;
    margin: auto;
  }
}

.video-data-services-button__glyph {
  background-color: currentColor;
  display: inline-block;
  min-width: 12px;
  height: 12px;
  -webkit-mask-image: url(../assets/button_play@2x-72033fc20262613abda5455109f35915.png);
  mask-image: url(../assets/button_play@2x-72033fc20262613abda5455109f35915.png);

  -webkit-mask-size: 12px 12px;
  mask-size: 12px 12px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  margin-right: 6px;
}

.video-data-services-button__glyph--remove {
  -webkit-mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png);
  mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png);
}

.video-data-services-button__glyph--add {
  -webkit-mask-image: url(../assets/button_add@2x-453d8aecad37017d736a90ae1c2453f3.png);
  mask-image: url(../assets/button_add@2x-453d8aecad37017d736a90ae1c2453f3.png);
}

.cc-modal__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 5;
  pointer-events: none;

  /* max-width: 700px; */
  margin-left: auto;
  margin-right: auto;
}

.is-ltr,
.cc-modal__header,
.is-rtl .cc-modal__header {
  left: 0;
}

.is-ltr,
.cc-modal__header {
  right: 0;
}

.cc-modal__close-button {
  pointer-events: auto;
}

.cc-modal__close-button {
  /* margin: 16px 0 0 16px; */
  margin: 16px 16px 0 0;
}

.cc-modal__close-button {
  /* padding: 8px 0 0 8px; */
  padding: 8px 8px 0 0;
}

.theme-dark,
.cc-modal__close-button-circle {
  background: #333336;
}

.cc-modal__close-button-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: background 0.1s linear;
  background: #e8e8ed;
  background: white;
}

.theme-dark,
.cc-modal__close-button-circle {
  background: #333336;
  background: var(--keyColor);
}

.cc-modal__close-button-icon {
  display: inline-block;
  transition: background 0.1s linear;
  background-color: rgba(0, 0, 0, 0.56);
}

.cc-modal__close-button-icon,
.theme-dark,
.cc-modal__close-button-icon {
  height: 14px;
  width: 14px;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url(../assets/xmark.circle.fill.svg);
  mask-image: url(../assets/xmark.circle.fill.svg);
}

.theme-dark,
.cc-modal__close-button-icon {
  background-color: #969696;
  background-color: white;
}

.cc-modal__apple-icon {
  /* margin: 28px 28px 0; */
  /* margin: 10px 0 0 10px; */
  background-color: #fff;
}

.cc-modal__apple-icon,
.theme-dark .cc-modal__apple-icon {
  /* aspect-ratio: 322/104; */
  height: 0px;
  width: 100px;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  /* -webkit-mask-image: url(../assets/apple.6223699a.svg);
  mask-image: url(../assets/apple.6223699a.svg); */
  -webkit-mask-image: url(../assets/COL.svg);
  mask-image: url(../assets/COL.svg);

  padding-bottom: 0px;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 10px;

  display: block;

  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media screen and (min-width: 740px) {
  .cc-modal__apple-icon {
    background-color: #fff;
  }
}

.cc-modal__apple-icon-nav-big {
  /* margin: 28px 28px 0; */
  background-color: #cccccc00;
}

.cc-modal__apple-icon-nav-big,
.theme-dark .cc-modal__apple-icon-nav-big {
  aspect-ratio: 322/104;
  height: 30px;
  display: block;
  /* width: 25px; */
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  /* -webkit-mask-image: url(../assets/apple.6223699a.svg);
  mask-image: url(../assets/apple.6223699a.svg); */
  -webkit-mask-image: url(../assets/e2e.svg);
  mask-image: url(../assets/e2e.svg);
}

.theme-dark .cc-modal__apple-icon-nav-big {
  background-color: #969696;
  display: block;
}

@media screen and (min-width: 740px) {
  .cc-modal__apple-icon-nav-big {
    background-color: #fff;
  }
}

.cc-modal__apple-icon-nav-small {
  /* margin: 28px 28px 0; */
  background-color: #fff;
}

.cc-modal__apple-icon-nav-small,
.theme-dark .cc-modal__apple-icon-nav-small {
  aspect-ratio: 322/104;
  height: 20px;
  display: block;
  /* width: 25px; */
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  /* -webkit-mask-image: url(../assets/apple.6223699a.svg);
  mask-image: url(../assets/apple.6223699a.svg); */
  -webkit-mask-image: url(../assets/e2e.svg);
  mask-image: url(../assets/e2e.svg);
}

.theme-dark .cc-modal__apple-icon-nav-small {
  background-color: #969696;
  display: block;
}

@media screen and (min-width: 740px) {
  .cc-modal__apple-icon-nav-small {
    background-color: #fff;
  }
}

.cc-modal__apple-icon.bronze {
  height: 30px;
  width: 83px;
  margin-bottom: 5px;
  -webkit-mask-image: url(../assets/CBL.svg);
  mask-image: url(../assets/CBL.svg);
}

.cc-modal__apple-icon.silver {
  height: 30px;
  width: 83px;
  margin-bottom: 5px;
  -webkit-mask-image: url(../assets/CSL.svg);
  mask-image: url(../assets/CSL.svg);
}

.cc-modal__apple-icon.gold {
  height: 30px;
  width: 83px;
  margin-bottom: 5px;
  -webkit-mask-image: url(../assets/CGL.svg);
  mask-image: url(../assets/CGL.svg);
}

.cc-modal__apple-icon.diamond {
  height: 30px;
  width: 83px;
  margin-bottom: 5px;
  -webkit-mask-image: url(../assets/CDL.svg);
  mask-image: url(../assets/CDL.svg);
}

.theme-dark .cc-modal__apple-icon {
  background-color: #969696;
  display: block;
}

.lockup-overlay {
  background-color: var(--overlayBackgroundColor);
  border-radius: 12px;
  inset: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.lockup-overlay--button-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 11px;
}

.canvas-lockup .lockup-overlay__generic_content,
.canvas-lockup .lockup-overlay__playback,
.watch-live-lockup .lockup-overlay__generic_content,
.watch-live-lockup .lockup-overlay__playback {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}

.canvas-lockup .lockup-overlay__generic_content,
.watch-live-lockup .lockup-overlay__generic_content {
  pointer-events: none;
}

.lockup-button {
  position: relative;
  pointer-events: auto;
  width: 27px;
  height: 27px;
  border-radius: 27px;
  background-color: transparent;
  border: none;
  opacity: 1;
  z-index: 1;
}

.lockup-button--grouped {
  /* margin-left: 11px; */
}

.lockup-button::after,
.lockup-button::before {
  width: 27px;
  height: 27px;
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  z-index: 1;
}

.lockup-button::before {
  background-color: var(--systemStandardThinMaterialSover-default_IC);
  border-radius: 100%;
}

@supports ((-webkit-backdrop-filter: initial) or (backdrop-filter: initial)) {
  .lockup-button::before {
    background-color: var(--systemStandardThinMaterialSover);
    /* -webkit-backdrop-filter: saturate(190%) blur(60px);
    backdrop-filter: saturate(190%) blur(60px); */
  }
}

@supports ((-webkit-backdrop-filter: initial) or (backdrop-filter: initial)) {
  
    .lockup-button::before {
      /* -webkit-backdrop-filter: saturate(180%) blur(60px);
      backdrop-filter: saturate(180%) blur(60px); */
    }
  
}

/* .lockup-button::after {
  -webkit-mask-image: url(../assets/eee.svg);
  mask-image: url(../assets/eee.svg);
} */

.lockup-button.bronze::after {
  -webkit-mask-image: url(../assets/CBL.svg);
  mask-image: url(../assets/CBL.svg);
}

.lockup-button.silver::after {
  -webkit-mask-image: url(../assets/CSL.svg);
  mask-image: url(../assets/CSL.svg);
}

.lockup-button.gold::after {
  -webkit-mask-image: url(../assets/CGL.svg);
  mask-image: url(../assets/CGL.svg);
}

.lockup-button.diamond::after {
  -webkit-mask-image: url(../assets/CDL.svg);
  mask-image: url(../assets/CDL.svg);
}

.lockup-button.none::after {
  -webkit-mask-image: url(../assets/COL.svg);
  mask-image: url(../assets/COL.svg);
}

@media only screen and (min-width: 1000px) {
  .cc-modal__close-button {
    margin: 30px 50px 0 0;
  }
}

.lockup-button::after,
.lockup-button::before {
  width: 27px;
  height: 27px;
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  z-index: 1;
}

.lockup-button::after {
  /* background-color: var(--systemPrimary-onDark); */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
}

.movie-action-button {
  background-color: red;
  position: absolute;
  top: 0px;
  margin: 10px;
  padding: 10px;
  border-radius: 6px;
  z-index: 1;
}

.lt-line-clamp {
  overflow: hidden;
  position: relative;
}

.lt-line-clamp--multi-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.typography-callout-emphasized {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: 0;
}

.product-header__caption {
  padding-bottom: 5px;
  /* text-align: center; */
  font-weight: 600;
  opacity: 1;
  color: #fff;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.8);
}

.product-header__image-logo {
  position: absolute;
  margin: 25px 0 0 35px;
  left: 0;
  z-index: 3;
}

@media only screen and (max-width: 739px) {
  .product-header__image-logo {
    display: none;
  }
}

@media only screen and (min-width: 740px) {
  .product-header__image-logo {
    max-width: calc((1 * ((100vw - 80px - 2 * 20px) / 3 + 20px)) - 20px);
  }
}

.product-header__image-logo__blur {
  position: absolute;
  top: 0;
  width: 60%;
  height: calc(60% * 9 / 16);
  opacity: 0.7;
  background-image: radial-gradient(
    at top left,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0) 72%
  );
  z-index: 1;
  border-radius: 5px;
}

/* .product-header__brand-logo__blur {
  display: none;
  border-radius: 8px;
} */

.product-header__brand-logo__blur {
  display: block;
  height: 354px;
  width: 364px;
  position: absolute;
  z-index: 2;
  right: 0;
  border-radius: 5px;
}

.dark-content,
.product-header__brand-logo__blur {
  background: radial-gradient(
    at bottom left,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.35) 100%
  );
}

.product-header__content__details__metadata {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 14px;
  margin-bottom: 12px;
  z-index: 1;
}

@media only screen and (min-width: 740px) {
  .product-header__content__details__metadata {
    /* position: absolute;
    bottom: 1px;
    margin-bottom: unset; */
  }
}

.product-header__content__details__metadata--info {
  margin-right: 24px;
}

@media only screen and (max-width: 999px) {
  .product-header__content__details__metadata--info {
    margin-right: 10px;
  }
}

.product-header__content__details {
  position: relative;
  width: 100%;
  z-index: 1;
}

/* .product-header__content__details, .line-clamp-wrapper {
  margin-bottom: 4px;
} */

@media only screen and (min-width: 740px) {
  .product-header__content__details,
  .line-clamp-wrapper {
    /* margin-bottom: 16px; */
  }
}

@media only screen and (min-width: 740px) {
  .product-header__content__details {
    /* position: absolute; */
    width: calc(100% - 70px);
    /* top: 40px; */
  }
}

.show-long-moviedata {
  display: none;
}

@media only screen and (min-width: 740px) {
  .show-long-moviedata {
    display: inline;
  }
}

@media only screen and (max-width: 739px) {
  .product-header__content__crews {
    /* display: none; */
  }
  /* .product-header__content__details__metadata-extra {
    display: none;
  } */
}

.typography-title-3 {
  font-size: 15px;
  line-height: 1.33338;
  font-weight: 400;
  letter-spacing: 0;
}

.product-header__content__crews {
  position: relative;
  margin-bottom: 10px;
  text-align: center;
}

.product-header__content__crews__list {
  display: inline;
}

@media only screen and (max-width: 739px) {
  .product-header__brand-logo__blur {
    border-radius: 0px;
  }
  .product-header__image-logo__blur {
    border-radius: 0px;
  }
}

.product-footer__metadata__section {
  /* Ensures the section takes full width of its parent */
  width: 100%;
}

.product-footer__metadata__columns {
  display: flex;
  justify-content: space-between; /* Spreads the two dl elements evenly */
  width: 100%; /* Ensures the container takes full width of its parent */
}

.product-footer__metadata__list {
  width: 50%; /* Each list takes up half the width of the container */
  /* Optional: Add padding or margin if needed for spacing */
}

.divider-xs-only {
  display: block;
  margin: 0 auto;
  width: calc(100% - 0px);
  border-top: 0.3px solid #494949;
}

.movie-detail-popup-inner {
  position: relative; /* Use fixed or absolute positioning */
  /* background-color: #000044; */
  z-index: 1001;
  width: calc(100% - 0px);
  /* max-width: calc(100% - 20px);
  max-height: calc(100% - 20px); */
  /* max-height: calc(50vh + 454px); */
  /* aspect-ratio: 996 / 753; */
  /* height: calc(100% - 20px); */
  border-radius: 0px;
  /* border: 2px solid #49494952; */
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  /* display: flex; */
  /* Use flexbox to center content inside the popup */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* padding: 20px;  */
  /* Adjust padding as needed */
  background-color: var(--appPrimaryBackgroundColor);
}

.movie-detail-content {
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr;  */
  /* margin-top: -18px; */
  border-radius: 10px;
  padding-bottom: 50px;
}

.movie-detail-content.bronze {
  margin-top: -18px;
  border-radius: 10px;
  /* border: 5px solid rgba(169, 102, 14, 0.6); */
  /* box-shadow: 0px 0px 7px 4px rgba(169, 102, 14, 0.6); */
  background: rgba(169, 102, 14, 0.6);
  background-color: var(--systemBronze);
}

.movie-detail-content.silver {
  margin-top: -18px;
  border-radius: 10px;
  /* border: 5px solid rgba(138, 138, 138, 0.792); */
  box-shadow: 0px 0px 7px 4px var(--systemSilver);
  background-color: #7d7d7a;
}

.movie-detail-content.gold {
  margin-top: -18px;
  border-radius: 10px;
  /* border: 5px solid rgba(253, 208, 45, 0.6); */
  box-shadow: 0px 0px 7px 4px var(--systemGold);
  background-color: var(--systemGold);
}

.movie-detail-content.diamond {
  margin-top: -18px;
  border-radius: 10px;
  /* border: 5px solid rgba(150, 147, 239, 0.6); */
  box-shadow: 0px 0px 20px 10px var(--systemDiamond);
  background-color: var(--systemDiamond);
}

@media only screen and (min-width: 740px) {
  .movie-detail-content {
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr;  */
    gap: 2px; /* Adjust the gap between columns as needed */
  }
  .product-header__blur {
    /* display: none; */
  }
  .product-header__wrapper {
    aspect-ratio: 0.66 / 1;
    height: auto;
    margin-bottom: 0px;
    max-height: 80vh;
    min-height: 500px;

    max-width: 1280px;
    max-height: 720px;
  }
  .product-main {
    /* aspect-ratio: .66 / 1; */
    width: 100%;
  }
  .product-footer {
    /* aspect-ratio: .66 / 1; */
  }
  .fullscreen {
    max-width: none;
    --transition: color 250ms ease-in-out;
  }
  .divider-xs-only {
    display: none;
  }
  .movie-detail-popup-inner {
    /* position: fixed;  */
    /* background-color: #000044; */
    z-index: 1001;
    width: calc(100% - 0px);
    /* max-width: calc(100% - 20px);
    max-height: calc(100% - 20px); */

    /* max-height: calc(100% - 70px); */
    /* aspect-ratio: 996 / 753; */
    /* height: calc(100% - 20px); */
    /* height: calc(100% - 80px); */
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    /* display: flex; */
    /* Use flexbox to center content inside the popup */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    /* padding: 20px;  */
    /* Adjust padding as needed */
  }
  @media only screen and (max-height: 560px) {
    .movie-detail-popup-inner {
      overflow-y: auto;
      width: calc(100% - 0px);
      aspect-ratio: auto;
    }
  }
}

@media only screen and (min-width: 800px) {
  .movie-detail-popup-inner {
    /* width: calc(100% - 10vw); */
  }
}

@media only screen and (min-width: 1110px) {
  .movie-detail-popup-inner {
    /* max-width: 1000px; */
  }
}

@media only screen and (max-width: 740px) {
  @media only screen and (max-height: 900px) {
    .movie-detail-popup-inner {
      /* overflow-y: auto; */
      position: relative;
      top: 0px;
      width: calc(100% - 0px);
      /* max-width: calc(100% - 0px); */
      height: calc(100% - 0px);
      border-radius: 0px 0px 0px 0px;
    }
  }
}

@media only screen and (max-width: 1679px) {
  .product-header__wrapper {
    width: 100%;
    max-height: 80vh;

    /* height: 56.25vw ; production */
  }
}

@media only screen and (max-width: 740px) {
  .product-footer,
  .product-main,
  .movie-detail-content {
    /* height: 100%; */
  }
  .movie-detail-popup-inner {
    overflow-y: auto;
    position: relative;
    /* top: 10px; */
    width: calc(100% - 0px);
    max-width: calc(100% - 0px);
    max-width: 1280px;
    height: calc(100% - 0px);
    border-radius: 0px 0px 0px 0px;
  }
}

@media only screen and (max-width: 739px) {
  .product-header__wrapper {
    width: 100%;
    aspect-ratio: 1 / 0.66;
    /* max-height: calc(100vh / 2); */
    /* height: calc(111vw / .85) ;
    max-height: 80vh; production */
    height: calc(111vw / 0.65);
    max-height: 80vh;
  }
  .product-header__image-bg {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 0 25%;
    object-position: 0 25%;
    border-radius: 0px;
    object-position: center;
  }
}

.banner-title-sub {
  margin-top: 0px;
  -webkit-line-clamp: 2;
  padding-bottom: 0px;
  max-width: 80%;
  margin: auto;
  text-align: center;
  font-weight: 600;
  opacity: 1;
  color: #fff;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.8);
  font-size: 11px;
  line-height: 1.27273;
  font-weight: 600;
  letter-spacing: 0;
  color: red;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  margin-top: 8px;
  margin-bottom: 10px;
}

.banner-title-sub-text {
  margin-top: 0px;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 0px;
}

.global-footer__links__link:last-child {
  border-inline-end: 0px solid #494949;
}

.search-landing__header {
  max-width: 1280px;
  /* margin-top: 25px;
  padding-left: 25px;
  padding-right: 25px; */
  margin-top: 0px;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
}

.typography-large-title-emphasized {
  font-size: 26px;
  font-weight: 700;

  display: block;
  font-size: 1.5em;
  font-size: 26px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.search-header__search-input:focus {
  /* border: 2px #0071e3 solid; */
  outline: 0;
}

.search-header__search-input-wrapper > ::-webkit-search-cancel-button {
  display: none;
}

.search-header__button:focus {
  outline: none;
}

:focus {
  outline-offset: 0px;
}

.typography-body,
.typography-headline-emphasized,
.typography-large-title,
.typography-large-title-emphasized {
  line-height: 1.23077;
  letter-spacing: 0;
}

.search-landing__header .search-header__search {
  position: relative;
  display: block;
  margin-top: 12px;
  z-index: 2;
}

.search-header__search {
  margin-top: 24px;
  width: 100%;
  display: none;
  position: relative;
  z-index: 1;
}

.search-header__form-input-label {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0 0 99.9% 99.9%);
  clip-path: inset(0 0 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

.search-header__search-input-wrapper {
  order: 2;
}

.search-header__search-icon {
  margin-top: -1px;
  height: 15px;
  width: 15px;
  order: 1;
  position: absolute;
  top: 12px;
  fill: var(--systemSecondary);
  pointer-events: none;
  left: 8px;
  margin-right: 14px;
  z-index: 1;
}

.search-header__search-input {
  height: 38px;
  width: 100%;
  border-radius: 9px;
  border: 1px solid var(--searchInputBorderColor);
  box-sizing: border-box;
  padding-bottom: 1px;
  background-color: transparent;
  color: var(--systemSecondary);
  font-size: 17px;
  line-height: 1.29412;
  font-weight: 400;
  letter-spacing: 0;
  padding-left: 26px;
  padding-right: 25px;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.logout-footer-button {
  align-items: center;
  background-color: #343639;
  background-color: var(--systemBlue);
  border: 1px solid #494949;
  border-radius: 8px;
  color: var(--systemPrimary);
  height: 24px;
  cursor: pointer;
  display: flex;
  padding: 6px 33px 6px 10px;
  position: relative;
  border-radius: 50px;
  background: 0 0;
  border: none;

  background-color: var(--systemBlue);
  color: white;
  border: none;
  font-size: 16px;

  background-color: #b7002b24;
  border: 1px #b7000047 solid;
  padding-right: 12px;

  margin-top: 20px;
}

.search-header__button--close.has-search-text,
.search-header__search-page .search-header__button--close {
  display: block;
}

.search-header__button--close {
  display: none;
  position: absolute;
  height: 14px;
  width: 14px;
  top: 12px;
  right: 11px;
  z-index: 1;
}

.search-header__button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  fill: var(--systemSecondary);
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

@media only screen and (max-width: 739px) {
  .product-header__content__container {
    padding: 24px 25px 12px;
    display: block;
    position: relative;
  }
}

@media only screen and (min-width: 740px) {
  .product-header__content__container {
    grid-template-columns: repeat(3, 1fr);
    padding-top: 24px;
    padding-bottom: 40px;
  }

  .product-header__content__details {
    grid-column: 2 / 3;
  }
  .product-header__content__buttons {
    grid-column: 3 / 3;
    margin-bottom: 0px;
  }
  .video-data-services-buttons__list {
    position: absolute;
    bottom: 0px;
  }
  .review-card__title,
  .typ-headline-emph {
    text-align: left;
    grid-column: 1 / 3;
    padding-bottom: 0px;
  }
  .review-card__title {
    /* padding-left: 20px; */
    position: relative;
    z-index: 0;
  }

  .banner-title-sub {
    margin-left: 0px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 739px) {
  .product-header__content__buttons {
    margin-bottom: 24px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1679px) {
  .product-header__wrapper {
    width: 100%;
    height: 56.25vw;
  }
}

@media only screen and (max-width: 739px) {
  .product-header__wrapper {
    width: 100%;
    padding-top: 56.25%;
    /* height: calc(111vw / .65); */
    /* max-height: 80vh; be */
  }
}
