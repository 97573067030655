.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.table-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 5rem;
}

.table-container h2 {
  text-align: center;
  margin-bottom: 3rem;
  border-radius: 1rem;
  padding: 1rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

th, td {
  padding: 8px 8px;
  text-align: left;
}

td {
  color: rgb(210, 210, 210);
}

th button {
  background-color: transparent;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

button {
  padding: 0px;
}

th button:hover {
  color: white;
}

.movie-table {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  border-collapse: collapse;
  border-radius: 0.5rem;  
  border-spacing: 0;
  height: 100%;
  table-layout: fixed;
  box-sizing: border-box;
  padding: 0px;
  /* background-color: rgb(20, 23, 28); */
}

input[type="checkbox"] {
  cursor: pointer;
}

.bold-text {
  font-weight: bold;
  /* font-size: large; */
}

/* Adjusting the widths of specific columns */
th:nth-child(1), td:nth-child(1) { /* Score column */
  width: 3%; /* Adjust the percentage as needed */
  padding-left: 0.75rem;
  padding: 0px 00px 0px 10px;
  text-align: center;
}

th:nth-child(2), td:nth-child(2) { /* Title column */
  width: 4%; /* 4 Adjust the percentage as needed */
  padding: 0px 10px 0px 10px;
  text-align: center;
}

th:nth-child(3), td:nth-child(3) { /* Year column */
  width: 11%; /* 11 Adjust the percentage as needed */
  color: white;
  font-weight: bold;
}

th:nth-child(4), td:nth-child(4) { /* Streaming column */
  width: 4%; /* 4 Adjust the percentage as needed */
}

th:nth-child(5), td:nth-child(5) { /* Seen column */
  width: 6%; /* 7 Adjust the percentage as needed */
}

td:nth-child(2) {
  /* color: #aafbb8; */
  font-weight: bold;
  text-align: center;
}

.container{
  padding: 0px;
}