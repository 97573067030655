html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

html {
  font-size: 62.5%;
}

:host, :root {
  --rem: 16;
}

[hydrated] {
  visibility: inherit;
}

@media only screen and (min-width: 740px) {
  :root {
    --bodyGutter: 40px;
  }
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

a:hover {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

:root {
    --bodyGutter: 25px;
    color-scheme: light dark;
    --systemRed: #ff3b30;
    --systemRed-vibrant: #f53126;
    --systemRed-default_IC: #d70015;
    --systemRed-vibrant_IC: #c20618;
    --systemOrange: #ff9500;
    --systemOrange-vibrant: #f58b00;
    --systemOrange-default_IC: #c93400;
    --systemOrange-vibrant_IC: #ad3a00;
    --systemYellow: #ffcc00;
    --systemYellow-vibrant: #f5c200;
    --systemYellow-default_IC: #a05a00;
    --systemYellow-vibrant_IC: #925100;
    --systemGreen: #28cd41;
    --systemGreen-vibrant: #1ec337;
    --systemGreen-default_IC: #007d1b;
    --systemGreen-vibrant_IC: #007018;
    --systemMint: #00c7be;
    --systemMint-vibrant: #00bdb4;
    --systemMint-default_IC: #0c817b;
    --systemMint-vibrant_IC: #0b7570;
    --systemTeal: #59adc4;
    --systemTeal-vibrant: #2ea7bd;
    --systemTeal-default_IC: #008299;
    --systemTeal-vibrant_IC: #00778c;
    --systemCyan: #55bef0;
    --systemCyan-vibrant: #41afdc;
    --systemCyan-default_IC: #0071a4;
    --systemCyan-vibrant_IC: #006796;
    --systemBlue: #007aff;
    --systemBlue-vibrant: #0070f5;
    --systemBlue-default_IC: #0040dd;
    --systemBlue-vibrant_IC: #0040dd;
    --systemBlue-rollover: #0045b7;
    --systemBlue-pressed: #005cd7;
    --systemBlue-deepPressed: #0045b7;
    --systemBlue-disabled: rgba(0, 122, 255, 0.35);
    --systemIndigo: #5856d6;
    --systemIndigo-vibrant: #5452cc;
    --systemIndigo-default_IC: #3634a3;
    --systemIndigo-vibrant_IC: #3634a3;
    --systemPurple: #af52de;
    --systemPurple-vibrant: #9f4bc9;
    --systemPurple-default_IC: #ad44ab;
    --systemPurple-vibrant_IC: #ad44ab;
    --systemPink: #ff2d55;
    --systemPink-vibrant: #f5234b;
    --systemPink-default_IC: #d30f45;
    --systemPink-vibrant_IC: #c11032;
    --systemBrown: #a2845e;
    --systemBrown-vibrant: #987a54;
    --systemBrown-default_IC: #7f6545;
    --systemBrown-vibrant_IC: #775d3b;
    --systemGray: #8e8e93;
    --systemGray-vibrant: #848489;
    --systemGray-default_IC: #69696e;
    --systemGray-vibrant_IC: #616165;
    --systemPrimary: rgba(0, 0, 0, 0.85);
    --systemPrimary-vibrant: #4c4c4c;
    --systemPrimary-onLight: rgba(0, 0, 0, 0.85);
    --systemPrimary-onDark: rgba(255, 255, 255, 0.85);
    --systemPrimary-default_IC: black;
    --systemPrimary-vibrant_IC: black;
    --systemPrimary-onLight_IC: black;
    --systemPrimary-onDark_IC: white;
    --systemSecondary: rgba(0, 0, 0, 0.5);
    --systemSecondary-vibrant: gray;
    --systemSecondary-onLight: rgba(0, 0, 0, 0.5);
    --systemSecondary-onDark: rgba(255, 255, 255, 0.55);
    --systemSecondary-default_IC: rgba(0, 0, 0, 0.76);
    --systemSecondary-vibrant_IC: rgba(0, 0, 0, 0.76);
    --systemSecondary-onLight_IC: rgba(0, 0, 0, 0.76);
    --systemSecondary-onDark_IC: rgba(255, 255, 255, 0.7);
    --systemTertiary: rgba(0, 0, 0, 0.25);
    --systemTertiary-vibrant: #bfbfbf;
    --systemTertiary-onLight: rgba(0, 0, 0, 0.25);
    --systemTertiary-onDark: rgba(255, 255, 255, 0.25);
    --systemTertiary-default_IC: rgba(0, 0, 0, 0.56);
    --systemTertiary-vibrant_IC: rgba(0, 0, 0, 0.56);
    --systemTertiary-onLight_IC: rgba(0, 0, 0, 0.56);
    --systemTertiary-onDark_IC: rgba(255, 255, 255, 0.5);
    --systemQuaternary: rgba(0, 0, 0, 0.1);
    --systemQuaternary-vibrant: #e6e6e6;
    --systemQuaternary-onLight: rgba(0, 0, 0, 0.1);
    --systemQuaternary-onDark: rgba(255, 255, 255, 0.1);
    --systemQuaternary-default_IC: rgba(0, 0, 0, 0.35);
    --systemQuaternary-vibrant_IC: rgba(0, 0, 0, 0.35);
    --systemQuaternary-onLight_IC: rgba(0, 0, 0, 0.35);
    --systemQuaternary-onDark_IC: rgba(255, 255, 255, 0.3);
    --systemQuinary: rgba(0, 0, 0, 0.05);
    --systemQuinary-vibrant: #f2f2f2;
    --systemQuinary-onLight: rgba(0, 0, 0, 0.05);
    --systemQuinary-onDark: rgba(255, 255, 255, 0.05);
    --systemQuinary-default_IC: rgba(0, 0, 0, 0.1);
    --systemQuinary-vibrant_IC: rgba(0, 0, 0, 0.1);
    --systemQuinary-onLight_IC: rgba(0, 0, 0, 0.1);
    --systemQuinary-onDark_IC: rgba(255, 255, 255, 0.1);
    --keyColor: #007aff;
    --keyColor-default_IC: #0040dd;
    --systemStandardUltrathinMaterialSover: rgba(246, 246, 246, 0.36);
    --systemStandardUltrathinMaterialSover-inactive: #f0f0f0;
    --systemStandardUltrathinMaterialSover-default_IC: #d2d2d2;
    --systemStandardUltrathinMaterialSover-inactive_IC: #ececec;
    --systemStandardThinMaterialSover: rgba(246, 246, 246, 0.48);
    --systemStandardThinMaterialSover-inactive: #f0f0f0;
    --systemStandardThinMaterialSover-default_IC: gainsboro;
    --systemStandardThinMaterialSover-inactive_IC: #ececec;
    --systemStandardMediumMaterialSover: rgba(246, 246, 246, 0.6);
    --systemStandardMediumMaterialSover-inactive: #f0f0f0;
    --systemStandardMediumMaterialSover-default_IC: #e6e6e6;
    --systemStandardMediumMaterialSover-inactive_IC: #ececec;
    --systemStandardThickMaterialSover: rgba(246, 246, 246, 0.72);
    --systemStandardThickMaterialSover-inactive: #f0f0f0;
    --systemStandardThickMaterialSover-default_IC: #f0f0f0;
    --systemStandardThickMaterialSover-inactive_IC: #ececec;
    --systemStandardUltrathickMaterialSover: rgba(246, 246, 246, 0.84);
    --systemStandardUltrathickMaterialSover-inactive: #f0f0f0;
    --systemStandardUltrathickMaterialSover-default_IC: #fafafa;
    --systemStandardUltrathickMaterialSover-inactive_IC: #ececec;
    --systemHeaderMaterialSover: rgba(255, 255, 255, 0.8);
    --systemHeaderMaterialSover-default_IC: white;
    --systemToolbarTitlebarMaterialSover: rgba(253, 253, 253, 0.8);
    --systemToolbarTitlebarMaterialSover-inactive: #f0f0f0;
    --systemToolbarTitlebarMaterialSover-default_IC: white;
    --systemToolbarTitlebarMaterialSover-inactive_IC: #ececec;
    --labelDivider: rgba(0, 0, 0, 0.15);
    --vibrantDivider: rgba(60, 60, 67, 0.29);
    --pageBG: white;
    --shelfBG: rgba(0, 0, 0, 0.05);
    --genericJoeColor: #ebebeb;
    --fallbackMaterialBG: rgba(255, 255, 255, 0.97);
    --liveBadgePlatterBG: #ff5066;
    --keyColorBackground: #007aff;
    --keyColorBackground-default_IC: #0040dd;
    --appPrimaryBackgroundColor: white;
    --appSecondaryBackgroundColor: #f0f0f3;
    --alertDropShadowColor: rgba(0, 0, 0, 0.25);
    --alertOuterStrokeColor: rgba(0, 0, 0, 0.2);
    --appModalBackgroundColor: white;
    --artworkBorderColor: rgba(151, 151, 151, 0.2);
    --ctaButtonBackgroundColor: white;
    --cardBackgroundColor: white;
    --defaultButtonBackgroundColor: rgba(84, 84, 84, 0.5);
    --dialogShadowColor: rgba(0, 0, 0, 0.25);
    --disabledLightColor: rbga(255, 255, 255, 0.4);
    --dividerTextColor: rgba(60, 60, 67, 0.29);
    --fauxNativeSheetBackgroundColor: white;
    --labelSecondary: rgba(235, 235, 245, 0.6);
    --liveBadgeBackgroundColor: #ff5066;
    --mobileNavigationBG: white;
    --modalBoxShadowColor: rgba(0, 0, 0, 0.24);
    --modalCloseButtonIconColor: rgba(60, 60, 67, 0.6);
    --modalContentOverlayColorEnd: white;
    --modalContentOverlayColorStart: rgba(255, 255, 255, 0);
    --modalHeaderBackgroundColor: rgba(255, 255, 255, 0.97);
    --modalHeaderShadowColor: rgba(0, 0, 0, 0.2);
    --modalScrimColor: rgba(0, 0, 0, 0.45);
    --navigationBackgroundColor: #eeeeee;
    --navigationBorderColor: #aaaaaa;
    --navigationBorderBottomColor: #aaaaaa;
    --navigationBorderLeftColor: #bbbbbb;
    --navigationBorderRightColor: #bbbbbb;
    --navigationBorderTopColor: #eeeeee;
    --navigationLinkSelectionStateColor: rgba(60, 60, 67, 0.1);
    --navigationMobileMenuBackgroundColor: white;
    --navigationScrollBackgroundColor: rgba(0, 0, 0, 0.4);
    --navigationScrollBackgroundFallbackColor: rgba(0, 0, 0, 0.6);
    --navigationTextColor: #555555;
    --overlayBackgroundColor: rgba(51, 51, 51, 0.3);
    --overlayButtonPlatterColor: rgba(255, 255, 255, 0.2);
    --personHeaderBackgroundColor: rgba(40, 40, 40, 0.9);
    --playerBackground: rgba(255, 255, 255, 0.88);
    --playbackOverlayBackgroundColor: rgba(51, 51, 51, 0.7);
    --progressBarColor: rgba(255, 255, 255, 0.4);
    --progressBarFillColor: white;
    --searchInputBorderColor: rgba(0, 0, 0, 0.15);
    --settingsMenuPointer: rgba(255, 255, 255, 0.5);
    --sidebarActiveItemBackgroundColor: rgba(60, 60, 67, 0.1);
    --sidebarBackgroundColor: rgba(60, 60, 67, 0.03);
    --sidebarSearchInputBorderColor: rgba(0, 0, 0, 0.15);
    --sportLockupClockTimeBadgeBackgroundColor: rgba(0, 0, 0, 0.2);
    --sportLockupScoreBadgeDivider: rgba(0, 0, 0, 0.15);
    --unifiedVolumeThumbShadow: rgba(0, 0, 0, 0.1);
    --unifiedVolumeThumbShadowInset: rgba(0, 0, 0, 0.15);
    --unifiedVolumeTrackFilled: rgba(246, 246, 246, 0.36);
    --unifiedVolumeThumb: #d0d0d0;
    --unifiedPlayerIcons: rgba(0, 0, 0, 0.95);
    --navigationIconColor: #7f7f7f;
    --modalBGColor: rgba(255, 255, 255, 1);
    --labelPrimary: rgba(0, 0, 0, 0.95);
    --shelf-arrow-offset: 38px;
    --skip-button-visibility: hidden;
}

:root {
  --systemDiamond: #b9f2ff90;
  --systemGold: #ffd7394d;
  --systemSilver: #c0c0c0a5;
  --systemBronze: #cd803259;
  --bodyGutter: 25px;
  color-scheme: light dark;
  --systemRed: #ff3b30;
  --systemRed-vibrant: #f53126;
  --systemRed-default_IC: #d70015;
  --systemRed-vibrant_IC: #c20618;
  --systemOrange: #ff9500;
  --systemOrange-vibrant: #f58b00;
  --systemOrange-default_IC: #c93400;
  --systemOrange-vibrant_IC: #ad3a00;
  --systemYellow: #ffcc00;
  --systemYellow-vibrant: #f5c200;
  --systemYellow-default_IC: #a05a00;
  --systemYellow-vibrant_IC: #925100;
  --systemGreen: #28cd41;
  --systemGreen-vibrant: #1ec337;
  --systemGreen-default_IC: #007d1b;
  --systemGreen-vibrant_IC: #007018;
  --systemMint: #00c7be;
  --systemMint-vibrant: #00bdb4;
  --systemMint-default_IC: #0c817b;
  --systemMint-vibrant_IC: #0b7570;
  --systemTeal: #59adc4;
  --systemTeal-vibrant: #2ea7bd;
  --systemTeal-default_IC: #008299;
  --systemTeal-vibrant_IC: #00778c;
  --systemCyan: #55bef0;
  --systemCyan-vibrant: #41afdc;
  --systemCyan-default_IC: #0071a4;
  --systemCyan-vibrant_IC: #006796;
  --systemBlue: #007aff;
  --systemBlue-vibrant: #0070f5;
  --systemBlue-default_IC: #0040dd;
  --systemBlue-vibrant_IC: #0040dd;
  --systemBlue-rollover: #0045b7;
  --systemBlue-pressed: #005cd7;
  --systemBlue-deepPressed: #0045b7;
  --systemBlue-disabled: rgba(0, 122, 255, 0.35);
  --systemIndigo: #5856d6;
  --systemIndigo-vibrant: #5452cc;
  --systemIndigo-default_IC: #3634a3;
  --systemIndigo-vibrant_IC: #3634a3;
  --systemPurple: #af52de;
  --systemPurple-vibrant: #9f4bc9;
  --systemPurple-default_IC: #ad44ab;
  --systemPurple-vibrant_IC: #ad44ab;
  --systemPink: #ff2d55;
  --systemPink-vibrant: #f5234b;
  --systemPink-default_IC: #d30f45;
  --systemPink-vibrant_IC: #c11032;
  --systemBrown: #a2845e;
  --systemBrown-vibrant: #987a54;
  --systemBrown-default_IC: #7f6545;
  --systemBrown-vibrant_IC: #775d3b;
  --systemGray: #8e8e93;
  --systemGray-vibrant: #848489;
  --systemGray-default_IC: #69696e;
  --systemGray-vibrant_IC: #616165;
  --systemPrimary: rgba(0, 0, 0, 0.85);
  --systemPrimary-vibrant: #4c4c4c;
  --systemPrimary-onLight: rgba(0, 0, 0, 0.85);
  --systemPrimary-onDark: rgba(255, 255, 255, 0.85);
  --systemPrimary-default_IC: black;
  --systemPrimary-vibrant_IC: black;
  --systemPrimary-onLight_IC: black;
  --systemPrimary-onDark_IC: white;
  --systemSecondary: rgba(0, 0, 0, 0.5);
  --systemSecondary-vibrant: gray;
  --systemSecondary-onLight: rgba(0, 0, 0, 0.5);
  --systemSecondary-onDark: rgba(255, 255, 255, 0.55);
  --systemSecondary-default_IC: rgba(0, 0, 0, 0.76);
  --systemSecondary-vibrant_IC: rgba(0, 0, 0, 0.76);
  --systemSecondary-onLight_IC: rgba(0, 0, 0, 0.76);
  --systemSecondary-onDark_IC: rgba(255, 255, 255, 0.7);
  --systemTertiary: rgba(0, 0, 0, 0.25);
  --systemTertiary-vibrant: #bfbfbf;
  --systemTertiary-onLight: rgba(0, 0, 0, 0.25);
  --systemTertiary-onDark: rgba(255, 255, 255, 0.25);
  --systemTertiary-default_IC: rgba(0, 0, 0, 0.56);
  --systemTertiary-vibrant_IC: rgba(0, 0, 0, 0.56);
  --systemTertiary-onLight_IC: rgba(0, 0, 0, 0.56);
  --systemTertiary-onDark_IC: rgba(255, 255, 255, 0.5);
  --systemQuaternary: rgba(0, 0, 0, 0.1);
  --systemQuaternary-vibrant: #e6e6e6;
  --systemQuaternary-onLight: rgba(0, 0, 0, 0.1);
  --systemQuaternary-onDark: rgba(255, 255, 255, 0.1);
  --systemQuaternary-default_IC: rgba(0, 0, 0, 0.35);
  --systemQuaternary-vibrant_IC: rgba(0, 0, 0, 0.35);
  --systemQuaternary-onLight_IC: rgba(0, 0, 0, 0.35);
  --systemQuaternary-onDark_IC: rgba(255, 255, 255, 0.3);
  --systemQuinary: rgba(0, 0, 0, 0.05);
  --systemQuinary-vibrant: #f2f2f2;
  --systemQuinary-onLight: rgba(0, 0, 0, 0.05);
  --systemQuinary-onDark: rgba(255, 255, 255, 0.05);
  --systemQuinary-default_IC: rgba(0, 0, 0, 0.1);
  --systemQuinary-vibrant_IC: rgba(0, 0, 0, 0.1);
  --systemQuinary-onLight_IC: rgba(0, 0, 0, 0.1);
  --systemQuinary-onDark_IC: rgba(255, 255, 255, 0.1);
  --keyColor: #007aff;
  --keyColor-default_IC: #0040dd;
  --systemStandardUltrathinMaterialSover: rgba(246, 246, 246, 0.36);
  --systemStandardUltrathinMaterialSover-inactive: #f0f0f0;
  --systemStandardUltrathinMaterialSover-default_IC: #d2d2d2;
  --systemStandardUltrathinMaterialSover-inactive_IC: #ececec;
  --systemStandardThinMaterialSover: rgba(246, 246, 246, 0.48);
  --systemStandardThinMaterialSover-inactive: #f0f0f0;
  --systemStandardThinMaterialSover-default_IC: gainsboro;
  --systemStandardThinMaterialSover-inactive_IC: #ececec;
  --systemStandardMediumMaterialSover: rgba(246, 246, 246, 0.6);
  --systemStandardMediumMaterialSover-inactive: #f0f0f0;
  --systemStandardMediumMaterialSover-default_IC: #e6e6e6;
  --systemStandardMediumMaterialSover-inactive_IC: #ececec;
  --systemStandardThickMaterialSover: rgba(246, 246, 246, 0.72);
  --systemStandardThickMaterialSover-inactive: #f0f0f0;
  --systemStandardThickMaterialSover-default_IC: #f0f0f0;
  --systemStandardThickMaterialSover-inactive_IC: #ececec;
  --systemStandardUltrathickMaterialSover: rgba(246, 246, 246, 0.84);
  --systemStandardUltrathickMaterialSover-inactive: #f0f0f0;
  --systemStandardUltrathickMaterialSover-default_IC: #fafafa;
  --systemStandardUltrathickMaterialSover-inactive_IC: #ececec;
  --systemHeaderMaterialSover: rgba(255, 255, 255, 0.8);
  --systemHeaderMaterialSover-default_IC: white;
  --systemToolbarTitlebarMaterialSover: rgba(253, 253, 253, 0.8);
  --systemToolbarTitlebarMaterialSover-inactive: #f0f0f0;
  --systemToolbarTitlebarMaterialSover-default_IC: white;
  --systemToolbarTitlebarMaterialSover-inactive_IC: #ececec;
  --labelDivider: rgba(0, 0, 0, 0.15);
  --vibrantDivider: rgba(60, 60, 67, 0.29);
  --pageBG: white;
  --shelfBG: rgba(0, 0, 0, 0.05);
  --genericJoeColor: #ebebeb;
  --fallbackMaterialBG: rgba(255, 255, 255, 0.97);
  --liveBadgePlatterBG: #ff5066;
  --keyColorBackground: #007aff;
  --keyColorBackground-default_IC: #0040dd;
  --appPrimaryBackgroundColor: white;
  --appSecondaryBackgroundColor: #f0f0f3;
  --alertDropShadowColor: rgba(0, 0, 0, 0.25);
  --alertOuterStrokeColor: rgba(0, 0, 0, 0.2);
  --appModalBackgroundColor: white;
  --artworkBorderColor: rgba(151, 151, 151, 0.2);
  --ctaButtonBackgroundColor: white;
  --cardBackgroundColor: white;
  --defaultButtonBackgroundColor: rgba(84, 84, 84, 0.5);
  --dialogShadowColor: rgba(0, 0, 0, 0.25);
  --disabledLightColor: rbga(255, 255, 255, 0.4);
  --dividerTextColor: rgba(60, 60, 67, 0.29);
  --fauxNativeSheetBackgroundColor: white;
  --labelSecondary: rgba(235, 235, 245, 0.6);
  --liveBadgeBackgroundColor: #ff5066;
  --mobileNavigationBG: white;
  --modalBoxShadowColor: rgba(0, 0, 0, 0.24);
  --modalCloseButtonIconColor: rgba(60, 60, 67, 0.6);
  --modalContentOverlayColorEnd: white;
  --modalContentOverlayColorStart: rgba(255, 255, 255, 0);
  --modalHeaderBackgroundColor: rgba(255, 255, 255, 0.97);
  --modalHeaderShadowColor: rgba(0, 0, 0, 0.2);
  --modalScrimColor: rgba(0, 0, 0, 0.45);
  --navigationBackgroundColor: #eeeeee;
  --navigationBorderColor: #aaaaaa;
  --navigationBorderBottomColor: #aaaaaa;
  --navigationBorderLeftColor: #bbbbbb;
  --navigationBorderRightColor: #bbbbbb;
  --navigationBorderTopColor: #eeeeee;
  --navigationLinkSelectionStateColor: rgba(60, 60, 67, 0.1);
  --navigationMobileMenuBackgroundColor: white;
  --navigationScrollBackgroundColor: rgba(0, 0, 0, 0.4);
  --navigationScrollBackgroundFallbackColor: rgba(0, 0, 0, 0.6);
  --navigationTextColor: #555555;
  --overlayBackgroundColor: rgba(51, 51, 51, 0.3);
  --overlayButtonPlatterColor: rgba(255, 255, 255, 0.2);
  --personHeaderBackgroundColor: rgba(40, 40, 40, 0.9);
  --playerBackground: rgba(255, 255, 255, 0.88);
  --playbackOverlayBackgroundColor: rgba(51, 51, 51, 0.7);
  --progressBarColor: rgba(255, 255, 255, 0.4);
  --progressBarFillColor: white;
  --searchInputBorderColor: rgba(0, 0, 0, 0.15);
  --settingsMenuPointer: rgba(255, 255, 255, 0.5);
  --sidebarActiveItemBackgroundColor: rgba(60, 60, 67, 0.1);
  --sidebarBackgroundColor: rgba(60, 60, 67, 0.03);
  --sidebarSearchInputBorderColor: rgba(0, 0, 0, 0.15);
  --sportLockupClockTimeBadgeBackgroundColor: rgba(0, 0, 0, 0.2);
  --sportLockupScoreBadgeDivider: rgba(0, 0, 0, 0.15);
  --unifiedVolumeThumbShadow: rgba(0, 0, 0, 0.1);
  --unifiedVolumeThumbShadowInset: rgba(0, 0, 0, 0.15);
  --unifiedVolumeTrackFilled: rgba(246, 246, 246, 0.36);
  --unifiedVolumeThumb: #d0d0d0;
  --unifiedPlayerIcons: rgba(0, 0, 0, 0.95);
  --navigationIconColor: #7f7f7f;
  --modalBGColor: rgba(255, 255, 255, 1);
  --labelPrimary: rgba(0, 0, 0, 0.95);
  --shelf-arrow-offset: 38px;
  --skip-button-visibility: hidden;
}

@supports not (font:-apple-system-body) {
  :root {
      --systemPrimary: rgba(0, 0, 0, 0.88);
      --systemPrimary-vibrant: #1d1d1f;
      --systemPrimary-onLight: rgba(0, 0, 0, 0.88);
      --systemPrimary-onDark: rgba(255, 255, 255, 0.92);
      --systemSecondary: rgba(0, 0, 0, 0.56);
      --systemSecondary-vibrant: rgba(0, 0, 0, 0.56);
      --systemSecondary-onLight: rgba(0, 0, 0, 0.56);
      --systemSecondary-onDark: rgba(255, 255, 255, 0.64);
      --systemTertiary: rgba(0, 0, 0, 0.48);
      --systemTertiary-vibrant: rgba(0, 0, 0, 0.48);
      --systemTertiary-onLight: rgba(0, 0, 0, 0.48);
      --systemTertiary-onDark: rgba(255, 255, 255, 0.4);
      --keyColorBackground: #0040dd;
  }
}

  :root {
    --systemRed: #ff453a;
    --systemRed-vibrant: #ff4f44;
    --systemRed-default_IC: #ff6961;
    --systemRed-vibrant_IC: #ff4136;
    --systemOrange: #ff9f0a;
    --systemOrange-vibrant: #ffa914;
    --systemOrange-default_IC: #ffb340;
    --systemOrange-vibrant_IC: #ffb340;
    --systemYellow: #ffd60a;
    --systemYellow-vibrant: #ffe014;
    --systemYellow-default_IC: #ffd426;
    --systemYellow-vibrant_IC: #ffd426;
    --systemGreen: #32d74b;
    --systemGreen-vibrant: #3ce155;
    --systemGreen-default_IC: #31de4b;
    --systemGreen-vibrant_IC: #31de4b;
    --systemMint: #63e6e2;
    --systemMint-vibrant: #6ce0db;
    --systemMint-default_IC: #63e6e2;
    --systemMint-vibrant_IC: #63e6e2;
    --systemTeal: #6ac4dc;
    --systemTeal-vibrant: #44d4ed;
    --systemTeal-default_IC: #5de6ff;
    --systemTeal-vibrant_IC: #5de6ff;
    --systemCyan: #5ac8f5;
    --systemCyan-vibrant: #5acdfa;
    --systemCyan-default_IC: #70d7ff;
    --systemCyan-vibrant_IC: #70d7ff;
    --systemBlue: #0a84ff;
    --systemBlue-vibrant: #148eff;
    --systemBlue-default_IC: #409cff;
    --systemBlue-vibrant_IC: #409cff;
    --systemBlue-rollover: #47c1ff;
    --systemBlue-pressed: #38b2ff;
    --systemBlue-deepPressed: #47c1ff;
    --systemBlue-disabled: rgba(10, 132, 255, 0.5);
    --systemIndigo: #5e5ce6;
    --systemIndigo-vibrant: #6361f2;
    --systemIndigo-default_IC: #7d7aff;
    --systemIndigo-vibrant_IC: #7d7aff;
    --systemPurple: #bf5af2;
    --systemPurple-vibrant: #cc65ff;
    --systemPurple-default_IC: #da8fff;
    --systemPurple-vibrant_IC: #da8fff;
    --systemPink: #ff375f;
    --systemPink-vibrant: #ff4169;
    --systemPink-default_IC: #ff6482;
    --systemPink-vibrant_IC: #ff3a5f;
    --systemBrown: #ac8e68;
    --systemBrown-vibrant: #b69872;
    --systemBrown-default_IC: #b59469;
    --systemBrown-vibrant_IC: #b59469;
    --systemGray: #98989d;
    --systemGray-vibrant: #a2a2a7;
    --systemGray-default_IC: #98989d;
    --systemGray-vibrant_IC: #98989d;
    --systemPrimary: rgba(255, 255, 255, 0.85);
    --systemPrimary-vibrant: #e5e5e5;
    --systemPrimary-onLight: rgba(0, 0, 0, 0.85);
    --systemPrimary-onDark: rgba(255, 255, 255, 0.85);
    --systemPrimary-default_IC: white;
    --systemPrimary-vibrant_IC: white;
    --systemPrimary-onLight_IC: black;
    --systemPrimary-onDark_IC: white;
    --systemSecondary: rgba(255, 255, 255, 0.55);
    --systemSecondary-vibrant: #7c7c7c;
    --systemSecondary-onLight: rgba(0, 0, 0, 0.5);
    --systemSecondary-onDark: rgba(255, 255, 255, 0.55);
    --systemSecondary-default_IC: rgba(255, 255, 255, 0.7);
    --systemSecondary-vibrant_IC: rgba(255, 255, 255, 0.7);
    --systemSecondary-onLight_IC: rgba(0, 0, 0, 0.76);
    --systemSecondary-onDark_IC: rgba(255, 255, 255, 0.7);
    --systemTertiary: rgba(255, 255, 255, 0.25);
    --systemTertiary-vibrant: #414141;
    --systemTertiary-onLight: rgba(0, 0, 0, 0.25);
    --systemTertiary-onDark: rgba(255, 255, 255, 0.25);
    --systemTertiary-default_IC: rgba(255, 255, 255, 0.5);
    --systemTertiary-vibrant_IC: rgba(255, 255, 255, 0.5);
    --systemTertiary-onLight_IC: rgba(0, 0, 0, 0.56);
    --systemTertiary-onDark_IC: rgba(255, 255, 255, 0.5);
    --systemQuaternary: rgba(255, 255, 255, 0.1);
    --systemQuaternary-vibrant: #232323;
    --systemQuaternary-onLight: rgba(0, 0, 0, 0.1);
    --systemQuaternary-onDark: rgba(255, 255, 255, 0.1);
    --systemQuaternary-default_IC: rgba(255, 255, 255, 0.3);
    --systemQuaternary-vibrant_IC: rgba(255, 255, 255, 0.3);
    --systemQuaternary-onLight_IC: rgba(0, 0, 0, 0.35);
    --systemQuaternary-onDark_IC: rgba(255, 255, 255, 0.3);
    --systemQuinary: rgba(255, 255, 255, 0.05);
    --systemQuinary-vibrant: #080808;
    --systemQuinary-onLight: rgba(0, 0, 0, 0.05);
    --systemQuinary-onDark: rgba(255, 255, 255, 0.05);
    --systemQuinary-default_IC: rgba(255, 255, 255, 0.1);
    --systemQuinary-vibrant_IC: rgba(255, 255, 255, 0.1);
    --systemQuinary-onLight_IC: rgba(0, 0, 0, 0.1);
    --systemQuinary-onDark_IC: rgba(255, 255, 255, 0.1);
    --keyColor: #0a84ff;
    --keyColor-default_IC: #409cff;
    --systemStandardUltrathinMaterialSover: rgba(40, 40, 40, 0.4);
    --systemStandardUltrathinMaterialSover-inactive: #282828;
    --systemStandardUltrathinMaterialSover-default_IC: black;
    --systemStandardUltrathinMaterialSover-inactive_IC: #141414;
    --systemStandardThinMaterialSover: rgba(40, 40, 40, 0.5);
    --systemStandardThinMaterialSover-inactive: #282828;
    --systemStandardThinMaterialSover-default_IC: #0a0a0a;
    --systemStandardThinMaterialSover-inactive_IC: #141414;
    --systemStandardMediumMaterialSover: rgba(40, 40, 40, 0.6);
    --systemStandardMediumMaterialSover-inactive: #282828;
    --systemStandardMediumMaterialSover-default_IC: #141414;
    --systemStandardMediumMaterialSover-inactive_IC: #141414;
    --systemStandardThickMaterialSover: rgba(40, 40, 40, 0.7);
    --systemStandardThickMaterialSover-inactive: #282828;
    --systemStandardThickMaterialSover-default_IC: #1e1e1e;
    --systemStandardThickMaterialSover-inactive_IC: #141414;
    --systemStandardUltrathickMaterialSover: rgba(40, 40, 40, 0.8);
    --systemStandardUltrathickMaterialSover-inactive: #282828;
    --systemStandardUltrathickMaterialSover-default_IC: #282828;
    --systemStandardUltrathickMaterialSover-inactive_IC: #141414;
    --systemHeaderMaterialSover: rgba(30, 30, 30, 0.8);
    --systemHeaderMaterialSover-default_IC: #1e1e1e;
    --systemToolbarTitlebarMaterialSover: rgba(60, 60, 60, 0.8);
    --systemToolbarTitlebarMaterialSover-inactive: #282828;
    --systemToolbarTitlebarMaterialSover-default_IC: #262626;
    --systemToolbarTitlebarMaterialSover-inactive_IC: #141414;
    --labelDivider: rgba(255, 255, 255, 0.1);
    --vibrantDivider: rgba(235, 235, 245, 0.19);
    --pageBG: #1f1f1f;
    --shelfBG: rgba(255, 255, 255, 0.05);
    --genericJoeColor: #323232;
    --fallbackMaterialBG: rgba(31, 31, 31, 0.97);
    --liveBadgePlatterBG: #ff5066;
    --keyColorBackground: #0a84ff;
    --keyColorBackground-default_IC: #409cff;
    --appPrimaryBackgroundColor: #131313;
    --appSecondaryBackgroundColor: rgba(0, 0, 0, 0.3);
    --alertDropShadowColor: rgba(0, 0, 0, 0.55);
    --alertOuterStrokeColor: rgba(0, 0, 0, 0.5);
    --appModalBackgroundColor: #2d2d2d;
    --artworkBorderColor: rgba(151, 151, 151, 0.2);
    --ctaButtonBackgroundColor: white;
    --cardBackgroundColor: var(--systemQuaternary-onDark);
    --defaultButtonBackgroundColor: rgba(84, 84, 84, 0.5);
    --dialogShadowColor: rgba(0, 0, 0, 0.55);
    --disabledLightColor: rbga(255, 255, 255, 0.4);
    --dividerTextColor: rgba(235, 235, 245, 0.19);
    --fauxNativeSheetBackgroundColor: #2d2d2d;
    --labelSecondary: rgba(60, 60, 67, 0.6);
    --liveBadgeBackgroundColor: #ff5066;
    --mobileNavigationBG: #2e2e2e;
    --modalBoxShadowColor: rgba(0, 0, 0, 0.24);
    --modalCloseButtonIconColor: rgba(235, 235, 245, 0.6);
    --modalContentOverlayColorEnd: #2d2d2d;
    --modalContentOverlayColorStart: rgba(45, 45, 45, 0);
    --modalHeaderBackgroundColor: rgba(45, 45, 45, 0.97);
    --modalHeaderShadowColor: rgba(0, 0, 0, 0.2);
    --modalScrimColor: rgba(0, 0, 0, 0.55);
    --navigationBackgroundColor: #5b5b5b;
    --navigationBorderColor: #353535;
    --navigationBorderBottomColor: #484848;
    --navigationBorderLeftColor: #525252;
    --navigationBorderRightColor: #525252;
    --navigationBorderTopColor: #848484;
    --navigationLinkSelectionStateColor: rgba(235, 235, 245, 0.1);
    --navigationMobileMenuBackgroundColor: white;
    --navigationScrollBackgroundColor: rgba(0, 0, 0, 0.4);
    --navigationScrollBackgroundFallbackColor: rgba(0, 0, 0, 0.6);
    --navigationTextColor: #c8c8c8;
    --overlayBackgroundColor: rgba(51, 51, 51, 0.3);
    --overlayButtonPlatterColor: rgba(255, 255, 255, 0.2);
    --personHeaderBackgroundColor: rgba(40, 40, 40, 0.9);
    --playerBackground: rgba(45, 45, 45, 0.88);
    --playbackOverlayBackgroundColor: rgba(51, 51, 51, 0.7);
    --progressBarColor: rgba(255, 255, 255, 0.4);
    --progressBarFillColor: white;
    --searchInputBorderColor: rgba(255, 255, 255, 0.15);
    --settingsMenuPointer: rgba(45, 45, 45, 0.5);
    --sidebarActiveItemBackgroundColor: rgba(235, 235, 245, 0.1);
    --sidebarBackgroundColor: rgba(235, 235, 245, 0.03);
    --sidebarSearchInputBorderColor: rgba(255, 255, 255, 0.15);
    --sportLockupClockTimeBadgeBackgroundColor: rgba(0, 0, 0, 0.2);
    --sportLockupScoreBadgeDivider: rgba(0, 0, 0, 0.15);
    --unifiedVolumeThumbShadow: rgba(0, 0, 0, 0.1);
    --unifiedVolumeThumbShadowInset: rgba(0, 0, 0, 0.15);
    --unifiedVolumeTrackFilled: rgba(246, 246, 246, 0.36);
    --unifiedVolumeThumb: #d0d0d0;
    --unifiedPlayerIcons: rgba(255, 255, 255, 0.9);
  }


@supports not (font:-apple-system-body) {
    :root {
        --systemPrimary: rgba(0, 0, 0, 0.88);
        --systemPrimary-vibrant: #1d1d1f;
        --systemPrimary-onLight: rgba(0, 0, 0, 0.88);
        --systemPrimary-onDark: rgba(255, 255, 255, 0.92);
        --systemSecondary: rgba(0, 0, 0, 0.56);
        --systemSecondary-vibrant: rgba(0, 0, 0, 0.56);
        --systemSecondary-onLight: rgba(0, 0, 0, 0.56);
        --systemSecondary-onDark: rgba(255, 255, 255, 0.64);
        --systemTertiary: rgba(0, 0, 0, 0.48);
        --systemTertiary-vibrant: rgba(0, 0, 0, 0.48);
        --systemTertiary-onLight: rgba(0, 0, 0, 0.48);
        --systemTertiary-onDark: rgba(255, 255, 255, 0.4);
        --keyColorBackground: #0040dd;
    }
}

  @supports not (font:-apple-system-body) {
      :root {
          --systemPrimary: rgba(255, 255, 255, 0.92);
          --systemPrimary-vibrant: #f5f5f7;
          --systemPrimary-onLight: rgba(0, 0, 0, 0.88);
          --systemPrimary-onDark: rgba(255, 255, 255, 0.92);
          --systemSecondary: rgba(255, 255, 255, 0.64);
          --systemSecondary-vibrant: #a1a1a6;
          --systemSecondary-onLight: rgba(0, 0, 0, 0.56);
          --systemSecondary-onDark: rgba(255, 255, 255, 0.64);
          --systemTertiary: rgba(255, 255, 255, 0.4);
          --systemTertiary-vibrant: #6e6e73;
          --systemTertiary-onLight: rgba(0, 0, 0, 0.48);
          --systemTertiary-onDark: rgba(255, 255, 255, 0.4);
          --keyColorBackground: #0040dd;
      }
  }


body {
  background-color: var(--systemPrimary-onLight);
  background-color: var(--appPrimaryBackgroundColor);
  background-color: var(--systemPrimary);
  background-color: var(--appPrimaryBackgroundColor);
  color: var(--systemPrimary);
  font-family: "-apple-system",BlinkMacSystemFont,"-webkit-system-font","Malgun Gothic","Segoe UI","Helvetica Neue",Helvetica,sans-serif;
  -webkit-font-feature-settings: 'kern';
  font-feature-settings: 'kern';
  -moz-font-feature-settings: 'kern';
  line-height: 1.23077;
  letter-spacing: 0;
}

.typography-body, body {
  font-size: 13px;
  font-weight: 400;
}

.person-header__bio__source, body {
  font-style: normal;
}

body, button, input, select, textarea {
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, blockquote, body, code, dd, div, dl, dt, embed, fieldset, footer, form, h1, h2, h3, h4, h5, h6, header, html, img, legend, li, ol, p, pre, section, table, td, th, ul {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  margin: 0;
  padding: 0;
}

.clr-primary-text {
  color: var(--systemPrimary);
}

.loading-inner {
  height: calc(100% - var(--footer-height-mobile));
}

main {
  --footer-height-desktop: 89px;
  --footer-height-mobile: 148px;
  min-height: calc(100% - var(--footer-height-mobile));
  position: relative;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.shelf-grid {
  margin-bottom: 24px;
  background-color: #00000000;
  /* background-color: var(--appSecondaryBackgroundColor); */

  width: 100%;
  padding: 0 40px;
  position: relative;
  z-index: 1;
}

.shelf-grid__header {
  /* padding: 13px 25px; production  */
  padding: 13px 25px;
}

.shelf-header--with-see-all {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.episode-shelf-header, .ember-basic-dropdown, .ember-power-select-trigger, .typ-headline-emph {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.23;
  margin: 0px;
}

.shelf-grid__body {
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
}

.shelf-grid .shelf-grid__body {
  margin-top: calc(15px * -1);
  margin-bottom: calc(15px * -1);
  margin-right: calc(15px * -1);
  margin-left: calc(15px * -1);
  padding: 15px;
}

/* .shelf-grid .shelf-grid__list {
  grid-auto-columns: calc((100% - 2 * 2px)/ 1.5);
  grid-template-rows: repeat(var(--grid-rows),-webkit-max-content);
  grid-template-rows: repeat(var(--grid-rows),max-content);

  grid-column-gap: var(--grid-column-gap);
  grid-column-gap: 10px;

  margin-top: -20px;
  margin-bottom: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

[data-grid='C'] .shelf-grid__list {
  grid-auto-columns: 200px;
  grid-template-rows: repeat(var(--grid-rows),-webkit-max-content);
  grid-template-rows: repeat(var(--grid-rows),max-content);
  grid-column-gap: 10px;
} */

/* .shelf-grid-nav, .shelf-grid-nav ul, .shelf-grid__list {
  margin: 0;
  list-style: none;
}

.shelf-grid__list {
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior-x: none;
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

.epic-stage, .shelf-grid, .shelf-grid__list-item {
  position: relative;
} */

/* .shelf-grid__list-item {
  scroll-snap-align: center;
  background-color: blue;
  background-color: #272727d9;
    border-radius: 15px;
    aspect-ratio: 1/.85;
} */

.ordinal-chart-lockup {
  /* display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  border-radius: 11px;
  background-color: #3c3c3c;
  background-color: #272727d9;
  background-color: #00000024;
  background-color: linear-gradient(to-bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 255, 0, 1) 50%, rgba(0, 0, 0, 1) 100%); */
  /* ADJUST HOVER BELOW TO BE THE SAME */
  /* background: linear-gradient(to bottom, rgb(29, 29, 29, 0.0) 0%, rgb(255, 255, 255, 0) 70%, rgba(0, 0, 0, .6) 100%); */
  /* ADJUST HOVER BELOW TO BE THE SAME */
      /* margin-bottom: 12px; */
      /* -webkit-backdrop-filter: saturate(190%) blur(60px);  */
      /* backdrop-filter: saturate(190%) blur(60px); */
      /* background-color: red; */
      /* justify-content: baseline; */
      /* padding: 15px; */
}

.ordinal-chart-lockup {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  height: 100%;
}

.ordinal-chart-lockup:hover {
  /* background: linear-gradient(to bottom, rgb(29, 29, 29, 0.0) 0%, rgb(255, 255, 255, 0) 70%, rgba(0, 0, 0, .6) 100%); */
  position: relative;
  /* pointer-events: ; */
  cursor: pointer;
  z-index: 1;
}


.ordinal-chart__artwork, .ordinal-chart__ordinal {
  flex-shrink: 0;
}

/* .canvas-lockup, .watch-live-lockup {
  position: relative;
  aspect-ratio: 1/ .5;
  position: absolute;
  width: 100%;
  z-index: 0;
} */

.shelf-grid {
  margin-bottom: 0px;
  margin-bottom: 10px;
  /* scroll-snap-align: start; */
}

.media-artwork-v2__container, .media-artwork-v2__image {
  z-index: 1;
}

.canvas-lockup__artwork, .library-container, .library-lockup, .canvas-lockup__artwork, .watch-live-lockup__artwork {
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 4px 7px rgba(0,0,0,.25);
  margin-bottom: 8px;
  margin-bottom: -40px;
  overflow: hidden;
}

.canvas-lockup__artwork.none {
  border-radius: 12px;
  box-sizing: border-box;
  /* box-shadow: 0 4px 7px rgba(0,0,0,.25); */
  margin-bottom: 8px;
  overflow: hidden;
}



.canvas-lockup__artwork.bronze {
  border-radius: 12px;
  box-sizing: border-box;
  /* border: 5px solid rgba(169, 102, 14, 0.6); */
  /* box-shadow: 0px 0px 7px 4px rgba(169, 102, 14, 0.6); */
  margin-bottom: 8px;
  overflow: hidden;
}

.canvas-lockup__artwork.silver {
  border-radius: 12px;
  box-sizing: border-box;
  /* border: 5px solid rgba(138, 138, 138, 0.792); */
  /* box-shadow: 0px 0px 7px 4px rgba(138, 138, 138, 0.792); */
  margin-bottom: 8px;
  overflow: hidden;
}

.canvas-lockup__artwork.gold {
  border-radius: 12px;
  box-sizing: border-box;
  /* border: 5px solid rgba(253, 208, 45, 0.6); */
  /* box-shadow: 0px 0px 7px 4px rgba(253, 208, 45, 0.6); */
  margin-bottom: 8px;
  overflow: hidden;
}

.canvas-lockup__artwork.diamond {
  border-radius: 12px;
  box-sizing: border-box;
  /* border: 5px solid rgba(150, 147, 239, 0.6); */
  /* background: rgba(150, 147, 239, 0.6); */
  margin-bottom: 8px;
  overflow: hidden;
}


.media-artwork-v2__container {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto; */
}

.canvas-lockup__artwork, .media-artwork-v2__image, .library-container, .library-lockup, .canvas-lockup__artwork, .media-artwork-v2__image, .watch-live-lockup__artwork, .media-artwork-v2__image {
  border-radius: 12px;
  
}

.media-artwork-v2--image-did-attempt-download, .media-artwork-v2__image, .media-artwork-v2--image-did-error, .media-artwork-v2__image {
  opacity: 1;
}

.media-artwork-v2__container, .media-artwork-v2__image {
  z-index: 1;
}

.media-artwork-v2__image {
  border-radius: 0px;
  bottom: -9999px;
  left: -9999px;
  margin: auto;
  opacity: 0;
  position: absolute;
  right: -9999px;
  top: -9999px;
  transition-duration: .15s;
  transition-property: opacity;
  height: 100%;
  /* width: 100%; */
  object-fit: cover;
  padding: 0px;
}

/* .canvas-lockup.diamond {
  position: relative;
  background: rgba(150, 147, 239, 0.6);
  clip-path: polygon(50% 0%, 100% 10%, 100% 90%, 50% 100%, 0% 90%, 0% 10%);
  box-sizing: initial;
  z-index: 2;
} */


/* Style for the buttons and ratings container */
.flex-container {
  display: flex;
  justify-content: space-around; /* This will space out the items evenly */
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  text-align: center;
}

.flex-item {
  flex-basis: 45%; /* Each button will take up 45% of the container width, allowing for two side-by-side */
}

.flex-item-rating {
  flex-basis: 30%; /* Each rating will take up about 30% of the container width, allowing for three in a row */
}

.review-card:first-child {
  /* grid-column: 1 / span 3; */
}

.rating-card{
  /* text-align: center; */
  grid-template-columns: repeat(2, 1fr);
}

.product-footer__info, .product-footer__metadata {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

@media only screen and (max-width: 739px) {
  .product-footer__info, .product-footer__metadata {
    grid-template-columns: 1fr;
  }
  .review-card:first-child {
    /* grid-column: 1 / span 2; */
  }

  .even-split {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .rating-card{
    /* text-align: center; */
  }
}


.canvas-lockup, .lockup-overlay__generic_content, .watch-live-lockup, .lockup-overlay__generic_content {
  pointer-events: none;
}

.landing__main {
  padding-bottom: 90px;
}

.production-company {
  /* white-space: nowrap; */
  /* overflow: hidden; */
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.explore-landing__title {
  padding: 0 40px;
  /* padding-top: 20px; */
  padding-top: 20px;
  overflow-x: clip;
  /* scroll-snap-align: start; */
  scroll-snap-align: start;
}

@media only screen and (max-width: 740px) {
  .explore-landing__title {
    padding: 0 25px;
    /* padding-top: 10px; */
    overflow-x: clip;
    padding-top: 20px;
    scroll-snap-align: start;
  }
}

/* 
.canvas-lockup, .lockup-overlay__generic_content, .canvas-lockup, .lockup-overlay__playback, .watch-live-lockup, .lockup-overlay__generic_content, .watch-live-lockup, .lockup-overlay__playback {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: ease-in;
} 
*/

.canvas-lockup .lockup-overlay__generic_content, .canvas-lockup .lockup-overlay__playback, .watch-live-lockup, .lockup-overlay__generic_content, .watch-live-lockup, .lockup-overlay__playback {
  opacity: 1;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: ease-in;
} 

.lockup-overlay--button-container {
  /* display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0px; */

  display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 11px;

  /* display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px;
    flex-direction: column;

    padding-bottom: 7%; */
}

.secondary-bg-color {
  background-color: var(--appSecondaryBackgroundColor);
}

.lockup-overlay {
  /* background-color: var(--overlayBackgroundColor); */
  /* background-image: linear-gradient(to bottom, rgba(0,0,255,0) 0%, rgba(0,0,255,0) 70%, rgba(0,0,0,.6) 90%, rgba(0,0,0,1) 95%); */
  border-radius: 11px;
  inset: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
/* 
.lockup-overlay.bronze {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0) 40%,  var(--systemBronze) 81%,var(--systemBronze) 100%);
}

.lockup-overlay.silver {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0) 30%, var(--systemSilver) 81%, #7D7D7A 100%);
}

.lockup-overlay.gold {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0) 40%,  var(--systemGold) 81%, #e2b00ece 100%);
}

.lockup-overlay.diamond {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0) 40%, var(--systemDiamond) 81%, rgba(150,147,239) 100%);
} */



.lockup-button {
  position: relative;
  pointer-events: auto;
  /* width: 27px;
  height: 27px; */
  width: 10%;
  /* height: 20px; */
  height: auto;
  aspect-ratio: 1;
  border-radius: 27px;
  background-color: transparent;
  border: none;
  opacity: 1;
  z-index: 1;
}

.lockup-button--grouped {
  margin-left: 11px;
}



[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

body, button, input, select, textarea {
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button, select {
  text-transform: none;
}

button, input {
  overflow: visible;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

@supports ((-webkit-backdrop-filter: initial) or (backdrop-filter: initial)) {
    .lockup-button::before {
      /* -webkit-backdrop-filter: saturate(180%) blur(60px);
      backdrop-filter: saturate(180%) blur(60px); */
    }
  
}

@supports ((-webkit-backdrop-filter: initial) or (backdrop-filter: initial)) {
  .lockup-button::before {
    /* background-color: var(--systemStandardThinMaterialSover); */
    /* -webkit-backdrop-filter: saturate(190%) blur(60px);
    backdrop-filter: saturate(190%) blur(60px); */
  }
}

.lockup-button::before {
  background-color: #00000000;
  border-radius: 7px;
}

.lockup-button::after, .lockup-button::before {
  width: 40px;
  height: 40px;
  
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  z-index: 1;
}

.lockup-button::after {
  /* background-color: var(--systemPrimary-onDark); */
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;

  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

/* .lockup-button::after, .lockup-button::before {
  width: 100%;
  height: auto;
  aspect-ratio: 40/14.35;
  
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  z-index: 1;
} */

.lockup-button::after, .lockup-button::before {
  width: 27px;
  height: 27px;
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  z-index: 1;
}

.mrec {
  right: 0px;
    position: absolute;
    margin: 20px;
    height: 30px;
    width: 30px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.recm {
  left: 0px;
    position: absolute;
    margin: 20px;
    height: 30px;
    width: 30px;
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.mrec.selected {
  /* background-color: green; */
}

.recm.selected {
  /* background-color: purple; */
}

.mrec::before {
  height: 50px;
    width: 50px;
    border-radius: 100%;
  -webkit-backdrop-filter: saturate(180%) blur(60px);
    backdrop-filter: saturate(180%) blur(60px);
    background-color: var(--systemStandardThinMaterialSover);
    background-color: rgba(246, 246, 246, 0.48);
    -webkit-backdrop-filter: saturate(190%) blur(60px);
    backdrop-filter: saturate(190%) blur(60px);
    /* background-color: white; */
}

.recm::before {
  height: 50px;
    width: 50px;
    border-radius: 100%;
    left: 0;
 
  -webkit-backdrop-filter: saturate(180%) blur(60px);
    backdrop-filter: saturate(180%) blur(60px);
    background-color: var(--systemStandardThinMaterialSover);
    /* background-color: rgba(246, 246, 246, 0.48); */
    background-color: rgba(246, 246, 246, 0.48);
    /* background-color: white; */
    -webkit-backdrop-filter: saturate(190%) blur(60px);
    backdrop-filter: saturate(190%) blur(60px);
}

.mrec.seen::after {
    -webkit-mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png);
    mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png);

    background-color: black;
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.mrec.unseen::after {

  -webkit-mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png);
    mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png);


  -webkit-mask-image: url(../assets/button_add@2x-453d8aecad37017d736a90ae1c2453f3.png);
  mask-image: url(../assets/button_add@2x-453d8aecad37017d736a90ae1c2453f3.png);

  background-color: black;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.mrec::after {
  /* -webkit-mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png);
  mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png);

  -webkit-mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png);
    mask-image: url(../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png); */

  background-color: black;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.icon {
  display: inline-block;
  width: 24px; 
  height: 24px; 
  /* background-color: white; */
  margin-bottom: 5px;
}

.icon-check {
  -webkit-mask-image: url('../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png');
  mask-image: url('../assets/button_check@2x-b4f8a30d062df74ceeb7ac0f00123e6d.png');
  margin-top: 7px;
}

.icon-add {
  -webkit-mask-image: url('../assets/button_add@2x-453d8aecad37017d736a90ae1c2453f3.png');
  mask-image: url('../assets/button_add@2x-453d8aecad37017d736a90ae1c2453f3.png');
  background-color: var(--keyColor);
  margin-top: 7px;
}

.movie-bottom-bar {
  text-align: center;
  color: white;
  width: 100%;
  position: absolute;
  bottom: 0px;
  border-radius: 0px 0px 12px 12px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  padding: 10px; /* Consolidated padding */
  box-sizing: border-box;
  background-color: rgba(40, 40, 40, 0.5); /* Base color if backdrop-filter isn't effective */
  -webkit-backdrop-filter: saturate(190%) blur(60px); /* Consolidated filter */
  backdrop-filter: saturate(190%) blur(60px);
  z-index: 2; /* Ensure it's on top of elements that might interfere */
}

@media only screen and (min-width: 740px) {
  .movie-bottom-bar {
    background-color: rgb(40, 40, 40); /* Base color if backdrop-filter isn't effective */
  }
}

.movie-top-bar {
  text-align: center;
  color: white;
  width: 100%;
  position: absolute;
  top: 0px;
  border-radius: 0px 0px 12px 12px;
  border-radius: 12px 12px 0px 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  padding: 10px; /* Consolidated padding */
  box-sizing: border-box;
  background-color: rgba(40, 40, 40, 0.5); 
  /* -webkit-backdrop-filter: saturate(190%) blur(60px); 
  backdrop-filter: saturate(190%) blur(60px); */
  border-radius: 12px 12px 0px 0px;

  z-index: 2; /* Ensure it's on top of elements that might interfere */
}

@media only screen and (min-width: 740px) {
  .movie-top-bar {
    background-color: rgb(40, 40, 40); /* Base color if backdrop-filter isn't effective */
  }
}

.movie-top-text {
  position: relative;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
    flex-direction: column;
    justify-content: center;
  bottom: 0px;
  border-radius: 0px 10px 0px 10px;
  border-radius: 10px;
  width: 40%;
  font-size: 16px;
  box-sizing: border-box;
  flex: 0 0 calc(40% - 10px);

  pointer-events: auto;
  font-weight: 500;
  align-items: center;
}

.noImgPlsLeft {
  /* background-color: rgba(246, 246, 246, 0.48); */
  /* background-color: rgba(0, 0, 0, .5); */
  position: relative;    
  height: 75px;
  width: 30%;
  bottom: 0px;
  border-radius: 0px 10px 0px 10px;
  border-radius: 10px;
  box-sizing: border-box;
  /* flex: 0 0 calc(30% - 10px); */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noImgPlsRight {
  /* background-color: rgba(246, 246, 246, 0.48); */
  /* background-color: rgba(0, 0, 0, .5); */
  position: relative;    
  height: 75px;
  width: 30%;
  bottom: 0px;
  border-radius: 10px 0px 10px 0px;
  border-radius: 10px;
  box-sizing: border-box;
  /* flex: 0 0 calc(30% - 10px); */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.movie-bottom-text {
  position: relative;
  bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
    flex-direction: column;
    justify-content: center;
  /* background-color: rgba(0, 0, 0, .5); */
  bottom: 0px;
  border-radius: 0px 10px 0px 10px;
  border-radius: 10px;
  width: 40%;
  /* margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px; */

  font-size: 16px;
  box-sizing: border-box;
  flex: 0 0 calc(40% - 10px);
}

.header-shelf__shelf-container, [data-grid=UpNext] .lockup-button::after, .lockup-button--context-menu::after {
  /* -webkit-mask-image: url(badges/small-more-4491204….svg);
  mask-image: url(badges/small-more-4491204….svg); */
}

.toggle-movies4{
  background-color: rgb(36, 36, 36);
  background-color: var(--keyColor);
  color: white;
  border: none;
  border: #49494949 1px solid;
  text-align: left;
  /* padding: 15px; */
}

.toggle-moviesB4{
  color: white;
  color: var(--systemPrimary);
  border: none;
  /* text-align: left; */
  /* padding-left: 0px; */
  font-size: 15px;
}

.buttonIconColor {
  color: var(--systemPrimary);
  width: 18px; 
  fill: none; 
  stroke: white; 
  stroke: var(--systemPrimary);
  stroke-width: 2px; 
  margin-bottom: 0px;
}

.buttonIconColorNoFill {
  width: 18px;
  fill: none;
  stroke: var(--systemPrimary);
  stroke-width: 2px;
  margin-bottom: 0px;
}

.buttonIconColorFill {
  width: 18px;
  fill: var(--systemPrimary);
  stroke: var(--systemPrimary);
  stroke-width: 2px;
  margin-bottom: 0px;
}

.ordinal-chart__content {
  display: flex;
  width: 100%;
  overflow-x: clip;
}

.ordinal-chart__artwork, .ordinal-chart__ordinal {
  flex-shrink: 0;
}

.ordinal-chart__metadata {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 1;
  width: 100%;
}

.ordinal-chart__ordinal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 12px;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: .875;
}

.ordinal-chart-item__title {
  /* margin-top: 1px; */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25;

  -webkit-line-clamp: 2;
}

@media (min-width: 600px) {
  .ordinal-chart-item__title {
    font-size: 14px;
  }
}

@media (min-width: 1000px) {
  .ordinal-chart-item__title {
    font-size: 14px;
  }
}

.d3o3Ad {
  color-scheme: light;
  font-family: Roboto,arial,sans-serif;
  line-height: 1.58;
  font-size: 14px;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  color: #4d5156;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  overflow: hidden;
}


.similar-text {
  margin-top: 1px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25;

  -webkit-line-clamp: 2;
}

@media (min-width: 600px) {
  .similar-text {
    font-size: 12px;
  }
}

@media (min-width: 1000px) {
  .similar-text {
    font-size: 16px;
  }
}

.clr-secondary-text, .watch-card-lockup__content__subtitle {
  /* color: var(--systemSecondary);
  color: var(--systemPrimary); */
  color: var(--systemPrimary);
}

.ordinal-chart-item__metadata {
  margin-bottom: 1px;
  font-size: 13px;
  line-height: 1.23077;
  font-weight: 400;
  letter-spacing: 0;
  -webkit-line-clamp: 1;
}

@media only screen and (min-width: 740px) {
  .ordinal-chart-item__metadata {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.1;
  }
}

.movie-bundle-sheet__description, .ordinal-chart-item__metadata {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

/* @media only screen and (min-width: 740px) {
  .shelf-grid .shelf-grid__list {
    grid-auto-columns: calc((100% - 2 * var(--grid-column-gap)) / 3);
    grid-template-rows: repeat(var(--grid-rows), -webkit-max-content);
    grid-template-rows: repeat(var(--grid-rows), max-content);
    grid-column-gap: var(--grid-column-gap);
  }
} */

.special-shelf {
  --grid-rows: 1;
  --grid-column-gap: 20px;
}

.hidden {
  display: none; /* or visibility: hidden; based on your preference */
}

@media only screen and (min-width: 740px) {
  .shelf-grid__header {
    padding-left: 0;
    padding-right: 0;
  }

  /* .shelf-grid .shelf-grid__list {
    -webkit-mask: linear-gradient(90deg,transparent 0,#000 12px,#000 calc(100% - 12px),transparent 100%);
    mask: linear-gradient(90deg,transparent 0,#000 12px,#000 calc(100% - 12px),transparent 100%);
    margin-left: -12px;
    margin-right: -12px;
    padding-left: 12px;
    padding-right: 12px;
    scroll-padding-left: 12px;
    scroll-padding-right: 12px;
  }

  [data-grid='C'] .shelf-grid__list {
    grid-auto-columns: calc((100% - 2 * var(--grid-column-gap))/ 3);
    grid-template-rows: repeat(var(--grid-rows),-webkit-max-content);
    grid-template-rows: repeat(var(--grid-rows),max-content);
    grid-column-gap: var(--grid-column-gap);
  } */
}

@media only screen and (min-width: 740px) {
  main {
    min-height: calc(100% - var(--footer-height-desktop));
  }
}

@media only screen and (max-width: 739px) {
  .shelf-grid {
    padding-left: 2px;
    padding-right: 2px;
  }
  .ordinal-chart__ordinal {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-right: 7px;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: .875;
}
}

@media only screen and (max-width: 739px) {
  .shelf-grid .shelf-grid__body {
    margin-right: unset;
    padding-right: unset;
  }
}

@media only screen and (max-width: 739px) {
  /* .shelf-grid__list {
    -webkit-scroll-snap-type: none;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 25px;
  } */
}

/* .shelf-grid__list {
  grid-auto-columns: 200px;
  grid-template-rows: repeat(var(--grid-rows),-webkit-max-content);
  grid-template-rows: repeat(var(--grid-rows),max-content);
  grid-column-gap: 10px;

  scroll-snap-type: x mandatory;
} */

.top3-container-display {
  /* max-width: 698px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding-top: 19px;

  margin-bottom: 20px;
  /* padding-bottom: 20px; */

  /* padding-left: 1px;
  padding-right: 1px; */
  padding-bottom: 1px;

  border-radius: 10px;
  /* border: 0.5px solid rgba(0,0,0,.2); */
  box-shadow: 0 0 0 0px rgba(255,255,255,.2),0 8px 40px var(--dialogShadowColor);
  background-color: var(--systemStandardThickMaterialSover-default_IC);
  background-color: var(--systemStandardThickMaterialSover);
  /* -webkit-backdrop-filter: blur(60px) saturate(210%); */
  /* backdrop-filter: blur(60px) saturate(210%); */
  border-radius: 12px;
  background-color: #00000000;

  position: relative;
  top: 52px;
  margin-top: 0px;

  overflow-y: auto;
  /* scroll-snap-type: y mandatory; */
  height: 100vh; /* Adjust to fit your design */
  padding-top: 0px;

  height: calc(100vh - 52px);
}

@media only screen and (max-width: 739px) {
  .top3-container-display {
    margin-top: 8px;
    padding-top: 10px;
    border: 0px solid #000;
    border-radius: 0;
    box-shadow: none;

    top: 44px;
    margin-top: 0px;
    padding-top: 0px;

    height: calc(100vh - 44px);
  }
}

.snap-shelf {
  scroll-snap-align: start;
}

.nav-header__user-controls {
  grid-area: auth;
  display: flex;
  align-items: center;
  justify-self: end;
  margin-right: 0;
}

.search-header__search {
  margin-top: 0;
  display: block;
  grid-area: search;
}

.search-header__search {
  margin-top: 24px;
  width: 100%;
  display: none;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 740px) {
  .nav-header__desktop {
    display: block;
  }
}

.nav-header__desktop {
  /* display: none; */
}

@media only screen and (min-width: 740px) {
  .search-header__search {
    margin-top: 0;
    display: block;
    grid-area: search;
  }
}

.typography-subhead-emphasized {
  font-size: 13px;
  line-height: 1.27273;
  font-weight: 600;
  letter-spacing: 0;
}

.search-header__form-input-label {
  position: absolute;
  clip: rect(1px,1px,1px,1px);
  -webkit-clip-path: inset(0 0 99.9% 99.9%);
  clip-path: inset(0 0 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

@media only screen and (min-width: 740px) {
  .search-header__search-input-wrapper {
    margin-right: 12px;
}
}

.search-header__search-input-wrapper {
  order: 2;
}

@media only screen and (min-width: 740px) {
  .search-header__search-icon {
    top: 7px;
    fill: var(--systemSecondary-onDark);
    left: 6px;
}
}

.search-header__search-icon {
  margin-top: -1px;
  height: 15px;
  width: 15px;
  order: 1;
  position: absolute;
  top: 12px;
  fill: var(--systemSecondary);
  pointer-events: none;
  left: 8px;
  margin-right: 14px;
  z-index: 1;
}

@media only screen and (min-width: 740px) {
  .search-header__search-input {
    border-radius: 4px;
    color: var(--systemPrimary-onDark);
    height: 28px;
    min-width: 102px;
     /* max-width: 142px; hide temp, change for production */
    border: 1px solid #fff;
    background-color: transparent;
    font-size: 15px;
    line-height: 1.33338;
    font-weight: 400;
    letter-spacing: 0;
}
}

.search-header__search-input {
  height: 38px;
  width: 100%;
  border-radius: 9px;
  border: 1px solid var(--searchInputBorderColor);
  box-sizing: border-box;
  padding-bottom: 1px;
  background-color: transparent;
  color: var(--systemSecondary);
  font-size: 17px;
  line-height: 1.29412;
  font-weight: 400;
  letter-spacing: 0;
  padding-left: 26px;
  padding-right: 25px;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.search-header__button--close.has-search-text, .search-header__search-page .search-header__button--close {
  display: none; /* hide temp, change for production */
}

@media only screen and (min-width: 740px) {
  .search-header__button--close {
    top: 7px;
    right: 18px;
}
}

.search-header__button--close {
  display: none;
  position: absolute;
  height: 14px;
  width: 14px;
  top: 12px;
  right: 11px;
  z-index: 1;
}

@media only screen and (min-width: 740px) {
  .search-header__button {
    fill: var(--systemSecondary-onDark);
}
}

.search-header__button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  fill: var(--systemSecondary);
}


[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

.search-hints.is-hidden {
  position: absolute;
  clip: rect(1px,1px,1px,1px);
  -webkit-clip-path: inset(0 0 99.9% 99.9%);
  clip-path: inset(0 0 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

@media only screen and (min-width: 740px) {
  .search-hints {
    width: 300px;
    right: 12px;
}
}

.search-hints {
  padding: 6px 10px;
  width: 282px;
  max-height: calc(100vh - 55px - 54px - 25px + 3px);
  position: absolute;
  top: 32px;
  border-radius: 9px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: start;
  z-index: 9953;
}

.nav-header__user-image__wrapper {
  text-align: right;
  height: 28px;
  width: 28px;
  margin: 0;
}

.nav-header-auth .ember-basic-dropdown .ember-basic-dropdown-trigger .nav-header__user-image {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.nav-header__content {
  position: relative;
  max-width: 1280px;
  z-index: 1;
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 740px) {
  .nav-header__content {
    margin: auto;
    padding-left: 40px;
    padding-right: 40px;
}
}

@media only screen and (max-width: 739px) {
  .nav-header__content {
    width: 100%;
    height: 44px;
}
}

@media only screen and (min-width: 1000px) {
  /* .shelf-grid__list.special-shelf.first-shelf {
    grid-auto-columns: calc((100% - 2* var(--grid-column-gap)) / 3);
  } */
}


.nav-header__content {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'trigger logo auth';
  align-items: center;
}

@media only screen and (min-width: 740px) {
  .nav-header__content {
    grid-template-rows: 52px;
    grid-template-areas: 'opentv tvlinks auth';
    width: 100%;
}
}


.nav-header, .nav-header__wrapper {
  height: 52px;
  background-color: #000;
}

.nav-header {
  position: -webkit-fixed;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  left: 0;
}

@media only screen and (min-width: 740px) {
  .nav-header {
    margin: auto;
    height: 52px;
}
}

@media only screen and (min-width: 740px) {
  .nav-header__desktop-links {
    grid-area: tvlinks;
    justify-self: center;
}
}


.nav-header__desktop {
  display: none;
}

@media only screen and (min-width: 740px) {
  .nav-header__desktop {
    display: block;
}
}


.nav-header__desktop-links {
  /* margin-right: 16px; */
}


.nav-header__desktop-links-list {
  display: flex;
  gap: 30px;
}

@media only screen and (min-width: 740px) {
  .nav-header__link.nav-header__link--active, .nav-header__link:hover {
    color: var(--systemPrimary-onDark);
}
}

.nav-header__link.nav-header__link--active, .nav-header__link:hover {
  color: var(--systemPrimary-onDark);
}

.nav-header__link:nth-child(1) {
  transition-duration: .2s;
}

@media only screen and (min-width: 740px) {
  .nav-header__link {
    color: var(--systemSecondary-onDark);
}
}

.nav-header__link {
  color: var(--systemSecondary-onDark);
}

@media only screen and (min-width: 740px) {
  .nav-header__link {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.47;
}
}

.nav-header__link {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.47;
}

.nav-header__link {
  transition-property: opacity,transform;
}

@media only screen and (max-width: 739px) {
  .nav-header__wrapper {
    transition: height .56s cubic-bezier(.52,.16,.24,1);
}

.nav-header, .nav-header__wrapper {
  height: 44px;
  background-color: #000;
}
}

.nav-header__mobile-solo-tab {
  color: var(--systemPrimary-onDark);
  opacity: .92;
}


@media only screen and (min-width: 740px) {
  .nav-header__mobile-solo-tab {
    display: none;
}
}


#footer-upsell-banner-hole {
  bottom: -1px;
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  z-index: 10000;
}

.clr-primary-text-on-dark, .person-header__container {
  color: var(--systemPrimary-onDark);
}

.upsell-banner {
  -webkit-animation: fade-in .5s;
  animation: fade-in .5s;
  width: 100%;
  padding: 16px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--keyColor);
  box-shadow: rgba(0,0,0,.12) 0 0 21px;
}

@media only screen and (max-width: 739px) {
  .upsell-banner {
    padding: 16px 25px;
}
}


.upsell-banner__button-wrapper {
  text-align: center;
  flex-shrink: 0;
  margin-left: 30px;
}

@media only screen and (max-width: 739px) {
  .upsell-banner__button-wrapper {
    margin-left: 10px;
}
}

.upsell-banner__title {
  font-size: 17px;
  line-height: 1.29412;
  font-weight: 700;
  letter-spacing: 0;
}


.upsell-banner__text {
  margin-top: 6px;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 400;
  letter-spacing: 0;
}

@media only screen and (max-width: 739px) {
  .upsell-banner__text {
    display: none;
}
}

.upsell-banner__text a, .upsell-banner__text span {
  display: inline-block;
}

.upsell-banner__text a {
  font-weight: 700;
}

.upsell-banner__button-wrapper {
  text-align: center;
  flex-shrink: 0;
  margin-left: 30px;
}

@media only screen and (max-width: 739px) {
  .upsell-banner__button-wrapper {
    margin-left: 10px;
}
}



.cursor-pointer {
  cursor: pointer;
}

.coming-soon--play-trailer, .typ-subhead-emph {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.47;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

.typ-footnote, .typ-footnote-emph {
  font-size: 1.1rem;
  line-height: 1.19;
}

.typ-footnote {
  font-weight: 400;
}

.upsell-banner__button-text {
  margin-top: 5px;
  opacity: .6;
  max-width: 245px;
  text-align: center;
}

.upsell-banner__button {
  height: 32px;
  min-width: 125px;
  max-width: 245px;
  border-radius: 4px;
  padding: 0 12px;
  text-align: center;
  vertical-align: middle;
  background-color: #fff;
  color: var(--systemPrimary-onLight);
  border: none;
  box-shadow: rgba(0,0,0,.06) 0 0 20px;
  font-size: 13px;
}


/* Hide the break on screens smaller than 740px */
.responsive-break {
  display: block;
}

/* Simulate a break on screens 740px wide or wider */
@media (min-width: 740px) {
  .responsive-break {
      display: inline;
  }
}

@media only screen and (max-width: 739px) {
  .upsell-banner__text {
    display: none;
}
}

@media only screen and (max-width: 739px) {
  .shelf-grid__list.special-shelf.first-shelf {
    grid-auto-columns: calc((100% - 2 * var(--grid-column-gap))/ 1);
    scroll-snap-type: x mandatory;
  }
}

@media only screen and (min-width: 739px) {
  .shelf-grid__list.special-shelf.first-shelf {
    grid-auto-columns: calc((100% - 2 * var(--grid-column-gap))/ 3);
    scroll-snap-type: x mandatory;
  }
}

@media only screen and (min-width: 739px) {
  .shelf-grid__list.special-shelf.first-shelf {
    /* grid-auto-columns: calc((40vh - 2 * var(--grid-column-gap))/ 1); */
  }
}

.ordinal-chart__artwork, .ordinal-chart__ordinal {
  flex-shrink: 0;
}

.canvas-lockup__artwork {
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 4px 7px rgba(0,0,0,.25);
  margin-bottom: 0px;
  overflow: hidden;
}

.boomba {
  position: fixed;
    z-index: 10;
    background-color: var(--appPrimaryBackgroundColor);
    overflow: scroll;
    height: 100vh;
    padding-bottom: 100px;
    /* width: 100vw; */
    width: 100%;
    max-width: 1280px;
}

.shelf-grid__list-item.first-shelf {
  scroll-snap-align: center;
}

.otter-bar-bottom {
  height: 100px;
  /* background-color: #272727d9; */
  /* padding-top: 56.25%; */
  /* -webkit-backdrop-filter: saturate(190%) blur(10px);
  backdrop-filter: saturate(190%) blur(10px); */
  display: flex;
  border-radius: 0px 0px 10px 10px;
  height: 100%;
  /* padding: 10px; */
  /* background-color: #00000024; */
    position: relative;
    z-index: 1;
  /* height: 100px; */
}

.otter-bar-top {
  /* background-color: #272727d9; */
  /* padding-top: 56.25%; */
  /* -webkit-backdrop-filter: saturate(100%) blur(1px);
  backdrop-filter: saturate(100%) blur(1px); */
  display: flex;
  border-radius: 10px 10px 0px 0px;
  height: 100%;
  padding: 20px 20px 10px 20px;
  /* padding: 30px; */
  /* background-color: #00000024; */
    position: relative;
    z-index: 1;
  /* height: 110px; */
}





/* .shelf-grid__list {
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior-x: none;
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
} */

/* @media only screen and (min-width: 740px) {
  .shelf-grid__list {
    grid-auto-columns: calc((100% - 2* var(--grid-column-gap)) / 3);
    grid-template-rows: repeat(var(--grid-rows), -webkit-max-content);
    grid-template-rows: repeat(var(--grid-rows), max-content);
    grid-column-gap: var(--grid-column-gap);
}
}

@media only screen and (max-width: 739px) {
  .shelf-grid__list {
    -webkit-scroll-snap-type: none;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 25px;
}
} */


.shelf-grid__list-item {

  -webkit-text-size-adjust: 100%;
  --rem: 16;
  visibility: inherit;
  --bodyGutter: 25px;
  color-scheme: light dark;
  --navigationIconColor: #7f7f7f;
  --modalBGColor: rgba(255, 255, 255, 1);
  --labelPrimary: rgba(0, 0, 0, 0.95);
  --skip-button-visibility: hidden;
  --systemRed: #ff453a;
  --systemRed-vibrant: #ff4f44;
  --systemRed-default_IC: #ff6961;
  --systemRed-vibrant_IC: #ff4136;
  --systemOrange: #ff9f0a;
  --systemOrange-vibrant: #ffa914;
  --systemOrange-default_IC: #ffb340;
  --systemOrange-vibrant_IC: #ffb340;
  --systemYellow: #ffd60a;
  --systemYellow-vibrant: #ffe014;
  --systemYellow-default_IC: #ffd426;
  --systemYellow-vibrant_IC: #ffd426;
  --systemGreen: #32d74b;
  --systemGreen-vibrant: #3ce155;
  --systemGreen-default_IC: #31de4b;
  --systemGreen-vibrant_IC: #31de4b;
  --systemMint: #63e6e2;
  --systemMint-vibrant: #6ce0db;
  --systemMint-default_IC: #63e6e2;
  --systemMint-vibrant_IC: #63e6e2;
  --systemTeal: #6ac4dc;
  --systemTeal-vibrant: #44d4ed;
  --systemTeal-default_IC: #5de6ff;
  --systemTeal-vibrant_IC: #5de6ff;
  --systemCyan: #5ac8f5;
  --systemCyan-vibrant: #5acdfa;
  --systemCyan-default_IC: #70d7ff;
  --systemCyan-vibrant_IC: #70d7ff;
  --systemBlue: #0a84ff;
  --systemBlue-vibrant: #148eff;
  --systemBlue-default_IC: #409cff;
  --systemBlue-vibrant_IC: #409cff;
  --systemBlue-rollover: #47c1ff;
  --systemBlue-pressed: #38b2ff;
  --systemBlue-deepPressed: #47c1ff;
  --systemBlue-disabled: rgba(10, 132, 255, 0.5);
  --systemIndigo: #5e5ce6;
  --systemIndigo-vibrant: #6361f2;
  --systemIndigo-default_IC: #7d7aff;
  --systemIndigo-vibrant_IC: #7d7aff;
  --systemPurple: #bf5af2;
  --systemPurple-vibrant: #cc65ff;
  --systemPurple-default_IC: #da8fff;
  --systemPurple-vibrant_IC: #da8fff;
  --systemPink: #ff375f;
  --systemPink-vibrant: #ff4169;
  --systemPink-default_IC: #ff6482;
  --systemPink-vibrant_IC: #ff3a5f;
  --systemBrown: #ac8e68;
  --systemBrown-vibrant: #b69872;
  --systemBrown-default_IC: #b59469;
  --systemBrown-vibrant_IC: #b59469;
  --systemGray: #98989d;
  --systemGray-vibrant: #a2a2a7;
  --systemGray-default_IC: #98989d;
  --systemGray-vibrant_IC: #98989d;
  --systemPrimary-default_IC: white;
  --systemPrimary-vibrant_IC: white;
  --systemPrimary-onLight_IC: black;
  --systemPrimary-onDark_IC: white;
  --systemSecondary-default_IC: rgba(255, 255, 255, 0.7);
  --systemSecondary-vibrant_IC: rgba(255, 255, 255, 0.7);
  --systemSecondary-onLight_IC: rgba(0, 0, 0, 0.76);
  --systemSecondary-onDark_IC: rgba(255, 255, 255, 0.7);
  --systemTertiary-default_IC: rgba(255, 255, 255, 0.5);
  --systemTertiary-vibrant_IC: rgba(255, 255, 255, 0.5);
  --systemTertiary-onLight_IC: rgba(0, 0, 0, 0.56);
  --systemTertiary-onDark_IC: rgba(255, 255, 255, 0.5);
  --systemQuaternary: rgba(255, 255, 255, 0.1);
  --systemQuaternary-vibrant: #232323;
  --systemQuaternary-onLight: rgba(0, 0, 0, 0.1);
  --systemQuaternary-onDark: rgba(255, 255, 255, 0.1);
  --systemQuaternary-default_IC: rgba(255, 255, 255, 0.3);
  --systemQuaternary-vibrant_IC: rgba(255, 255, 255, 0.3);
  --systemQuaternary-onLight_IC: rgba(0, 0, 0, 0.35);
  --systemQuaternary-onDark_IC: rgba(255, 255, 255, 0.3);
  --systemQuinary: rgba(255, 255, 255, 0.05);
  --systemQuinary-vibrant: #080808;
  --systemQuinary-onLight: rgba(0, 0, 0, 0.05);
  --systemQuinary-onDark: rgba(255, 255, 255, 0.05);
  --systemQuinary-default_IC: rgba(255, 255, 255, 0.1);
  --systemQuinary-vibrant_IC: rgba(255, 255, 255, 0.1);
  --systemQuinary-onLight_IC: rgba(0, 0, 0, 0.1);
  --systemQuinary-onDark_IC: rgba(255, 255, 255, 0.1);
  --keyColor: #0a84ff;
  --keyColor-default_IC: #409cff;
  --systemStandardUltrathinMaterialSover: rgba(40, 40, 40, 0.4);
  --systemStandardUltrathinMaterialSover-inactive: #282828;
  --systemStandardUltrathinMaterialSover-default_IC: black;
  --systemStandardUltrathinMaterialSover-inactive_IC: #141414;
  --systemStandardThinMaterialSover: rgba(40, 40, 40, 0.5);
  --systemStandardThinMaterialSover-inactive: #282828;
  --systemStandardThinMaterialSover-default_IC: #0a0a0a;
  --systemStandardThinMaterialSover-inactive_IC: #141414;
  --systemStandardMediumMaterialSover: rgba(40, 40, 40, 0.6);
  --systemStandardMediumMaterialSover-inactive: #282828;
  --systemStandardMediumMaterialSover-default_IC: #141414;
  --systemStandardMediumMaterialSover-inactive_IC: #141414;
  --systemStandardThickMaterialSover: rgba(40, 40, 40, 0.7);
  --systemStandardThickMaterialSover-inactive: #282828;
  --systemStandardThickMaterialSover-default_IC: #1e1e1e;
  --systemStandardThickMaterialSover-inactive_IC: #141414;
  --systemStandardUltrathickMaterialSover: rgba(40, 40, 40, 0.8);
  --systemStandardUltrathickMaterialSover-inactive: #282828;
  --systemStandardUltrathickMaterialSover-default_IC: #282828;
  --systemStandardUltrathickMaterialSover-inactive_IC: #141414;
  --systemHeaderMaterialSover: rgba(30, 30, 30, 0.8);
  --systemHeaderMaterialSover-default_IC: #1e1e1e;
  --systemToolbarTitlebarMaterialSover: rgba(60, 60, 60, 0.8);
  --systemToolbarTitlebarMaterialSover-inactive: #282828;
  --systemToolbarTitlebarMaterialSover-default_IC: #262626;
  --systemToolbarTitlebarMaterialSover-inactive_IC: #141414;
  --labelDivider: rgba(255, 255, 255, 0.1);
  --vibrantDivider: rgba(235, 235, 245, 0.19);
  --pageBG: #1f1f1f;
  --shelfBG: rgba(255, 255, 255, 0.05);
  --genericJoeColor: #323232;
  --fallbackMaterialBG: rgba(31, 31, 31, 0.97);
  --liveBadgePlatterBG: #ff5066;
  --keyColorBackground-default_IC: #409cff;
  --appPrimaryBackgroundColor: #2d2d2d;
  --appSecondaryBackgroundColor: rgba(0, 0, 0, 0.3);
  --alertDropShadowColor: rgba(0, 0, 0, 0.55);
  --alertOuterStrokeColor: rgba(0, 0, 0, 0.5);
  --appModalBackgroundColor: #2d2d2d;
  --artworkBorderColor: rgba(151, 151, 151, 0.2);
  --ctaButtonBackgroundColor: white;
  --cardBackgroundColor: var(--systemQuaternary-onDark);
  --defaultButtonBackgroundColor: rgba(84, 84, 84, 0.5);
  --dialogShadowColor: rgba(0, 0, 0, 0.55);
  --disabledLightColor: rbga(255, 255, 255, 0.4);
  --dividerTextColor: rgba(235, 235, 245, 0.19);
  --fauxNativeSheetBackgroundColor: #2d2d2d;
  --labelSecondary: rgba(60, 60, 67, 0.6);
  --liveBadgeBackgroundColor: #ff5066;
  --mobileNavigationBG: #2e2e2e;
  --modalBoxShadowColor: rgba(0, 0, 0, 0.24);
  --modalCloseButtonIconColor: rgba(235, 235, 245, 0.6);
  --modalContentOverlayColorEnd: #2d2d2d;
  --modalContentOverlayColorStart: rgba(45, 45, 45, 0);
  --modalHeaderBackgroundColor: rgba(45, 45, 45, 0.97);
  --modalHeaderShadowColor: rgba(0, 0, 0, 0.2);
  --modalScrimColor: rgba(0, 0, 0, 0.55);
  --navigationBackgroundColor: #5b5b5b;
  --navigationBorderColor: #353535;
  --navigationBorderBottomColor: #484848;
  --navigationBorderLeftColor: #525252;
  --navigationBorderRightColor: #525252;
  --navigationBorderTopColor: #848484;
  --navigationLinkSelectionStateColor: rgba(235, 235, 245, 0.1);
  --navigationMobileMenuBackgroundColor: white;
  --navigationScrollBackgroundColor: rgba(0, 0, 0, 0.4);
  --navigationScrollBackgroundFallbackColor: rgba(0, 0, 0, 0.6);
  --navigationTextColor: #c8c8c8;
  --overlayBackgroundColor: rgba(51, 51, 51, 0.3);
  --overlayButtonPlatterColor: rgba(255, 255, 255, 0.2);
  --personHeaderBackgroundColor: rgba(40, 40, 40, 0.9);
  --playerBackground: rgba(45, 45, 45, 0.88);
  --playbackOverlayBackgroundColor: rgba(51, 51, 51, 0.7);
  --progressBarColor: rgba(255, 255, 255, 0.4);
  --progressBarFillColor: white;
  --searchInputBorderColor: rgba(255, 255, 255, 0.15);
  --settingsMenuPointer: rgba(45, 45, 45, 0.5);
  --sidebarActiveItemBackgroundColor: rgba(235, 235, 245, 0.1);
  --sidebarBackgroundColor: rgba(235, 235, 245, 0.03);
  --sidebarSearchInputBorderColor: rgba(255, 255, 255, 0.15);
  --sportLockupClockTimeBadgeBackgroundColor: rgba(0, 0, 0, 0.2);
  --sportLockupScoreBadgeDivider: rgba(0, 0, 0, 0.15);
  --unifiedVolumeThumbShadow: rgba(0, 0, 0, 0.1);
  --unifiedVolumeThumbShadowInset: rgba(0, 0, 0, 0.15);
  --unifiedVolumeTrackFilled: rgba(246, 246, 246, 0.36);
  --unifiedVolumeThumb: #d0d0d0;
  --unifiedPlayerIcons: rgba(255, 255, 255, 0.9);
  /* --systemPrimary: rgba(255, 255, 255, 0.92); */
  --systemPrimary-vibrant: #f5f5f7;
  --systemPrimary-onLight: rgba(0, 0, 0, 0.88);
  --systemPrimary-onDark: rgba(255, 255, 255, 0.92);
  --systemSecondary: rgba(255, 255, 255, 0.64);
  --systemSecondary-vibrant: #a1a1a6;
  --systemSecondary-onLight: rgba(0, 0, 0, 0.56);
  --systemSecondary-onDark: rgba(255, 255, 255, 0.64);
  --systemTertiary: rgba(255, 255, 255, 0.4);
  --systemTertiary-vibrant: #6e6e73;
  --systemTertiary-onLight: rgba(0, 0, 0, 0.48);
  --systemTertiary-onDark: rgba(255, 255, 255, 0.4);
  --keyColorBackground: #0040dd;
  font-style: normal;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.23077;
  font-weight: 400;
  /* color: var(--systemPrimary); */
  font-family: "-apple-system",BlinkMacSystemFont,"-webkit-system-font","Malgun Gothic","Segoe UI","Helvetica Neue",Helvetica,sans-serif;
  font-feature-settings: 'kern';
  --scrollbar-width: 0px;
  --footer-height-desktop: 89px;
  --footer-height-mobile: 148px;
  --shelf-arrow-offset: 12px;
  list-style: none;
  --grid-rows: 1;
  --grid-column-gap: 20px;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-drag: none;
  margin: 0;
  padding: 0;
  scroll-snap-align: none;
  position: relative;
}

.ordinal-chart__artwork, .ordinal-chart__ordinal {
  flex-shrink: 0;
}

.canvas-lockup, .watch-live-lockup {
  position: relative;
}

.canvas-lockup__artwork, .library-container .library-lockup .canvas-lockup__artwork, .watch-live-lockup__artwork {
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: 0 4px 7px rgba(0,0,0,.25);
  margin-bottom: 8px;
  overflow: hidden;
}

.shelf-grid-nav, .shelf-grid-nav ul, .shelf-grid__list {
  margin: 0;
  list-style: none;
}

.shelf-grid__list {
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior-x: none;
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

@media only screen and (max-width: 739px) {
  .shelf-grid__list {
    -webkit-scroll-snap-type: none;
    -ms-scroll-snap-type: none;
    scroll-snap-type: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 25px;
  }
}

.shelf-grid__list {
  grid-auto-columns: 200px;
  grid-template-rows: repeat(var(--grid-rows), -webkit-max-content);
  grid-template-rows: repeat(var(--grid-rows), max-content);
  grid-column-gap: 10px;
}

.shelf-grid .shelf-grid__list {
  margin-top: -20px;
  margin-bottom: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 740px) {
  .shelf-grid .shelf-grid__list {
    -webkit-mask: linear-gradient(90deg, transparent 0, #000 12px, #000 calc(100% - 12px), transparent 100%);
    mask: linear-gradient(90deg, transparent 0, #000 12px, #000 calc(100% - 12px), transparent 100%);
    margin-left: -12px;
    margin-right: -12px;
    padding-left: 12px;
    padding-right: 12px;
    scroll-padding-left: 12px;
    scroll-padding-right: 12px;
  }
}

.ordinal-chart__ordinal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 12px;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: .875;
}

.ordinal-chart-item__metadata {
  margin-bottom: 1px;

  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.23077;
  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (min-width: 740px) {
  .ordinal-chart-item__metadata {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.1;
  }

  .ordinal-chart__ordinal {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: .875;
  }
}

.shelf-grid__list-item {
  scroll-snap-align: start;
}

@media only screen and (min-width: 740px) {
  .shelf-grid__list {
    grid-auto-columns: calc((100% - 2* var(--grid-column-gap)) / 3);
    grid-template-rows: repeat(var(--grid-rows), -webkit-max-content);
    grid-template-rows: repeat(var(--grid-rows), max-content);
    grid-column-gap: var(--grid-column-gap);
  }
}


@media only screen and (max-width: 739px) {
  .wider740 {
    display: none;
  }
  .smaller740 {
    position: relative;
    padding-top: 0px;
    width:100%;
    padding-left: 15px;
    /* border-bottom: 0.5px solid var(--labelDivider); */
  }
}

@media only screen and (min-width: 740px) {
  .smaller740 {
    /* display: none; */
  }
  .smaller740 {
    position: relative;
    padding-top: 0px;
    width:100%;
    position: relative;
    padding-top: 0px;
    width:100%;
    padding-left: 15px;
    
    /* border-bottom: 0.5px solid var(--labelDivider); */
  }
  .wider740 {
    display: none;
  }
}



/* .lockup-button::after {
  background-color: var(--systemPrimary-onDark);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: bottom right; 
  mask-position: bottom right; 
} */

/* .streaming-service-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
  right: 0;
  bottom: 0;
} */

.streaming-service-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  border-radius: 25%;
}

.animate, .context-menu--closing, .epic-showcase-shelf__linear-gradient--hidden, .product-header--sport-event--logged-in.product-header--sport-event--fastboot .product-header--sport-event__platter, .product-header__image-bg--hidden {
  opacity: 0;
}

video {
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.background-video__media {
  width: 100%;
  height: 100%;
  display: block;
}

.background-video__media:not(:fullscreen) {
  object-fit: cover;
}

[hydrated] {
  visibility: inherit;
}

:host(.contain) .background-video__media {
  height: auto;
}

.background-video__wrapper {
  grid-area: video;
  background-color: var(--joe-color);
}

:host(.contain) .background-video__wrapper {
  background-color: transparent;
  -webkit-mask-image: linear-gradient(0deg, transparent, #000 8%, #000 100%);
  mask-image: linear-gradient(0deg, transparent, #000 8%, #000 100%);
}

.product-header__video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-header__video-wrapper {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.header-button:not(:first-child) {
  margin-left: 4px;
}

svg:not(:root) {
  overflow-clip-margin: content-box;
  overflow: hidden;
}

*, [type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

.icon {
  display: block;
}

.header-button svg, .nav-header__auth-avatar {
  fill: var(--systemPrimary-onDark);
}

.header-button, .play-button {
  background-color: transparent;
  border: none;
  opacity: 1;
}

.header-button {
  width: 28px;
  height: 28px;
  background-size: contain;
}

.animate, .modal-content, .modal-overlay {
  transition: all .5s ease-in-out;
}

.product-header__video-control__container {
  position: absolute;
  padding: 0 40px;
  bottom: 180px;
  right: 0;
  z-index: 6;
}

.fade-in {
  opacity: 1;
}
